@charset "UTF-8";
/*
Template Name: Bidout HTML Template for LTL Version
*/
/*================================================
1. Global CSS
2. Mixins Css
3. Preloader
4. Topbar Css
5. Banner Area CSS
6. Category Area CSS
7. Live Auction CSS
8. Featured CSS
9. Comming assets CSS
10. Hot collection
11. Top author
12. Blog section
13. testimonial section
11. Home Two Menu CSS
12. Home Two Hero CSS
13. Home Two About CSS
14. Home Two Knowledge Box CSS
15. Home Two Support Fourm CSS
16. Home Two Documentation CSS
17. Home Two Blog Area CSS
18. Home Two Footer CSS
19. Home two Newsletter CSS
20. Home Two Testimonial CSS
21. FAQ Page CSS
22. signup Page CSS
23. Login Page CSS
24. Blog Grid Page CSS
25. Blog Sidebar Page CSS
26. 404 Page CSS
27. Contact Page CSS
28. Blog Details Page CSS
29. Topic Page CSS
30. Ask A Qsn page CSS
31. Topic Details page CSS
32. Forum Qsn Page CSS
33. Documents Page CSS
34. Gallery CSS
35. Chat

================================================*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Saira:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");
@import "stream-chat-react/dist/css/v2/index.css" layer(base);
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Saira", sans-serif;
}

.z-n1 {
  z-index: -1;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.z-max {
  z-index: 99999;
}

/* scroll bar */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background: #d1c6c6;
  border: 2px solid #d1c6c6;
  border-radius: 0.25rem;
  transition-duration: 0.4s;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}
a:focus {
  outline: 0 solid;
}
a:hover {
  text-decoration: none;
  color: unset;
}

input:focus,
textarea:focus,
button:focus {
  outline: none;
}

ul {
  list-style: none;
}

i {
  cursor: pointer;
}

i.bx {
  vertical-align: middle;
}

.error-message {
  color: red;
  font-size: 12px;
  font-family: "Inter", sans-serif;
}

.disabled-button {
  background: #ccc !important;
}
.disabled-button::after {
  background: #ccc !important;
}
.disabled-button::before {
  background: #ccc !important;
}

/*================================================
2. Mixins Css
=================================================*/
.pt-120 {
  padding-top: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pt-120 {
    padding-top: 90px;
  }
}
@media (max-width: 991px) {
  .pt-120 {
    padding-top: 80px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 90px;
  }
}
@media (max-width: 991px) {
  .pb-120 {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

.pl-1r {
  padding-left: 1rem;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-60 {
  padding-bottom: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.alert-wrapper {
  position: fixed;
  top: 90px;
  left: 50%;
  z-index: 999999;
  width: 70%;
  transform: translate(-50%, 0);
}
@media (max-width: 991px) {
  .alert-wrapper {
    width: 95%;
    top: 10px;
  }
}

.alert-message {
  margin: auto;
}
.alert-message p {
  margin: 0;
}

.free-illustrations-text {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 10px;
}
.free-illustrations-text a {
  font-size: 13px;
  color: #d2d2d2;
  font-family: "Inter", sans-serif;
}

.text-counter,
.title-text-counter {
  font-size: 12px;
  width: 100%;
  font-family: "Inter", sans-serif;
  color: #696969;
  padding: 5px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.text-counter {
  margin-top: -10px;
}

@media (max-width: 991px) {
  #cookiescript_badge {
    bottom: 70px !important;
  }
}

@media (max-width: 991px) {
  #cookiescript_badgeimage,
  #cookiescript_badgesvg {
    width: 30px !important;
    height: 30px !important;
  }
}

@media (max-width: 991px) {
  #cookiescript_badgetext {
    display: none;
  }
}

.filepond--item {
  touch-action: auto !important;
}

.swiper-fix {
  margin: -12px;
  padding: 12px;
}

.para {
  font-size: 16px;
  font-weight: 400;
  color: #696969;
  font-family: "Inter", sans-serif;
  line-height: 28px;
}
@media (max-width: 576px) {
  .para {
    font-size: 14px !important;
    line-height: 25px !important;
  }
}

.color-primary {
  color: #f9395f;
}

.eg-card {
  border-radius: 5px;
  border: 1px solid #eeeeee;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.eg-btn {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease;
  text-transform: capitalize;
  font-family: "Saira", sans-serif;
  border: none;
}

.btn--success {
  background: #1f2230;
  color: #fff;
  position: relative;
  z-index: 1;
  white-space: nowrap;
}
.btn--success::before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #57bf84;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 5px;
}
.btn--success::after {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #57bf84;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 5px;
}
.btn--success:hover {
  color: #fff;
}
.btn--success:hover::before {
  transform: rotateX(90deg);
}
.btn--success:hover::after {
  transform: rotateY(90deg);
}

.btn--dark {
  background: #0a0a0f;
  color: #fff;
  position: relative;
  z-index: 1;
  white-space: nowrap;
}
.btn--dark::before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #0a0a0f;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 5px;
}
.btn--dark::after {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #0a0a0f;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 5px;
}
.btn--dark:hover {
  color: #fff;
}
.btn--dark:hover::before {
  transform: rotateX(90deg);
}
.btn--dark:hover::after {
  transform: rotateY(90deg);
}

.btn--primary {
  background: #1f2230;
  color: #fff;
  position: relative;
  z-index: 1;
  white-space: nowrap;
}
.btn--primary::before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #f9395f;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 5px;
}
.btn--primary::after {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #f9395f;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 5px;
}
.btn--primary:hover {
  color: #fff;
}
.btn--primary:hover::before {
  transform: rotateX(90deg);
}
.btn--primary:hover::after {
  transform: rotateY(90deg);
}

.btn--primary2 {
  background: #1f2230;
  color: #fff;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  border-radius: 100px;
}
.btn--primary2::before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #f9395f;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 100px;
}
.btn--primary2::after {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #f9395f;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 100px;
}
.btn--primary2:hover {
  color: #fff;
}
.btn--primary2:hover::before {
  transform: rotateX(90deg);
}
.btn--primary2:hover::after {
  transform: rotateY(90deg);
}

.btn--primary3 {
  background: #1f2230;
  color: #fff;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  border-radius: 100px;
}
.btn--primary3::before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #029e7c;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 100px;
}
.btn--primary3::after {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #029e7c;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 100px;
}
.btn--primary3:hover {
  color: #fff;
}
.btn--primary3:hover::before {
  transform: rotateX(90deg);
}
.btn--primary3:hover::after {
  transform: rotateY(90deg);
}

.btn--primary3-outline {
  border: 1px solid #029e7c;
  color: #029e7c;
  white-space: nowrap;
  transition: all 0.52s ease;
}
.btn--primary3-outline:hover {
  background: #029e7c;
  color: #fff;
}

.btn--lg {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 17px 32px;
  letter-spacing: 1px;
}
@media (max-width: 767px) {
  .btn--lg {
    padding: 12px 27px;
  }
}

.btn--md {
  padding: 14px 45px;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .btn--md {
    padding: 10px 40px;
  }
}

.btn--sm {
  font-size: 16px;
  font-weight: 600;
  padding: 5px 12px;
}

.header-btn {
  background-color: #f9395f;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  padding: 9px 20px;
}

.action-btn {
  min-width: 26px;
  height: 26px;
  line-height: 0px;
  border: none;
  outline: none;
}
.action-btn.red {
  background: #f9395f;
}
.action-btn.green {
  background: #f9395f;
}

.logo-paragraph {
  font-family: "League Spartan", sans-serif !important;
  font-optical-sizing: auto !important;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
}
@media (max-width: 576px) {
  .logo-paragraph {
    font-size: 18px;
  }
}

.logo-text {
  font-family: "League Spartan", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 700 !important;
  font-style: normal !important;
  letter-spacing: -2.1px !important;
}

.section-title1 {
  text-align: center;
  margin-bottom: 60px;
}
.section-title1 h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #1f2230;
  margin-top: -5px;
}
@media (max-width: 576px) {
  .section-title1 h2 {
    font-size: 35px;
  }
}
.section-title1 p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #696969;
}
@media (max-width: 576px) {
  .section-title1 p {
    font-size: 15px !important;
    padding: 10px;
  }
}

.section-title2 {
  text-align: center;
}
.section-title2 h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #1f2230;
  margin-top: -5px;
}
@media (max-width: 576px) {
  .section-title2 h2 {
    font-size: 30px;
  }
}
.section-title2 p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #696969;
}
@media (max-width: 576px) {
  .section-title2 p {
    font-size: 15px;
    padding: 10px;
  }
}

.section-title4 {
  text-align: center;
  margin-bottom: 60px;
}
.section-title4 h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #1f2230;
  margin-top: -5px;
  position: relative;
  display: inline-block;
}
@media (max-width: 767px) {
  .section-title4 h2 {
    font-size: 2.1rem;
  }
}
.section-title4 h2::before {
  content: url("../../public/images//icons/sec-arro1.svg");
  position: absolute;
  top: -5px;
  left: -55px;
}
@media (max-width: 767px) {
  .section-title4 h2::before {
    left: -45px;
  }
}
.section-title4 h2::after {
  content: url("../../public/images//icons/sec-arro2.svg");
  position: absolute;
  top: -5px;
  right: -55px;
}
@media (max-width: 767px) {
  .section-title4 h2::after {
    right: -45px;
  }
}
.section-title4 p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #696969;
}

.hover-style {
  background: #fff;
  position: relative;
  z-index: 1;
}
.hover-style::before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #f9395f;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 5px;
  opacity: 0;
}
.hover-style::after {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #f9395f;
  transition: all 0.52s;
  z-index: -1;
  border-radius: 5px;
  opacity: 0;
}
.hover-style:hover::before {
  transform: rotateX(90deg);
  opacity: 0.2;
}
.hover-style:hover::after {
  transform: rotateY(90deg);
  opacity: 0.2;
}

.hover-border1 {
  background: #fff;
  position: relative;
  z-index: 1;
}
.hover-border1::before {
  content: "";
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: 0%;
  left: 0%;
  background: #f9395f;
  transition: all 0.52s;
  z-index: -1;
}
.hover-border1:hover::before {
  width: 100%;
}

.hover-border2 {
  background: #fff;
  position: relative;
  z-index: 1;
}
.hover-border2::before {
  content: "";
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: 0%;
  left: 0%;
  background: #f9395f;
  transition: all 0.52s;
  z-index: -1;
}
.hover-border2:hover::before {
  width: 100%;
}

.hover-border3 {
  background: #fff;
  position: relative;
  z-index: 1;
}
.hover-border3::before {
  content: "";
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: 0%;
  left: 0%;
  background: #029e7c;
  transition: all 0.52s;
  z-index: -1;
}
.hover-border3:hover::before {
  width: 100%;
}

.otp-field {
  flex-direction: row;
  -moz-column-gap: 10px;
       column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-field input {
  height: 60px;
  width: 38px !important;
  border-radius: 6px;
  outline: none;
  font-size: 1.5rem;
  text-align: center !important;
  border: 1px solid #ddd;
}

.otp-field input:focus {
  box-shadow: 0 1px 0 #f9395f;
}

.otp-field input::-webkit-inner-spin-button,
.otp-field input::-webkit-outer-spin-button {
  display: none;
}

.expired-fucha,
.inactive-account {
  opacity: 0.5 !important;
}

/*=======================================
 3. preloader start & loaders & spinners
=======================================*/
.spinner-white-bg:hover > span > span {
  background-color: #f9395f !important;
}

.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #f9395f;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes loader {
  0%, 10%, 100% {
    width: 80px;
    height: 80px;
  }
  65% {
    width: 150px;
    height: 150px;
  }
}
@keyframes loaderBlock {
  0%, 30% {
    transform: rotate(0);
  }
  55% {
    background-color: #1f2230;
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes loaderBlockInverse {
  0%, 20% {
    transform: rotate(0);
  }
  55% {
    background-color: #1f2230;
  }
  100% {
    transform: rotate(-90deg);
  }
}
.preloader.style-2 {
  background: #f9395f;
}
.preloader.style-3 {
  background: #029e7c;
}

.preloader .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  animation: loader 1.2s infinite ease-in-out;
}

.preloader .loader span {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  animation: loaderBlock 1.2s infinite ease-in-out both;
}

.preloader .loader span:nth-child(1) {
  top: 0;
  left: 0;
}

.preloader .loader span:nth-child(2) {
  top: 0;
  right: 0;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}

.preloader .loader span:nth-child(3) {
  bottom: 0;
  left: 0;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}

.preloader .loader span:nth-child(4) {
  bottom: 0;
  right: 0;
}

/* HTML: <div class="loader"></div> */
.loader-fucha {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  background: linear-gradient(90deg, #000 50%, rgba(0, 0, 0, 0) 0) right/200% 100%;
  animation: l21 2s infinite linear;
}

.loader-fucha::before {
  content: "Ładowanie Fuch...";
  color: rgba(0, 0, 0, 0);
  padding: 0 5px;
  background: inherit;
  background-image: linear-gradient(90deg, #fff 50%, #000 0);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes l21 {
  100% {
    background-position: left;
  }
}
/*=======================================
  3. search area start
=======================================*/
.mobile-search {
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-items: center;
  position: fixed;
  cursor: pointer;
  transform: scale(0);
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.65s ease;
  padding: 35px 100px;
}
@media (max-width: 767px) {
  .mobile-search {
    padding: 20px 20px;
  }
}
.mobile-search label {
  color: #fff;
  margin-bottom: 20px;
  font-family: "Saira", sans-serif;
}
.mobile-search.slide {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.mobile-search input {
  border: none;
  border-radius: unset;
  border-bottom: 1px solid #eeeeee;
  padding-left: 0px;
  padding-bottom: 5px;
  width: 100%;
  background: transparent;
  transition: 0.3s ease-in-out;
  color: #fff;
  margin-bottom: 20px;
}
.mobile-search input:focus {
  border-bottom: 1px solid #fff;
}
.mobile-search input::-moz-placeholder {
  font-family: "Saira", sans-serif;
  color: #696969;
}
.mobile-search input::placeholder {
  font-family: "Saira", sans-serif;
  color: #696969;
}
.mobile-search .search-cross-btn {
  color: #fff;
  cursor: pointer;
  background: rgba(249, 57, 95, 0.6);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  line-height: 41px;
  transition: 0.5s ease;
}
.mobile-search .search-cross-btn:hover {
  background: #f9395f;
  color: #fff;
}
.mobile-search .search-cross-btn.style-2 {
  background-color: transparent;
  border: 1px solid #f9395f;
}
.mobile-search .search-cross-btn.style-2:hover {
  background: #f9395f;
  color: #fff;
}
.mobile-search .search-cross-btn.style-3 {
  background-color: transparent;
  border: 1px solid #029e7c;
}
.mobile-search .search-cross-btn.style-3:hover {
  background: #029e7c;
  color: #fff;
}
.mobile-search .search-cross-btn i {
  font-size: 25px;
  cursor: pointer;
}

/*=======================================
  3. search area end
=======================================*/
/*=======================================
   4. Topbar Css
=======================================*/
.topbar {
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px;
  position: relative;
  z-index: 999;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .topbar {
    padding: 10px 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .topbar {
    padding: 10px 30px;
  }
}
@media (max-width: 991px) {
  .topbar {
    display: none;
    visibility: hidden;
  }
}

.topbar-left h6 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  color: #696969;
  margin-right: 15px;
  line-height: 10px;
}
.topbar-left .topbar-social-list {
  display: flex;
  justify-content: start;
  align-content: center;
  padding: 0;
  margin: 0;
}
.topbar-left .topbar-social-list li a {
  color: #f9395f;
}
.topbar-left .topbar-social-list li .bx {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
  background: transparent;
  transition: all 0.42s ease-in-out;
}
.topbar-left .topbar-social-list li .bx:hover {
  background: #f9395f;
  color: #fff;
}

.email-area h6 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}

.topbar-right .topbar-right-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}
.topbar-right .topbar-right-list li {
  position: relative;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  transition: all 0.35s ease;
  font-size: 14px;
  font-weight: 400;
  color: #696969;
  font-family: "Saira", sans-serif;
}
.topbar-right .topbar-right-list li span,
.topbar-right .topbar-right-list li a {
  font-size: 14px;
  font-weight: 400;
  color: #696969;
  transition: all 0.35s ease;
  font-family: "Saira", sans-serif;
}
.topbar-right .topbar-right-list li:hover {
  color: #f9395f;
}
.topbar-right .topbar-right-list li:hover span,
.topbar-right .topbar-right-list li:hover a {
  color: inherit;
  font-family: "Saira", sans-serif;
}
.topbar-right .topbar-right-list li:hover .topbar-sublist {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}
.topbar-right .topbar-right-list li > img {
  margin-left: 15px;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 10px;
}
.topbar-right .topbar-right-list li .topbar-sublist {
  position: absolute;
  top: 30px;
  left: 0px;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #f5f5f5;
  min-width: 100px;
  height: auto;
  z-index: 9;
  border: 1px solid rgba(255, 255, 255, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: all 0.6s ease;
  text-align: right;
}
.topbar-right .topbar-right-list li .topbar-sublist li {
  padding: 8px 12px;
  display: flex;
  justify-content: initial;
}
.topbar-right .topbar-right-list li .topbar-sublist li img {
  margin-left: 0px;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 12px;
}

.header-logo {
  background-color: #f9395f;
  text-align: center;
}
.header-logo .logo-text {
  font-size: 38px;
  color: #fff;
  margin-bottom: 0px;
}

.MuiDrawer-modal {
  z-index: 99999 !important;
}

.mobile-header-area {
  background-color: #f9395f;
  display: none;
  text-align: center;
  position: relative;
}
@media (max-width: 1199px) {
  .mobile-header-area {
    display: block;
  }
}
.mobile-header-area p {
  margin: 0px;
}
.mobile-header-area .mobile-logo {
  z-index: 999;
}
.mobile-header-area .mobile-logo .logo-text {
  font-size: 30px;
  color: #fff;
}
.mobile-header-area .mobile-logo .logo-text-home {
  font-size: 30px;
  color: #f9395f;
}
.mobile-header-area .notification-icon-container {
  position: absolute;
  right: 20px;
  top: 10px;
}
.mobile-header-area .notification-icon-container a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-header-area .notification-icon-container a svg {
  color: #fff;
  height: 24px;
  width: 24px;
}

.bottom-nav-container {
  display: none;
}
@media (max-width: 1199px) {
  .bottom-nav-container {
    display: block;
  }
}

.drawer-divider-text {
  font-weight: 500;
  margin: 10px 0 5px 17px;
  color: rgba(15, 15, 15, 0.5294117647);
  font-size: small;
}

.mobile-menu-form {
  margin-top: 50px;
}
.mobile-menu-form input {
  width: 100%;
  border: 1px solid #eeeeee;
  padding: 10px 15px;
  background-color: #fff;
  margin-bottom: 15px;
  outline: none;
  border-radius: 5px;
}
.mobile-menu-form input::-moz-placeholder {
  font-size: 15px;
}
.mobile-menu-form input::placeholder {
  font-size: 15px;
}
.mobile-menu-form button {
  outline: none;
  border: none;
  padding: 10px;
}
.mobile-menu-form.style-2 input {
  border-radius: 30px;
}

.hotline-info {
  text-align: center;
  font-family: "Saira", sans-serif;
}
.hotline-info h6 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Saira", sans-serif;
}

header.style-1 {
  background-color: #fff;
  width: 100%;
  position: relative;
  top: 0;
  z-index: 99;
  padding: 0px 60px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 {
    padding: 0px 30px 0px 0px;
  }
}
@media (max-width: 1199px) {
  header.style-1 {
    display: none;
  }
}
header.style-1.sticky {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
  animation: smooth-header 0.65s linear;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-1 .nav-right .hotline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}
header.style-1 .nav-right .hotline .hotline-icon {
  margin-right: 8px;
}
header.style-1 .nav-right .hotline .hotline-info span {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #696969;
}
header.style-1 .nav-right .hotline .hotline-info h6 {
  font-size: 14px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #1f2230;
  white-space: nowrap;
}
header.style-1 .nav-right .search-btn {
  width: 42px;
  height: 42px;
  line-height: 45px;
  text-align: center;
  background-color: #f9395f;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}
header.style-1 .nav-right .search-btn .bi {
  font-size: 20px;
  color: #fff;
}
header.style-1 .header-logo {
  background: #f9395f;
  height: 100%;
  padding: 0 50px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .header-logo {
    padding: 0 30px;
  }
}
@media (max-width: 1199px) {
  header.style-1 .header-logo {
    padding: 0 30px;
  }
}
header.style-1 .header-logo p {
  font-size: 50px;
  color: #fff;
  margin-bottom: 10px;
}
header.style-1 .header-btn {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  display: inline-block;
  transition: 0.35s ease-in;
  background: #f9395f;
  cursor: pointer;
  white-space: nowrap;
}
header.style-1 .header-btn:hover {
  background: #191a1c;
}
header.style-1 .header-btn:hover a {
  color: #fff;
}
@media (max-width: 1199px) {
  header.style-1 .header-btn {
    display: none;
    visibility: hidden;
  }
}
header.style-1 .main-menu .menu-list {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding: 0;
}
header.style-1 .main-menu .menu-list > li {
  position: relative;
  margin: 0px 17px;
  display: inline-block;
}
header.style-1 .main-menu .menu-list > li .dropdown-icon {
  color: #1f2230;
  font-size: 25px;
  transition: 0.4s ease-in;
  opacity: 0;
  visibility: hidden;
}
header.style-1 .main-menu .menu-list > li a {
  font-size: 16px;
  font-weight: 500;
  color: #1f2230;
  padding: 30px 0px;
  display: inline-block;
  white-space: nowrap;
  transition: 0.4s;
  display: flex;
  align-items: center;
  font-family: "Saira", sans-serif;
}
header.style-1 .main-menu .menu-list > li a.active {
  color: #f9395f;
}
header.style-1 .main-menu .menu-list > li a:hover {
  color: #f9395f;
}
header.style-1 .main-menu .menu-list > li .submenu {
  padding: 0;
  position: absolute;
  top: 84px;
  left: 0px;
  max-width: 230px;
  min-width: 200px;
  background-color: #fff;
  text-align: left;
  transform: translateY(25px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.55s ease-in-out;
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
}
header.style-1 .main-menu .menu-list > li .submenu > li a {
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(31, 34, 48, 0.1);
  position: relative;
  z-index: 1;
  font-size: 14px;
  color: #1f2230;
}
header.style-1 .main-menu .menu-list > li .submenu > li a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f9395f;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: -1;
}
header.style-1 .main-menu .menu-list > li .submenu > li a:hover {
  color: #f9395f;
}
header.style-1 .main-menu .menu-list > li .submenu > li a:hover::before {
  transform: scale(1);
}
header.style-1 .main-menu .menu-list > li .submenu > li a:last-child {
  border-bottom: unset;
}
header.style-1 .main-menu .menu-list > li.menu-item-has-children {
  position: relative;
  z-index: 1;
  padding-right: 20px;
}
header.style-1 .main-menu .menu-list > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 600;
  position: absolute;
  top: 33px;
  right: 0px;
  font-size: 13px;
  transition: all 0.42s ease-in-out;
}
header.style-1 .main-menu .menu-list > li.menu-item-has-children .dropdown-icon {
  position: absolute;
  top: 12px;
  right: 0;
  font-size: 20px;
}
header.style-1 .main-menu .menu-list > li.menu-item-has-children:hover .submenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
header.style-1 .main-menu .menu-list > li.menu-item-has-children:hover::after {
  color: #f9395f;
  transform: rotate(180deg);
}
header.style-1 .main-menu .mobile-logo-area {
  margin-bottom: 25px;
}
header.style-1 .main-menu .mobile-logo-area .menu-close-btn {
  cursor: pointer;
}
header.style-1 .main-menu .mobile-logo-area .menu-close-btn .bi {
  color: #1f2230;
  font-size: 18px;
}
header.style-1 .main-menu .mobile-logo-area .menu-close-btn .bi:hover {
  color: #f9395f;
}
header.style-1 .main-menu .mobile-logo-wrap {
  background: #f9395f;
  padding: 10px;
}

.mobile-menu-btn {
  margin-left: 25px;
}
.mobile-menu-btn .bx {
  color: #1f2230;
  font-size: 28px;
}

header.style-2 {
  background-color: transparent;
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 99;
  padding: 0px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991px) {
  header.style-2 {
    padding: 20px 30px 20px 30px;
  }
}
header.style-2.sticky {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
  animation: smooth-header 0.65s linear;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-2 .nav-right .hotline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}
header.style-2 .nav-right .hotline .hotline-icon {
  margin-right: 8px;
}
header.style-2 .nav-right .hotline .hotline-info span {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #696969;
}
header.style-2 .nav-right .hotline .hotline-info h6 {
  font-size: 14px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #1f2230;
  white-space: nowrap;
}
header.style-2 .nav-right .search-btn {
  width: 42px;
  height: 42px;
  line-height: 45px;
  text-align: center;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}
@media (max-width: 991px) {
  header.style-2 .nav-right .search-btn {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .nav-right .search-btn .bi {
  font-size: 20px;
  color: #f9395f;
}
header.style-2 .header-logo img {
  width: 100%;
  min-width: 120px;
}
header.style-2 .header-btn {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 100px;
  display: inline-block;
  transition: 0.35s ease-in;
  background: #f9395f;
  cursor: pointer;
  white-space: nowrap;
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.style-2 .header-btn {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .header-btn:hover {
  background: #191a1c;
}
header.style-2 .header-btn:hover a {
  color: #fff;
}
@media (max-width: 991px) {
  header.style-2 .header-btn {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .main-menu .menu-list {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding: 0;
}
@media (max-width: 991px) {
  header.style-2 .main-menu .menu-list {
    flex-direction: column;
    justify-content: start;
  }
}
header.style-2 .main-menu .menu-list > li {
  position: relative;
  margin: 0px 17px;
  display: inline-block;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu .menu-list > li {
    margin: 0px 15px;
  }
}
@media (max-width: 991px) {
  header.style-2 .main-menu .menu-list > li {
    margin: 0;
    width: 100%;
  }
}
header.style-2 .main-menu .menu-list > li .dropdown-icon {
  color: #1f2230;
  font-size: 25px;
  transition: 0.4s ease-in;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 991px) {
  header.style-2 .main-menu .menu-list > li .dropdown-icon {
    opacity: 1;
    visibility: visible;
  }
}
header.style-2 .main-menu .menu-list > li a {
  font-size: 16px;
  font-weight: 500;
  color: #1f2230;
  padding: 30px 0px;
  display: inline-block;
  white-space: nowrap;
  transition: 0.4s;
  display: flex;
  align-items: center;
  font-family: "Saira", sans-serif;
}
header.style-2 .main-menu .menu-list > li a.active {
  color: #f9395f;
}
header.style-2 .main-menu .menu-list > li a:hover {
  color: #f9395f;
}
@media (max-width: 991px) {
  header.style-2 .main-menu .menu-list > li a {
    padding: 12px 0px;
    display: block;
    position: relative;
    border-bottom: 1px solid rgba(31, 34, 48, 0.098);
  }
  header.style-2 .main-menu .menu-list > li a:hover {
    color: unset;
  }
}
header.style-2 .main-menu .menu-list > li .submenu {
  padding: 0;
  position: absolute;
  top: 84px;
  left: 0px;
  max-width: 230px;
  min-width: 200px;
  background-color: #fff;
  text-align: left;
  transform: translateY(25px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.55s ease-in-out;
  box-shadow: 2px 3px 20px rgba(31, 34, 48, 0.1);
}
@media (max-width: 991px) and (max-width: 991px) {
  header.style-2 .main-menu .menu-list > li .submenu {
    opacity: 1;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    border: unset;
    display: none;
    transition: unset;
    box-shadow: unset;
    transform: translateY(0px);
    visibility: unset;
  }
}
header.style-2 .main-menu .menu-list > li .submenu > li a {
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(31, 34, 48, 0.1);
  position: relative;
  z-index: 1;
  font-size: 14px;
  color: #1f2230;
}
@media (max-width: 991px) {
  header.style-2 .main-menu .menu-list > li .submenu > li a {
    border-bottom: unset;
    padding: 12px 15px;
  }
}
header.style-2 .main-menu .menu-list > li .submenu > li a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f9395f;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: -1;
}
header.style-2 .main-menu .menu-list > li .submenu > li a:hover {
  color: #f9395f;
}
header.style-2 .main-menu .menu-list > li .submenu > li a:hover::before {
  transform: scale(1);
}
header.style-2 .main-menu .menu-list > li .submenu > li a:last-child {
  border-bottom: unset;
}
header.style-2 .main-menu .menu-list > li.menu-item-has-children {
  position: relative;
  z-index: 1;
  padding-right: 20px;
}
@media (max-width: 991px) {
  header.style-2 .main-menu .menu-list > li.menu-item-has-children {
    padding-right: unset;
  }
}
header.style-2 .main-menu .menu-list > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 600;
  position: absolute;
  top: 33px;
  right: 0px;
  font-size: 13px;
  transition: all 0.42s ease-in-out;
}
@media (max-width: 991px) {
  header.style-2 .main-menu .menu-list > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .main-menu .menu-list > li.menu-item-has-children .dropdown-icon {
  position: absolute;
  top: 12px;
  right: 0;
  font-size: 20px;
}
header.style-2 .main-menu .menu-list > li.menu-item-has-children:hover .submenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
header.style-2 .main-menu .menu-list > li.menu-item-has-children:hover::after {
  color: #f9395f;
  transform: rotate(180deg);
}
@media (max-width: 991px) {
  header.style-2 .main-menu {
    position: fixed;
    top: 0%;
    left: -100%;
    width: 280px;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    border-right: 1px solid rgba(238, 238, 238, 0.1);
    padding: 40px 20px;
    z-index: 999;
    transition: 0.7s ease-in-out;
    border-right: 1px solid #eeeeee;
  }
  header.style-2 .main-menu.show-menu {
    left: 0;
  }
}
header.style-2 .main-menu .mobile-logo-area {
  margin-bottom: 25px;
}
header.style-2 .main-menu .mobile-logo-area .menu-close-btn {
  cursor: pointer;
}
header.style-2 .main-menu .mobile-logo-area .menu-close-btn .bi {
  color: #1f2230;
  font-size: 20px;
}
header.style-2 .main-menu .mobile-logo-area .menu-close-btn .bi:hover {
  color: #f9395f;
}
header.style-2 .join-btn {
  color: #f9395f;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 30px;
  transition: 0.42s ease;
  white-space: nowrap;
}
@media (max-width: 1399px) {
  header.style-2 .join-btn {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .join-btn:hover {
  color: #f9395f;
}

header.style-3 {
  background-color: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 99;
  padding: 0px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  header.style-3 {
    padding: 0px 30px;
  }
}
header.style-3.sticky {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999;
  background: #fff;
  transition: 0.2s ease;
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
  animation: smooth-header 0.65s linear;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-3 .nav-right .hotline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}
header.style-3 .nav-right .hotline .hotline-icon {
  margin-right: 8px;
}
header.style-3 .nav-right .hotline .hotline-info span {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #696969;
}
header.style-3 .nav-right .hotline .hotline-info h6 {
  font-size: 14px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #1f2230;
  white-space: nowrap;
}
header.style-3 .nav-right .search-btn {
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #029e7c;
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
}
header.style-3 .nav-right .search-btn .bi {
  font-size: 18px;
  color: #029e7c;
}
@media (max-width: 991px) {
  header.style-3 .nav-right .search-btn {
    display: none;
    visibility: hidden;
  }
}
header.style-3 .header-logo {
  padding: 20px 0px;
}
header.style-3 .header-logo img {
  min-width: 120px;
  max-width: 100%;
}
header.style-3 .header-btn {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}
header.style-3 .header-btn:hover {
  background: #191a1c;
}
header.style-3 .header-btn:hover a {
  color: #fff;
}
@media (max-width: 1199px) {
  header.style-3 .header-btn {
    display: none;
    visibility: hidden;
  }
}
header.style-3 .main-menu .menu-list {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding: 0;
}
@media (max-width: 991px) {
  header.style-3 .main-menu .menu-list {
    flex-direction: column;
    justify-content: start;
  }
}
header.style-3 .main-menu .menu-list > li {
  position: relative;
  margin: 0px 17px;
  display: inline-block;
}
@media (max-width: 1199px) {
  header.style-3 .main-menu .menu-list > li {
    margin: 0px 15px;
  }
}
@media (max-width: 991px) {
  header.style-3 .main-menu .menu-list > li {
    margin: 0;
    width: 100%;
  }
}
header.style-3 .main-menu .menu-list > li .dropdown-icon {
  color: #1f2230;
  font-size: 25px;
  transition: 0.4s ease-in;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 991px) {
  header.style-3 .main-menu .menu-list > li .dropdown-icon {
    opacity: 1;
    visibility: visible;
  }
}
header.style-3 .main-menu .menu-list > li a {
  font-size: 16px;
  font-weight: 500;
  color: #1f2230;
  padding: 30px 0px;
  display: inline-block;
  white-space: nowrap;
  transition: 0.4s;
  display: flex;
  align-items: center;
  font-family: "Saira", sans-serif;
}
header.style-3 .main-menu .menu-list > li a.active {
  color: #029e7c;
}
header.style-3 .main-menu .menu-list > li a:hover {
  color: #029e7c;
}
@media (max-width: 991px) {
  header.style-3 .main-menu .menu-list > li a {
    padding: 12px 0px;
    display: block;
    position: relative;
    border-bottom: 1px solid rgba(31, 34, 48, 0.098);
  }
  header.style-3 .main-menu .menu-list > li a:hover {
    color: unset;
  }
}
header.style-3 .main-menu .menu-list > li .submenu {
  padding: 0;
  position: absolute;
  top: 84px;
  left: 0px;
  max-width: 230px;
  min-width: 200px;
  background-color: #fff;
  text-align: left;
  transform: translateY(25px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.55s ease-in-out;
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
}
@media (max-width: 991px) {
  header.style-3 .main-menu .menu-list > li .submenu {
    opacity: 1;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    border: unset;
    display: none;
    transition: unset;
    box-shadow: unset;
    transform: translateY(0px);
    visibility: unset;
  }
}
header.style-3 .main-menu .menu-list > li .submenu > li a {
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(31, 34, 48, 0.1);
  position: relative;
  z-index: 1;
  font-size: 14px;
  color: #1f2230;
}
@media (max-width: 991px) {
  header.style-3 .main-menu .menu-list > li .submenu > li a {
    border-bottom: unset;
    padding: 12px 15px;
  }
}
header.style-3 .main-menu .menu-list > li .submenu > li a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #029e7c;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: -1;
}
header.style-3 .main-menu .menu-list > li .submenu > li a:hover {
  color: #029e7c;
}
header.style-3 .main-menu .menu-list > li .submenu > li a:hover::before {
  transform: scale(1);
}
header.style-3 .main-menu .menu-list > li .submenu > li a:last-child {
  border-bottom: unset;
}
header.style-3 .main-menu .menu-list > li.menu-item-has-children {
  position: relative;
  z-index: 1;
  padding-right: 20px;
}
@media (max-width: 991px) {
  header.style-3 .main-menu .menu-list > li.menu-item-has-children {
    padding-right: unset;
  }
}
header.style-3 .main-menu .menu-list > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 600;
  position: absolute;
  top: 33px;
  right: 0px;
  font-size: 13px;
  transition: all 0.42s ease-in-out;
}
@media (max-width: 991px) {
  header.style-3 .main-menu .menu-list > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
header.style-3 .main-menu .menu-list > li.menu-item-has-children .dropdown-icon {
  position: absolute;
  top: 12px;
  right: 0;
  font-size: 20px;
}
header.style-3 .main-menu .menu-list > li.menu-item-has-children:hover .submenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
header.style-3 .main-menu .menu-list > li.menu-item-has-children:hover::after {
  color: #029e7c;
  transform: rotate(180deg);
}
@media (max-width: 991px) {
  header.style-3 .main-menu {
    position: fixed;
    top: 0%;
    left: -100%;
    width: 280px;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    border-right: 1px solid rgba(238, 238, 238, 0.1);
    padding: 40px 20px;
    z-index: 999;
    transition: 0.7s ease-in-out;
    border-right: 1px solid #eeeeee;
  }
  header.style-3 .main-menu.show-menu {
    left: 0;
  }
}
header.style-3 .main-menu .mobile-logo-area {
  margin-bottom: 25px;
}
header.style-3 .main-menu .mobile-logo-area .menu-close-btn {
  cursor: pointer;
}
header.style-3 .main-menu .mobile-logo-area .menu-close-btn .bi {
  color: #1f2230;
  font-size: 20px;
}
header.style-3 .main-menu .mobile-logo-area .menu-close-btn .bi:hover {
  color: #029e7c;
}
header.style-3 .main-menu .mobile-logo-wrap {
  padding: 10px 0px;
}
header.style-3 .mobile-menu-form input[type=text] {
  border-radius: 30px;
  color: #029e7c;
}

/*=======================================
   banner-area
=======================================*/
.hero-style-one {
  position: relative;
  overflow: hidden;
}
.hero-style-one .swiper-slide-active span {
  animation: fadeInDown 1.7s;
}
.hero-style-one .swiper-slide-active h1 {
  animation: fadeInDown 1.7s;
}
.hero-style-one .swiper-slide-active h2 {
  animation: fadeInDown 1.7s;
}
.hero-style-one .swiper-slide-active p {
  animation: fadeInUp 1.7s;
}
.hero-style-one .swiper-slide-active .eg-btn {
  animation: fadeInUp 1.7s;
}
.hero-style-one .hero-one-pagination {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  width: 80px;
}
@media (max-width: 767px) {
  .hero-style-one .hero-one-pagination {
    display: none;
    visibility: hidden;
  }
}
.hero-style-one .hero-one-pagination .swiper-pagination-bullet {
  background: #fff;
  width: 15px;
  height: 15px;
}
.hero-style-one .hero-one-pagination .swiper-pagination-bullet-active {
  background: #f9395f;
}
.hero-style-one .slider-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
  z-index: 2;
  padding: 5px;
}
.hero-style-one .slider-bg-1 {
  padding: 160px 0px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  z-index: 1;
  position: relative;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-style-one .slider-bg-1 {
    padding: 160px 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hero-style-one .slider-bg-1 {
    padding: 160px 0px;
  }
}
@media (max-width: 991px) {
  .hero-style-one .slider-bg-1 {
    padding: 120px 0px;
  }
}
.hero-style-one .slider-bg-1::before {
  content: "";
  background-image: url("../../public/images//bg/banner-bg1.png");
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -9;
  animation: large 26s linear infinite alternate;
}
.hero-style-one .slider-bg-1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -9;
  background: rgba(0, 0, 0, 0.55);
}
@keyframes large {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.6);
  }
}
.hero-style-one .slider-bg-2 {
  padding: 160px 0px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  z-index: 1;
  position: relative;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-style-one .slider-bg-2 {
    padding: 160px 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hero-style-one .slider-bg-2 {
    padding: 160px 0px;
  }
}
@media (max-width: 991px) {
  .hero-style-one .slider-bg-2 {
    padding: 120px 0px;
  }
}
.hero-style-one .slider-bg-2::before {
  content: "";
  background-image: url("../../public/images//bg/banner-bg2.png");
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -9;
  animation: large 26s linear infinite alternate;
}
.hero-style-one .slider-bg-2::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -9;
  background: rgba(0, 0, 0, 0.55);
}
@keyframes large {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.8);
  }
}
.hero-style-one .banner1-content {
  width: 100%;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.hero-style-one .banner1-content span {
  font-size: 1.25rem;
  font-weight: 400;
  color: #eeeeee;
  font-family: "Inter", sans-serif;
  margin-bottom: 10px;
  display: block;
}
.hero-style-one .banner1-content h1,
.hero-style-one .banner1-content h2 {
  font-size: 4.8rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
  line-height: 1.2;
  text-transform: capitalize;
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-one .banner1-content h1,
  .hero-style-one .banner1-content h2 {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .hero-style-one .banner1-content h1,
  .hero-style-one .banner1-content h2 {
    font-size: 50px;
  }
}
.hero-style-one .banner1-content p {
  font-size: 16px;
  font-weight: 400;
  color: #eeeeee;
  margin-bottom: 55px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-one .banner1-content p {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .hero-style-one .banner1-content p {
    width: 98%;
  }
}

.hero-style-two {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 991px) {
  .hero-style-two {
    height: calc(100dvh - 61px);
  }
}
@media (max-width: 991px) {
  .hero-style-two .container {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .hero-style-two .hero-style-two-mobiles {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: rgba(82, 67, 67, 0.65);
  }
}
.hero-style-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  height: 100%;
  background-image: url("../../public/images//bg/banner2-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.hero-style-two::after {
  content: "";
  position: absolute;
  right: -50%;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/images//bg/banner2-girl.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
@media (max-width: 991px) {
  .hero-style-two::after {
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
  }
}
.hero-style-two .banner2-icon1 {
  position: absolute;
  top: 240px;
  right: 38%;
  animation: rotate 12s linear infinite;
}
@media (max-width: 1199px) {
  .hero-style-two .banner2-icon1 {
    display: none;
    visibility: hidden;
  }
}
.hero-style-two .banner2-icon2 {
  position: absolute;
  top: 130px;
  right: 8%;
  animation: rotate 12s linear infinite;
}
@media (max-width: 1399px) {
  .hero-style-two .banner2-icon2 {
    display: none;
    visibility: hidden;
  }
}
.hero-style-two .banner2-icon3 {
  position: absolute;
  bottom: 130px;
  right: 40%;
  animation: rotate 12s linear infinite;
}
@media (max-width: 1399px) {
  .hero-style-two .banner2-icon3 {
    display: none;
    visibility: hidden;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hero-style-two .banner-girl {
  max-width: 120%;
}
.hero-style-two .radial-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.hero-style-two .scroll-text {
  position: absolute;
  transform: rotate(270deg);
  left: 30px;
  bottom: 160px;
  cursor: pointer;
}
@media (min-width: 1400px) and (max-width: 1499px) {
  .hero-style-two .scroll-text {
    left: 0;
  }
}
@media (max-width: 1399px) {
  .hero-style-two .scroll-text {
    display: none;
    visibility: hidden;
  }
}
.hero-style-two .scroll-text h6 {
  font-size: 18px;
  font-weight: 600;
  color: #696969;
  position: relative;
}
.hero-style-two .scroll-text h6::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%) rotate(270deg);
  width: 3px;
  height: 65px;
  background-image: linear-gradient(0deg, #f9395f 30%, dimgray 70%);
}
.hero-style-two .banner2-content {
  padding: 200px 0;
}
@media (max-width: 991px) {
  .hero-style-two .banner2-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .hero-style-two .banner2-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
  }
}
.hero-style-two .banner2-content span {
  font-size: 20px;
  font-weight: 600;
  color: #f9395f;
  font-family: "Inter", sans-serif;
  margin-bottom: 10px;
  display: block;
}
.hero-style-two .banner2-content h1 {
  font-size: 60px;
  font-weight: 700;
  color: #1f2230;
  margin-bottom: 20px;
  line-height: 1.2;
}
@media (max-width: 991px) {
  .hero-style-two .banner2-content h1 {
    font-size: 45px;
    color: #fff;
    position: relative;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-two .banner2-content h1 {
    font-size: 60px;
    position: relative;
  }
}
@media (max-width: 576px) {
  .hero-style-two .banner2-content h1 {
    font-size: 30px;
  }
}
.hero-style-two .banner2-content p {
  font-size: 16px;
  font-weight: 400;
  color: #696969;
  margin-bottom: 45px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 991px) {
  .hero-style-two .banner2-content p {
    color: #fff;
    text-shadow: 1px 0px 4px rgba(0, 0, 0, 0.46);
    position: relative;
  }
}
@media (max-width: 576px) {
  .hero-style-two .banner2-content p {
    font-size: 15px;
    max-width: 330px;
  }
}

.hero-style-three {
  overflow-x: hidden;
  padding: 0px 0px;
  position: relative;
  z-index: 1;
  background-image: url("../../public/images//bg/home3-bannerbg.png");
}
@media (max-width: 991px) {
  .hero-style-three {
    background-image: unset;
    padding-top: 85px;
  }
}
.hero-style-three .banner-ellips {
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: -1;
}
@media (max-width: 991px) {
  .hero-style-three .banner-ellips {
    display: none;
    visibility: hidden;
  }
}
.hero-style-three .join-merchant {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #029e7c;
  border-radius: 0px 22px 22px 0px;
  color: #fff;
  font-weight: 600;
  padding: 10px 20px 10px 10px;
  cursor: pointer;
}
@media (max-width: 1500px) {
  .hero-style-three .join-merchant {
    display: none;
    visibility: hidden;
  }
}
.hero-style-three::before {
  content: "";
  position: absolute;
  right: 0;
  top: 85px;
  bottom: 85px;
  width: 100%;
  max-width: 560px;
  height: auto;
  background: #029e7c;
  border-radius: 279px 0px 0px 279px;
  z-index: -1;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-style-three::before {
    max-width: 450px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hero-style-three::before {
    max-width: 380px;
  }
}
@media (max-width: 991px) {
  .hero-style-three::before {
    display: none;
    visibility: hidden;
  }
}
.hero-style-three .home3-banner {
  position: absolute;
  width: 100%;
  max-width: 580px;
  height: auto;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-style-three .home3-banner {
    max-width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hero-style-three .home3-banner {
    max-width: 400px;
  }
}
@media (max-width: 991px) {
  .hero-style-three .home3-banner {
    display: none;
    visibility: hidden;
  }
}
.hero-style-three .banner3-content {
  width: 100%;
  padding: 240px 60px 240px 45px;
}
@media (max-width: 991px) {
  .hero-style-three .banner3-content {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.55)), url("../../public/images//bg/home3-banner.png");
    text-align: center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-style-three .banner3-content {
    padding: 200px 60px 200px 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-three .banner3-content {
    padding: 80px 60px;
  }
}
@media (max-width: 767px) {
  .hero-style-three .banner3-content {
    padding: 80px 20px;
  }
}
.hero-style-three .banner3-content span {
  font-size: 20px;
  font-weight: 600;
  color: #029e7c;
  font-family: "Inter", sans-serif;
  margin-bottom: 10px;
  display: block;
}
.hero-style-three .banner3-content h1 {
  font-size: 73px;
  font-weight: 700;
  color: #1f2230;
  margin-bottom: 20px;
  line-height: 1.2;
}
@media (max-width: 991px) {
  .hero-style-three .banner3-content h1 {
    color: #fff;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-three .banner3-content h1 {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .hero-style-three .banner3-content h1 {
    font-size: 50px;
  }
}
.hero-style-three .banner3-content p {
  font-size: 16px;
  font-weight: 400;
  color: #696969;
  margin-bottom: 45px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 991px) {
  .hero-style-three .banner3-content p {
    color: #fff;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-three .banner3-content p {
    width: 80%;
  }
}

/*=======================================
   Category-Slider
=======================================*/
.category-section .swiper {
  margin: -12px;
  padding: 12px;
}

.category-card1 {
  max-width: 170px;
  min-width: 170px;
  border-radius: 40px 0px;
  text-align: center;
  padding: 20px 5px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.4s ease;
  background: #fff;
  position: relative;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: unset;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
}
.category-card1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f9395f;
  transform: scaleX(0);
  border-radius: 5px;
  z-index: -1;
  transform-origin: right;
  transition: transform 0.35s ease-in;
}
.category-card1:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}
.category-card1:hover .cat-icon svg {
  fill: #fff;
}
.category-card1:hover h5 {
  color: #fff;
}
.category-card1 .cat-icon {
  margin-bottom: 15px;
}
.category-card1 .cat-icon svg {
  margin-bottom: 0;
  fill: #1f2230;
  transition: all 0.35s ease-in;
}
.category-card1 h5 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  color: #1f2230;
  transition: all 0.35s ease-in;
  cursor: pointer;
}
.category-card1.style2::before {
  background: #029e7c;
}

.category-prev1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -40px;
}
.category-prev1 i {
  height: 38px;
  width: 38px;
  line-height: 38px;
  border-radius: 50%;
  background: #fff;
  color: #484848;
  font-size: 16px;
  text-align: center;
  border: 1px solid #484848;
  transition: 0.5s ease;
}
.category-prev1:hover i {
  background: #f9395f;
  border: 1px solid #f9395f;
  color: #fff;
}
.category-prev1.style2:hover i {
  background: #029e7c;
  border: 1px solid #029e7c;
  color: #fff;
}

.category-next1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -46px;
}
.category-next1 i {
  height: 38px;
  width: 38px;
  line-height: 38px;
  border-radius: 50%;
  background: #fff;
  color: #484848;
  font-size: 16px;
  text-align: center;
  border: 1px solid #484848;
  transition: 0.5s ease;
}
.category-next1:hover i {
  background: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.category-next1.style2:hover i {
  background: #029e7c;
  border: 1px solid #029e7c;
  color: #fff;
}

.category-prev2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -40px;
}
@media (max-width: 1199px) {
  .category-prev2 {
    display: none;
    visibility: hidden;
  }
}
.category-prev2 i {
  height: 38px;
  width: 38px;
  line-height: 38px;
  border-radius: 50%;
  background: #fff;
  color: rgba(249, 57, 95, 0.6);
  font-size: 16px;
  text-align: center;
  border: 1px solid rgba(249, 57, 95, 0.6);
  transition: 0.5s ease;
}
.category-prev2:hover i {
  background: #f9395f;
  border: 1px solid #f9395f;
  color: #fff;
}

.category-next2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -40px;
}
@media (max-width: 1199px) {
  .category-next2 {
    display: none;
    visibility: hidden;
  }
}
.category-next2 i {
  height: 38px;
  width: 38px;
  line-height: 38px;
  border-radius: 50%;
  background: #fff;
  color: rgba(249, 57, 95, 0.6);
  font-size: 16px;
  text-align: center;
  border: 1px solid rgba(249, 57, 95, 0.6);
  transition: 0.5s ease;
}
.category-next2:hover i {
  background: #f9395f;
  border: 1px solid #f9395f;
  color: #fff;
}

.category-card2 {
  width: 100%;
  max-width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.4s ease;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.category-card2::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(31, 34, 48, 0.45);
  z-index: -1;
  width: 100%;
  height: 100%;
}
.category-card2:hover .category-img {
  transform: scale(1.2);
}
.category-card2 .category-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}
.category-card2 .content {
  text-align: center;
}
.category-card2 .content img {
  margin-bottom: 15px;
  height: 60px;
}
.category-card2 .content h5 {
  text-align: center;
  transition: all 0.5s ease-in-out;
  margin-bottom: 0;
}
.category-card2 .content h5 a {
  transition: all 0.5s ease-in-out;
  color: #fff;
}

/*================================================
7. Live Auction
=================================================*/
.live-auction {
  position: relative;
  overflow: hidden;
}
@media (max-width: 576px) {
  .live-auction.latest-fuchy .slider-bottom {
    margin-bottom: -40px;
    margin-top: 20px;
  }
}
@media (max-width: 576px) {
  .live-auction.latest-fuchy .slider-arrows {
    padding-right: 5px;
  }
}
.live-auction .latest-fuchy-container .auction-card2 {
  max-width: 416px;
}
@media (max-width: 576px) {
  .live-auction .latest-fuchy-container .auction-card2 {
    max-width: 95%;
  }
}
@media (max-width: 991px) {
  .live-auction .latest-fuchy-container .col {
    padding: 0;
  }
}
.live-auction .section-bg {
  position: absolute;
  top: 7%;
  left: 0%;
}
@media (max-width: 767px) {
  .live-auction .section-bg {
    display: none;
    visibility: hidden;
  }
}
.live-auction .section-bg2 {
  position: absolute;
  top: 7%;
  right: 0%;
}
@media (max-width: 767px) {
  .live-auction .section-bg2 {
    display: none;
    visibility: hidden;
  }
}
.live-auction .dotted1 {
  position: absolute;
  top: 58px;
  right: -80px;
  animation: move 2.6s linear infinite;
}
@media (max-width: 991px) {
  .live-auction .dotted1 {
    display: none;
    visibility: hidden;
  }
}
.live-auction .dotted2 {
  position: absolute;
  bottom: 40px;
  left: -80px;
  animation: move 2.6s linear infinite;
}
@media (max-width: 991px) {
  .live-auction .dotted2 {
    display: none;
    visibility: hidden;
  }
}
.live-auction .dotted3 {
  position: absolute;
  bottom: -40px;
  left: -80px;
  animation: move 2.6s linear infinite;
}
@media (max-width: 991px) {
  .live-auction .dotted3 {
    display: none;
    visibility: hidden;
  }
}
@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

.auction-card1 {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.auction-card1:hover .auction-img img {
  transform: scale(1.2);
}
.auction-card1 .auction-img {
  position: relative;
  overflow: hidden;
}
.auction-card1 .auction-img img {
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  transition: all 0.65s ease;
}
.auction-card1 .auction-img .auction-timer {
  border-radius: 30px;
  padding: 12px 20px 0px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  background: #fff;
  min-width: 230px;
}
.auction-card1 .auction-img .auction-timer .countdown h4 {
  font-size: 24px;
  font-weight: 600;
  color: #444444;
  margin-bottom: 0;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .auction-card1 .auction-img .auction-timer .countdown h4 {
    font-size: 22px;
  }
}
@media (max-width: 1199px) {
  .auction-card1 .auction-img .auction-timer .countdown h4 {
    font-size: 20px;
  }
}
.auction-card1 .auction-content {
  padding: 30px 25px;
}
.auction-card1 .auction-content h4 {
  font-size: 23px;
  font-weight: 600;
  color: #1f2230;
  line-height: 1.4;
  margin-top: -5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auction-card1 .auction-content p {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.auction-card1 .auction-content p span {
  font-size: 20px;
  font-weight: 700;
  color: #1f2230;
}
.auction-card1 .auction-content .price {
  font-size: 16px;
  font-weight: 700;
  color: #f9395f;
}
.auction-card1 .auction-card-bttm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;
}
.auction-card1 .type-of-work-area {
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}
.auction-card1 .type-of-work-area .react-tooltip {
  z-index: 1000;
}
.auction-card1 .type-of-work-area .type-of-work-emo {
  border-radius: 50%;
  background: #fff;
  padding: 7px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid transparent;
  z-index: 1;
}
.auction-card1 .type-of-work-area .type-of-work-emo img {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.auction-card1 .type-of-work-area .type-of-work-name {
  background: #f9395f;
  padding: 1px 20px 6px 44px;
  border-radius: 33px;
  margin-left: -27px;
  transform: scaleX(0.1);
  transform-origin: left;
  transition: 0.6s ease;
}
.auction-card1 .type-of-work-area .type-of-work-name span {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.auction-card1 .type-of-work-area:hover .type-of-work-emo {
  border: 1px solid #f9395f;
}
.auction-card1 .type-of-work-area:hover .type-of-work-name {
  transform: scaleX(1);
}
.auction-card1 .share-area {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  align-items: center;
}
.auction-card1 .share-area .share-btn i {
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 50%;
  background-color: #eeeeee;
  text-align: center;
  transition: 0.5s ease;
  z-index: 9;
}
.auction-card1 .share-area .share-btn i:hover {
  background: #1f2230;
  color: #fff;
}
.auction-card1 .share-area .social-icons {
  padding: 5px;
  margin-right: 0px;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.5s ease;
  z-index: 1;
  margin-bottom: 0px;
}
.auction-card1 .share-area:hover .social-icons {
  transform: scaleX(1);
}
.auction-card1 .share-area:hover .social-icons li {
  margin-right: 8px;
}
.auction-card1 .share-area:hover .social-icons li i {
  font-size: 1rem;
  transition: all 0.42s ease;
}
.auction-card1 .share-area:hover .social-icons li i:hover {
  color: #f9395f;
}

.auction-card2 {
  max-height: 579px;
  max-width: 416px;
  height: 579px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  padding: 15px 15px 0px 15px;
}
@media (max-width: 1399px) {
  .auction-card2 {
    height: unset;
  }
}
.auction-card2:hover .auction-img img {
  transform: scale(1.2);
  border-radius: 0.25rem;
}
.auction-card2:hover .auction-img .auction-timer .countdown h5 {
  color: #444444;
}
.auction-card2 .auction-img {
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
}
.auction-card2 .auction-img img {
  width: 100%;
  border-radius: 0.25rem;
  transition: all 0.65s ease;
}
@media (max-width: 576px) {
  .auction-card2 .auction-img .card-image {
    min-width: 100%;
    min-height: 320.33px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.auction-card2 .auction-img .auction-timer {
  border-radius: 15px 15px 0px 0px;
  padding: 12px 15px 0px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  background: #fff;
  min-width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.auction-card2 .auction-img .auction-timer h6 {
  padding-left: 0 !important;
  font-size: 14px;
  margin-top: 5px;
  color: #696969;
}
.auction-card2 .auction-img .auction-timer .countdown h5 {
  font-size: 20px;
  font-weight: 700;
  color: #444444;
  margin-bottom: 0;
  white-space: nowrap;
  transition: all 0.42s ease;
}
@media (max-width: 576px) {
  .auction-card2 .auction-img .auction-timer .countdown h5 {
    font-size: 18px;
  }
}
.auction-card2 .auction-content {
  padding: 30px 25px;
}
@media (max-width: 576px) {
  .auction-card2 .auction-content {
    padding: 25px 10px;
  }
}
.auction-card2 .auction-content h4 {
  font-size: 23px;
  font-weight: 600;
  color: #1f2230;
  line-height: 1.5;
  margin-top: -7px;
  min-height: 72px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auction-card2 .auction-content p {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.auction-card2 .auction-content p span {
  font-size: 20px;
  font-weight: bold;
  color: #1f2230;
}
.auction-card2 .auction-content .price {
  font-size: 16px;
  font-weight: 700;
  color: #f9395f;
}
.auction-card2 .auction-card-bttm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}
.auction-card2 .auction-card-bttm .share-area i {
  color: #696969;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.auction-card2 .auction-card-bttm .share-area i:hover {
  color: #f9395f;
}
.auction-card2 .author-price-area {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
}
@media (max-width: 991px) {
  .auction-card2 .author-price-area h6 {
    padding: 0;
  }
}
.auction-card2 .author-price-area .author img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.auction-card2 .author-price-area .author span.name {
  font-size: 14px;
  font-weight: 600;
  color: #696969;
}
.auction-card2 .author-price-area p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  color: #f9395f;
  font-family: "Saira", sans-serif;
}
.auction-card2 .type-of-work-area {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}
.auction-card2 .type-of-work-area .react-tooltip {
  z-index: 1000;
}
.auction-card2 .type-of-work-area .type-of-work-emo {
  border-radius: 50%;
  background: #fff;
  padding: 7px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid transparent;
  z-index: 1;
}
.auction-card2 .type-of-work-area .type-of-work-emo img {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.auction-card2 .type-of-work-area .type-of-work-name {
  background: #f9395f;
  padding: 1px 20px 6px 44px;
  border-radius: 33px;
  margin-left: -27px;
  transform: scaleX(0.1);
  transform-origin: left;
  transition: 0.6s ease;
}
.auction-card2 .type-of-work-area .type-of-work-name span {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
}
.auction-card2 .type-of-work-area:hover .type-of-work-emo {
  border: 1px solid #f9395f;
}
.auction-card2 .type-of-work-area:hover .type-of-work-name {
  transform: scaleX(1);
}
.auction-card2 .share-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.auction-card2 .share-list .social-icons {
  padding: 5px;
  margin-right: 0px;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.5s ease;
  z-index: 1;
  margin-bottom: 0px;
}
.auction-card2 .share-list:hover .social-icons {
  transform: scaleX(1);
}
.auction-card2 .share-list:hover .social-icons li {
  margin-right: 8px;
}
.auction-card2 .share-list:hover .social-icons li i {
  font-size: 1rem;
}

.auction-card3 {
  padding: 20px 20px 25px 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.06);
  border-radius: 0px 30px;
  border: unset;
}
.auction-card3:hover .auction-img {
  border-radius: 0px 30px;
}
.auction-card3:hover .auction-img img {
  transform: scale(1.2);
}
.auction-card3 .auction-timer {
  border-radius: 30px;
  padding-bottom: 20px;
  text-align: center;
  background: #fff;
}
.auction-card3 .auction-timer span.timer-title {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #696969;
  margin-bottom: 5px;
  display: block;
}
.auction-card3 .auction-timer .countdown h4 {
  font-size: 22px;
  font-weight: 700;
  color: #029e7c;
  margin-bottom: 0;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .auction-card3 .auction-timer .countdown h4 {
    font-size: 22px;
  }
}
@media (max-width: 1199px) {
  .auction-card3 .auction-timer .countdown h4 {
    font-size: 20px;
  }
}
.auction-card3 .auction-img {
  position: relative;
  overflow: hidden;
  border-radius: 0px 30px;
  margin-bottom: 25px;
}
.auction-card3 .auction-img img {
  width: 100%;
  transition: all 0.65s ease;
}
.auction-card3 .auction-content h4 {
  font-size: 23px;
  font-weight: 600;
  color: #1f2230;
  line-height: 1.5;
  margin-top: -7px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auction-card3 .auction-content p {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.auction-card3 .auction-content p span {
  font-size: 20px;
  font-weight: bold;
  color: #1f2230;
}
.auction-card3 .auction-content .price {
  font-size: 16px;
  font-weight: 700;
  color: #f9395f;
}
.auction-card3 .auction-card-bttm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}
.auction-card3 .type-of-work-area {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}
.auction-card3 .type-of-work-area .react-tooltip {
  z-index: 1000;
}
.auction-card3 .type-of-work-area .type-of-work-emo {
  border-radius: 50%;
  background: #fff;
  padding: 7px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid transparent;
  z-index: 1;
}
.auction-card3 .type-of-work-area .type-of-work-emo img {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.auction-card3 .type-of-work-area .type-of-work-name {
  background: #029e7c;
  padding: 6px 20px 6px 44px;
  border-radius: 33px;
  margin-left: -27px;
  transform: scaleX(0.1);
  transform-origin: left;
  transition: 0.6s ease;
}
.auction-card3 .type-of-work-area .type-of-work-name span {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.auction-card3 .type-of-work-area:hover .type-of-work-emo {
  border: 1px solid #f9395f;
}
.auction-card3 .type-of-work-area:hover .type-of-work-name {
  transform: scaleX(1);
}
.auction-card3 .share-area {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  align-items: center;
}
.auction-card3 .share-area .share-btn i {
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 50%;
  background-color: #eeeeee;
  text-align: center;
  transition: 0.5s ease;
  z-index: 9;
}
.auction-card3 .share-area .share-btn i:hover {
  background: #1f2230;
  color: #fff;
}
.auction-card3 .share-area .social-icons {
  padding: 5px;
  margin-right: 0px;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.5s ease;
  z-index: 1;
  margin-bottom: 0px;
}
.auction-card3 .share-area:hover .social-icons {
  transform: scaleX(1);
}
.auction-card3 .share-area:hover .social-icons li {
  margin-right: 8px;
}
.auction-card3 .share-area:hover .social-icons li i {
  font-size: 1rem;
  transition: all 0.42s ease;
}
.auction-card3 .share-area:hover .social-icons li i:hover {
  color: #f9395f;
}
.auction-card3.style-2 {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
}
.auction-card3.style-2 .auction-timer .countdown h4 {
  color: #444444;
}

/*================================================
7. Upcoming Auction
=================================================*/
.slider-bottom .coming-next1 .bi {
  padding: 8px 10px;
  line-height: 45px;
  border-radius: 50%;
  background: #fff;
  color: #dddddd;
  font-size: 16px;
  text-align: center;
  border: 1px solid #dddddd;
  transition: 0.5s ease;
}
.slider-bottom .coming-next1:hover i {
  background: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.slider-bottom .coming-prev1 i {
  padding: 8px 10px;
  line-height: 45px;
  border-radius: 50%;
  background: #fff;
  color: #dddddd;
  font-size: 16px;
  text-align: center;
  border: 1px solid #dddddd;
  transition: 0.5s ease;
}
.slider-bottom .coming-prev1:hover i {
  background: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.slider-bottom .coming-next2 .bi {
  padding: 8px 10px;
  line-height: 45px;
  border-radius: 50%;
  background: #fff;
  color: #dddddd;
  font-size: 16px;
  text-align: center;
  border: 1px solid #dddddd;
  transition: 0.5s ease;
}
.slider-bottom .coming-next2:hover i {
  background: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.slider-bottom .coming-prev2 i {
  padding: 8px 10px;
  line-height: 45px;
  border-radius: 50%;
  background: #fff;
  color: #dddddd;
  font-size: 16px;
  text-align: center;
  border: 1px solid #dddddd;
  transition: 0.5s ease;
}
.slider-bottom .coming-prev2:hover i {
  background: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.slider-bottom .coming-next3 .bi {
  padding: 8px 10px;
  line-height: 45px;
  border-radius: 50%;
  background: #fff;
  color: #dddddd;
  font-size: 16px;
  text-align: center;
  border: 1px solid #dddddd;
  transition: 0.5s ease;
}
.slider-bottom .coming-next3:hover i {
  background: #029e7c;
  color: #fff;
  border: 1px solid #029e7c;
}
.slider-bottom .coming-prev3 i {
  padding: 8px 10px;
  line-height: 45px;
  border-radius: 50%;
  background: #fff;
  color: #dddddd;
  font-size: 16px;
  text-align: center;
  border: 1px solid #dddddd;
  transition: 0.5s ease;
}
.slider-bottom .coming-prev3:hover i {
  background: #029e7c;
  color: #fff;
  border: 1px solid #029e7c;
}
.slider-bottom .coming-arrow {
  min-width: 105px;
}
.slider-bottom .coming-arrow svg {
  fill: #eeeeee;
}
.slider-bottom .swiper-pagination {
  position: unset;
  width: 60%;
}
.slider-bottom .swiper-pagination::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  height: 2px;
  max-width: 330px;
  width: 100%;
  background: #eeeeee;
}
@media (max-width: 1399px) {
  .slider-bottom .swiper-pagination::before {
    display: none;
    visibility: hidden;
  }
}
.slider-bottom .swiper-pagination::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  height: 2px;
  max-width: 330px;
  width: 100%;
  background: #eeeeee;
}
@media (max-width: 1399px) {
  .slider-bottom .swiper-pagination::after {
    display: none;
    visibility: hidden;
  }
}
.slider-bottom .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 8px;
  border-radius: 50px;
  background: #696969;
  transition: all 0.42s ease;
}
.slider-bottom .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #f9395f;
  width: 15px;
}
.slider-bottom .swiper-pagination.style-3 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #029e7c;
}

.upcoming-seciton {
  position: relative;
}
@media (max-width: 576px) {
  .upcoming-seciton.ending-soon .auction-card2 {
    max-width: 95%;
  }
}
@media (max-width: 576px) {
  .upcoming-seciton.ending-soon .slider-bottom {
    margin-bottom: -40px;
    margin-top: 20px;
  }
}
@media (max-width: 576px) {
  .upcoming-seciton.ending-soon .slider-arrows {
    padding-right: 5px;
  }
}
.upcoming-seciton .section-bg {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 767px) {
  .upcoming-seciton .section-bg {
    display: none;
    visibility: hidden;
  }
}
.upcoming-seciton .section-bg2 {
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 767px) {
  .upcoming-seciton .section-bg2 {
    display: none;
    visibility: hidden;
  }
}

.c-feature-card1 {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.c-feature-card1:hover .auction-timer2 .countdown-single {
  border: 1.5px solid #f9395f;
}
.c-feature-card1:hover .auction-img > img {
  transform: scale(1.12);
}
.c-feature-card1 .auction-img {
  position: relative;
  overflow: hidden;
  height: 350px;
}
.c-feature-card1 .auction-img img {
  width: 100%;
  transition: all 0.55s ease-in-out;
}
.c-feature-card1 .c-feature-content {
  padding: 30px 25px;
}
.c-feature-card1 .c-feature-content h4 {
  font-size: 23px;
  font-weight: 600;
  color: #1f2230;
  line-height: 1.4;
}
.c-feature-card1 .c-feature-content p {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.c-feature-card1 .c-feature-content p span {
  font-size: 20px;
  font-weight: bold;
  color: #1f2230;
}
.c-feature-card1 .c-feature-content .price {
  font-size: 16px;
  font-weight: 700;
  color: #696969;
}
.c-feature-card1 .c-feature-content .auction-card-bttm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
.c-feature-card1 .c-feature-content .auction-card-bttm .share-area {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  align-items: center;
}
.c-feature-card1 .c-feature-content .auction-card-bttm .share-area .share-btn i {
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 50%;
  background-color: #eeeeee;
  text-align: center;
  transition: 0.5s ease;
  z-index: 9;
}
.c-feature-card1 .c-feature-content .auction-card-bttm .share-area .share-btn i:hover {
  background: #1f2230;
  color: #fff;
}
.c-feature-card1 .c-feature-content .auction-card-bttm .share-area .social-icons {
  padding: 5px;
  margin-right: 0px;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.5s ease;
  z-index: 1;
  margin: 0;
  padding: 0;
}
.c-feature-card1 .c-feature-content .auction-card-bttm .share-area:hover .social-icons {
  transform: scaleX(1);
}
.c-feature-card1 .c-feature-content .auction-card-bttm .share-area:hover .social-icons li {
  margin-right: 8px;
}
.c-feature-card1 .c-feature-content .auction-card-bttm .share-area:hover .social-icons li i {
  font-size: 1rem;
  transition: all 0.42s ease;
}
.c-feature-card1 .c-feature-content .auction-card-bttm .share-area:hover .social-icons li i:hover {
  color: #f9395f;
}
.c-feature-card1 .c-feature-content .c-feature-category {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  font-family: "Inter", sans-serif;
  margin-bottom: 15px;
  margin-top: -5px;
}
.c-feature-card1 .auction-timer2 {
  padding: 15px;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #1f2230;
  min-width: 220px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.c-feature-card1 .auction-timer2 .countdown-single {
  border: 1.5px solid #696969;
  border-radius: 10px 0;
  padding: 10px 5px;
  transition: all 0.5s ease;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .c-feature-card1 .auction-timer2 .countdown-single {
    padding: 8px 3px;
  }
}
@media (max-width: 767px) {
  .c-feature-card1 .auction-timer2 .countdown-single {
    padding: 6px 0px;
  }
}
.c-feature-card1 .auction-timer2 .countdown-single h5 {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 700;
  color: #fff !important;
  line-height: 1;
}
.c-feature-card1 .auction-timer2 .countdown-single span {
  color: #fff;
  border-radius: 3px;
  margin: 10px;
  padding: 5px;
  min-width: 50px;
  font-size: 12px;
  font-weight: 500;
}
.c-feature-card1 .type-of-work-area2 {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}
.c-feature-card1 .type-of-work-area2 .type-of-work-emo {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid transparent;
  z-index: 1;
}
.c-feature-card1 .type-of-work-area2 .type-of-work-emo img {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.c-feature-card1 .type-of-work-area2 .type-of-work-name {
  background: #fff;
  padding: 6px 44px 6px 20px;
  border-radius: 33px;
  margin-right: -28px;
  transform: scaleX(0.1);
  transform-origin: right;
  transition: 0.6s ease;
}
.c-feature-card1 .type-of-work-area2 .type-of-work-name span {
  font-size: 14px;
  font-weight: 600;
  color: #1f2230;
}
.c-feature-card1 .type-of-work-area2:hover .type-of-work-emo {
  border: 1px solid transparent;
}
.c-feature-card1 .type-of-work-area2:hover .type-of-work-name {
  transform: scaleX(1);
}

.c-feature-card2 {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.c-feature-card2:hover .auction-timer2 .countdown-single {
  border: 1.5px solid #f9395f;
}
.c-feature-card2:hover .auction-img > img {
  transform: scale(1.12);
}
.c-feature-card2 .auction-img {
  position: relative;
  overflow: hidden;
  height: 350px;
}
.c-feature-card2 .auction-img img {
  width: 100%;
  transition: all 0.55s ease-in-out;
}
.c-feature-card2 .c-feature-content {
  padding: 30px 25px;
}
.c-feature-card2 .c-feature-content h4 {
  font-size: 23px;
  font-weight: 700;
  color: #1f2230;
}
.c-feature-card2 .c-feature-content p {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.c-feature-card2 .c-feature-content p span {
  font-size: 20px;
  font-weight: bold;
  color: #f9395f;
}
.c-feature-card2 .c-feature-content .price {
  font-size: 16px;
  font-weight: 700;
  color: #696969;
}
.c-feature-card2 .c-feature-content .auction-card-bttm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
.c-feature-card2 .c-feature-content .auction-card-bttm .share-area {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  align-items: center;
}
.c-feature-card2 .c-feature-content .auction-card-bttm .share-area .share-btn i {
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 50%;
  background-color: #eeeeee;
  text-align: center;
  transition: 0.5s ease;
  z-index: 9;
}
.c-feature-card2 .c-feature-content .auction-card-bttm .share-area .share-btn i:hover {
  background: #1f2230;
  color: #fff;
}
.c-feature-card2 .c-feature-content .auction-card-bttm .share-area .social-icons {
  padding: 5px;
  margin-right: 0px;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.5s ease;
  z-index: 1;
  margin: 0;
  padding: 0;
}
.c-feature-card2 .c-feature-content .auction-card-bttm .share-area .social-icons li a .bx {
  transition: all 0.5s ease-out 0s;
}
.c-feature-card2 .c-feature-content .auction-card-bttm .share-area .social-icons li a:hover .bx {
  color: #f9395f;
}
.c-feature-card2 .c-feature-content .auction-card-bttm .share-area:hover .social-icons {
  transform: scaleX(1);
}
.c-feature-card2 .c-feature-content .auction-card-bttm .share-area:hover .social-icons li {
  margin-right: 8px;
}
.c-feature-card2 .c-feature-content .auction-card-bttm .share-area:hover .social-icons li i {
  font-size: 1rem;
}
.c-feature-card2 .c-feature-content .c-feature-category {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  font-family: "Inter", sans-serif;
  margin-bottom: 15px;
  margin-top: -5px;
}
.c-feature-card2 .auction-timer2 {
  padding: 15px;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #1f2230;
  min-width: 220px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.c-feature-card2 .auction-timer2 .countdown-single {
  border: 1.5px solid #696969;
  border-radius: 10px;
  padding: 10px 5px;
  transition: all 0.5s ease;
}
@media (max-width: 991px) {
  .c-feature-card2 .auction-timer2 .countdown-single {
    padding: 8px 0px;
  }
}
.c-feature-card2 .auction-timer2 .countdown-single h5 {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 700;
  color: #fff !important;
  line-height: 1;
}
.c-feature-card2 .auction-timer2 .countdown-single span {
  color: #fff;
  border-radius: 3px;
  margin: 10px;
  padding: 5px;
  min-width: 50px;
  font-size: 12px;
  font-weight: 500;
}
.c-feature-card2 .type-of-work-area2 {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}
.c-feature-card2 .type-of-work-area2 .type-of-work-emo {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid transparent;
  z-index: 1;
}
.c-feature-card2 .type-of-work-area2 .type-of-work-emo img {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.c-feature-card2 .type-of-work-area2 .type-of-work-name {
  background: #fff;
  padding: 6px 44px 6px 20px;
  border-radius: 33px;
  margin-right: -28px;
  transform: scaleX(0.1);
  transform-origin: right;
  transition: 0.6s ease;
}
.c-feature-card2 .type-of-work-area2 .type-of-work-name span {
  font-size: 14px;
  font-weight: 600;
  color: #1f2230;
}
.c-feature-card2 .type-of-work-area2:hover .type-of-work-emo {
  border: 1px solid transparent;
}
.c-feature-card2 .type-of-work-area2:hover .type-of-work-name {
  transform: scaleX(1);
}
.c-feature-card2 .type-of-work-area3 {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}
.c-feature-card2 .type-of-work-area3 .type-of-work-emo {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid transparent;
  z-index: 1;
}
.c-feature-card2 .type-of-work-area3 .type-of-work-emo img {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.c-feature-card2 .type-of-work-area3 .type-of-work-name {
  background: #fff;
  padding: 1px 20px 6px 44px;
  border-radius: 33px;
  margin-left: -28px;
  transform: scaleX(0.1);
  transform-origin: left;
  transition: 0.6s ease;
}
.c-feature-card2 .type-of-work-area3 .type-of-work-name span {
  font-size: 14px;
  font-weight: 600;
  color: #1f2230;
}
.c-feature-card2 .type-of-work-area3:hover .type-of-work-emo {
  border: 1px solid transparent;
}
.c-feature-card2 .type-of-work-area3:hover .type-of-work-name {
  transform: scaleX(1);
}

/*================================================
7. Testimonial
=================================================*/
.testi-next2,
.testi-next1 {
  z-index: 9999;
}
@media (max-width: 767px) {
  .testi-next2,
  .testi-next1 {
    right: -30px;
  }
}
@media (max-width: 1199px) {
  .testi-next2,
  .testi-next1 {
    right: -40px;
  }
}
.testi-next2 .bi,
.testi-next1 .bi {
  padding: 8px 10px;
  line-height: 45px;
  border-radius: 50%;
  background: #fff;
  color: #666666;
  font-size: 16px;
  text-align: center;
  border: 1px solid #666666;
  transition: 0.5s ease;
}
.testi-next2:hover i,
.testi-next1:hover i {
  background: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.testi-next2.style-2 i,
.testi-next1.style-2 i {
  border: 1px solid #eeeeee;
}
.testi-next2.style-2:hover i,
.testi-next1.style-2:hover i {
  background: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.testi-next2.style-3 i,
.testi-next1.style-3 i {
  border: 1px solid #eeeeee;
}
.testi-next2.style-3:hover i,
.testi-next1.style-3:hover i {
  background: #029e7c;
  color: #fff;
  border: 1px solid #029e7c;
}

.testimonial2-arrow {
  justify-content: flex-end;
  margin-bottom: 20px;
}

.testi-prev2,
.testi-prev1 {
  z-index: 9999;
}
@media (max-width: 767px) {
  .testi-prev2,
  .testi-prev1 {
    left: -30px;
  }
}
@media (max-width: 1199px) {
  .testi-prev2,
  .testi-prev1 {
    left: -40px;
  }
}
.testi-prev2 i,
.testi-prev1 i {
  padding: 8px 10px;
  line-height: 45px;
  border-radius: 50%;
  background: #fff;
  color: #666666;
  font-size: 16px;
  text-align: center;
  border: 1px solid #666666;
  transition: 0.5s ease;
}
.testi-prev2:hover i,
.testi-prev1:hover i {
  background: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.testi-prev2.style-2 i,
.testi-prev1.style-2 i {
  border: 1px solid #eeeeee;
}
.testi-prev2.style-2:hover i,
.testi-prev1.style-2:hover i {
  background: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.testi-prev2.style-3 i,
.testi-prev1.style-3 i {
  border: 1px solid #eeeeee;
}
.testi-prev2.style-3:hover i,
.testi-prev1.style-3:hover i {
  background: #029e7c;
  color: #fff;
  border: 1px solid #029e7c;
}

.testimonial-section {
  background-image: url("../../public/images//bg/client-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .testimonial-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.testimonial-section h2 {
  font-size: 40px;
}
@media (max-width: 576px) {
  .testimonial-section h2 {
    font-size: 30px;
  }
}
.testimonial-section .swiper {
  margin: -25px -15px;
  padding: 25px 15px;
}
.testimonial-section .client-right-vector {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 767px) {
  .testimonial-section .client-right-vector {
    display: none;
    visibility: hidden;
  }
}
.testimonial-section .client-left-vector {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 767px) {
  .testimonial-section .client-left-vector {
    display: none;
    visibility: hidden;
  }
}
.testimonial-section .client-circle1 {
  position: absolute;
  right: 14%;
  bottom: 30px;
  animation: fluid 5s linear infinite alternate;
}
.testimonial-section .client-circle2 {
  position: absolute;
  left: 8%;
  bottom: 30px;
  animation: fluid 5s linear infinite alternate;
  animation-delay: 2s;
}
.testimonial-section .client-circle3 {
  position: absolute;
  left: 30%;
  bottom: 30px;
  animation: fluid 5s linear infinite alternate;
  animation-delay: 2s;
}
.testimonial-section .client-circle4 {
  position: absolute;
  left: 8%;
  bottom: 30px;
  animation: fluid 5s linear infinite alternate;
  animation-delay: 2s;
}
.testimonial-section .client-circle5 {
  position: absolute;
  right: 14%;
  bottom: 30px;
  animation: fluid 5s linear infinite alternate;
}
.testimonial-section .swiper {
  margin: -15px;
  padding: 15px;
}

@keyframes fluid {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(-50%);
  }
  75% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.testimonial-single {
  background: #fff;
  box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  padding: 25px;
  transition: 0.42s ease;
  transition-delay: 0.3s;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.testimonial-single p {
  font-style: italic;
  color: #1f2230;
  font-size: 16px;
}
.testimonial-single .quote-icon {
  position: absolute;
  bottom: 30px;
  right: 20px;
}
.testimonial-single .testi-img {
  width: 60px;
  height: 60px;
  margin-top: -50px;
  margin-bottom: 20px;
}
.testimonial-single .testi-img img {
  max-width: 100%;
  border-radius: 50%;
}
.testimonial-single .testi-content .testi-designation {
  margin-top: 21px;
}
.testimonial-single .testi-content .testi-designation h5 {
  font-size: 18px;
  font-weight: 600;
  color: #1f2230;
  line-height: 1;
  margin-bottom: 5px;
}
.testimonial-single .testi-content .testi-designation p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #696969;
  font-style: normal;
}
.testimonial-single.style2 {
  position: relative;
}
.testimonial-single.style2 .testi-designation {
  margin-bottom: 20px;
}
.testimonial-single.style2 .testi-content .para {
  margin-bottom: 0;
  max-height: 84px;
  overflow-y: auto;
}
.testimonial-single.style2 .quote-icon {
  position: absolute;
  top: 30px;
  right: 20px;
}
.testimonial-single.style2 .testi-img {
  width: 60px;
  height: 60px;
  margin-top: unset;
  margin-bottom: 15px;
}
.testimonial-single.style2 .testi-img img {
  max-width: 100%;
  border-radius: 50%;
}

.testimonial-slider {
  padding-top: 40px !important;
}

/*================================================
7. Blog
=================================================*/
.recent-news-section {
  position: relative;
  overflow: hidden;
}
.recent-news-section .section-bg {
  position: absolute;
  right: -100px;
  bottom: 10%;
  z-index: -1;
}
@media (max-width: 767px) {
  .recent-news-section .section-bg {
    display: none;
    visibility: hidden;
  }
}
.recent-news-section .section-bg2 {
  position: absolute;
  right: 0px;
  top: 0px;
}
@media (max-width: 767px) {
  .recent-news-section .section-bg2 {
    display: none;
    visibility: hidden;
  }
}
.recent-news-section .dot-circle {
  position: absolute;
  top: 240px;
  right: 250px;
  animation: round 8s linear infinite;
}
@media (max-width: 767px) {
  .recent-news-section .dot-circle {
    display: none;
    visibility: hidden;
  }
}
@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.single-blog-style1 {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}
.single-blog-style1:hover .blog-img img {
  transform: scale(1.12);
}
.single-blog-style1 .blog-img {
  position: relative;
  overflow: hidden;
}
.single-blog-style1 .blog-img img {
  max-width: 100%;
  transition: all 0.7s ease;
  border-radius: 5px 5px 0px 0px;
}
.single-blog-style1 .blog-img .blog-date {
  position: absolute;
  left: 0;
  top: 30px;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Saira", sans-serif;
  color: #fff;
  background: #f9395f;
  border-radius: 0px 17px 17px 0px;
  z-index: 1;
}
.single-blog-style1 .blog-img .blog-date .bi {
  margin-right: 10px;
  font-size: 16px;
}
.single-blog-style1 .blog-content {
  padding: 30px 25px;
  background: #fff;
}
.single-blog-style1 .blog-content h5 a {
  font-size: 25px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #1f2230;
  line-height: 1.4;
  transition: all 0.42s ease-in-out;
}
.single-blog-style1 .blog-content h5:hover a {
  color: #f9395f;
}
.single-blog-style1 .blog-meta {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 25px;
}
.single-blog-style1 .blog-meta .author {
  margin-right: 35px;
}
.single-blog-style1 .blog-meta .author img {
  width: 100%;
  max-width: 26px;
  border-radius: 50%;
  margin-right: 8px;
}
.single-blog-style1 .blog-meta .author .type-of-work-name {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
  white-space: nowrap;
}
@media (max-width: 991px) {
  .single-blog-style1 .blog-meta .author .type-of-work-name {
    font-size: 12px;
  }
}
.single-blog-style1 .blog-meta .comment img {
  margin-right: 8px;
}
.single-blog-style1 .blog-meta .comment .comment {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
  white-space: nowrap;
}

.single-blog-style2 {
  position: relative;
}
.single-blog-style2:hover .blog-content {
  transform: translateY(-20px);
}
.single-blog-style2 .blog-img img {
  max-width: 100%;
  transition: all 0.7s ease;
  border-radius: 5px 5px 0px 0px;
}
.single-blog-style2 .blog-img .blog-date {
  position: absolute;
  left: 0;
  top: 30px;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Saira", sans-serif;
  color: #fff;
  background: #f9395f;
  border-radius: 0px 17px 17px 0px;
  z-index: 1;
}
.single-blog-style2 .blog-img .blog-date .bi {
  margin-right: 10px;
  font-size: 16px;
}
.single-blog-style2 .blog-content {
  position: relative;
  width: 94%;
  padding: 25px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  z-index: 9;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  background: #fff;
  transition: all 0.55s ease-in-out;
  margin-top: -50px;
}
@media (max-width: 767px) {
  .single-blog-style2 .blog-content {
    padding: 20px;
  }
}
.single-blog-style2 .blog-content h5 a {
  font-size: 22px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #1f2230;
  line-height: 1.4;
  transition: all 0.42s ease-in-out;
}
.single-blog-style2 .blog-content h5:hover a {
  color: #f9395f;
}
.single-blog-style2 .blog-content p {
  margin-bottom: 0px;
}
.single-blog-style2 .blog-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .single-blog-style2 .blog-meta {
    justify-content: space-between;
  }
}
.single-blog-style2 .blog-meta .author {
  margin-right: 45px;
}
.single-blog-style2 .blog-meta .author img {
  width: 100%;
  max-width: 26px;
  border-radius: 50%;
  margin-right: 8px;
}
.single-blog-style2 .blog-meta .author .type-of-work-name {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
  white-space: nowrap;
}
.single-blog-style2 .blog-meta .comment img {
  margin-right: 8px;
}
.single-blog-style2 .blog-meta .comment .comment {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
  white-space: nowrap;
}

.single-blog-style3 {
  position: relative;
}
.single-blog-style3:hover .blog-content {
  transform: translateY(-20px);
}
.single-blog-style3 .blog-img img {
  max-width: 100%;
  transition: all 0.7s ease;
  border-radius: 5px 5px 0px 0px;
}
.single-blog-style3 .blog-img .blog-date {
  position: absolute;
  left: 0;
  top: 30px;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Saira", sans-serif;
  color: #fff;
  background: #029e7c;
  border-radius: 0px 17px 17px 0px;
  z-index: 1;
}
.single-blog-style3 .blog-img .blog-date .bi {
  margin-right: 10px;
  font-size: 16px;
}
.single-blog-style3 .blog-content {
  position: relative;
  width: 94%;
  padding: 25px 20px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  z-index: 9;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  background: #fff;
  transition: all 0.55s ease-in-out;
  margin-top: -50px;
}
@media (max-width: 767px) {
  .single-blog-style3 .blog-content {
    padding: 20px;
  }
}
.single-blog-style3 .blog-content h5 a {
  font-size: 22px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #1f2230;
  line-height: 1.4;
  transition: all 0.42s ease-in-out;
}
.single-blog-style3 .blog-content h5:hover a {
  color: #029e7c;
}
.single-blog-style3 .blog-content p {
  margin-bottom: 0px;
}
.single-blog-style3 .blog-meta {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .single-blog-style3 .blog-meta {
    justify-content: space-between;
  }
}
.single-blog-style3 .blog-meta .author {
  margin-right: 35px;
}
.single-blog-style3 .blog-meta .author img {
  width: 100%;
  max-width: 26px;
  border-radius: 50%;
  margin-right: 5px;
}
.single-blog-style3 .blog-meta .author .type-of-work-name {
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
  white-space: nowrap;
}
.single-blog-style3 .blog-meta .comment img {
  margin-right: 8px;
}
.single-blog-style3 .blog-meta .comment .comment {
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
  white-space: nowrap;
}

/*================================================
7. about-us counter
=================================================*/
.counter-single {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 30px 25px;
}
@media (max-width: 576px) {
  .counter-single {
    padding: 20px 15px;
  }
}
.counter-single .counter-icon {
  margin-right: 15px;
}
.counter-single .coundown h3 {
  font-size: 30px;
  font-weight: 600;
  color: #1f2230;
  position: relative;
  padding-right: 15px;
  margin: 0 auto !important;
}
.counter-single .coundown h3::after {
  content: "+";
  position: absolute;
  right: 0;
  top: -2px;
}
.counter-single .coundown p {
  margin-bottom: 0px;
  font-weight: 500;
  color: #696969;
  font-size: 16px;
}

/*================================================
    Sponsor-section
=================================================*/
.sponsor-section {
  overflow: hidden;
  padding: 80px 0px 100px 0px;
}
.sponsor-section.style-1 {
  padding: 120px 0px;
}
@media (max-width: 767px) {
  .sponsor-section.style-1 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.sponsor-section.style-3 {
  background: rgba(2, 158, 124, 0.1);
}
.sponsor-section.style-2 {
  background: rgba(249, 57, 95, 0.1);
}
.sponsor-section.style-2 .slick-dots .slick-active {
  background-color: #f9395f;
}
.sponsor-section .slick-list {
  margin: -15px;
}
.sponsor-section .slick-slide > div {
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sponsor-section .slide-item img {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
  box-shadow: 0px 0px 5px rgba(139, 139, 139, 0.15);
}
.sponsor-section .slide-item:hover img {
  transform: translateY(-5px);
}
.sponsor-section .slick-dots {
  position: absolute;
  bottom: -60px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.sponsor-section .slick-dots li {
  background: #696969;
  width: 10px;
  height: 8px;
  border-radius: 50px;
}
.sponsor-section .slick-dots li button:before {
  opacity: 0;
}
.sponsor-section .slick-dots .slick-active {
  background-color: #029e7c;
}
.sponsor-section .slick-dotted.slick-slider {
  margin-bottom: 45px;
}

/*================================================
7. Footer
=================================================*/
footer {
  background-image: url("../../public/images/bg/client-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  position: relative;
}
footer .footer-top {
  padding: 90px 0px;
}
@media (max-width: 991px) {
  footer .footer-top {
    padding: 80px 0px;
  }
}
@media (max-width: 767px) {
  footer .footer-top {
    padding: 60px 0px;
  }
}
footer .footer-item {
  min-width: 200px;
}
footer .footer-item .social-links-footer {
  width: 80px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -9px;
}
footer .footer-item .social-links-footer .fb-link {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
footer .footer-item .social-links-footer .fb-link i {
  font-size: 30px;
  color: #f9395f;
}
footer .footer-item .social-links-footer .instagram-link {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
footer .footer-item .social-links-footer .instagram-link img {
  display: block;
  height: 40px;
  width: 40px;
  margin-bottom: 0;
  max-width: unset;
  margin-left: 0;
}
footer .footer-item a img {
  margin-bottom: 25px;
  max-width: 300px;
  margin-left: -19px;
}
footer .footer-item p {
  font-size: 16px;
  font-weight: 400;
  color: #d2d2d2;
  margin-bottom: 25px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  footer .footer-item p {
    font-size: 14px;
    max-width: 335px;
  }
}
footer .footer-item .input-with-btn {
  max-width: 350px;
  width: 100%;
  border: 1px solid #696969;
  padding: 5px;
  border-radius: 5px;
}
footer .footer-item .input-with-btn input {
  width: 100%;
  border-radius: 5px;
  background: transparent;
  border: none;
  outline: none;
  padding: 7px 20px;
  transition: all 0.4s ease;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
footer .footer-item .input-with-btn input::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
footer .footer-item .input-with-btn input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
footer .footer-item .input-with-btn button {
  outline: none;
  border: none;
  background: #f9395f;
  border-radius: 5px;
  min-width: 46px;
  min-height: 44px;
}
footer .footer-item .input-with-btn button img {
  transition: 0.65s ease;
}
footer .footer-item .input-with-btn button:hover img {
  transform: rotate(360deg);
}
footer .footer-item h5 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
}
footer .footer-item .footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
footer .footer-item .footer-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 0px;
  transition: all 0.4s ease-in;
}
footer .footer-item .footer-list li::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  background: #f9395f;
  opacity: 0;
  transition: all 0.4s ease;
}
footer .footer-item .footer-list li:last-child {
  margin-bottom: 0;
}
footer .footer-item .footer-list li:hover {
  padding-left: 12px;
}
footer .footer-item .footer-list li:hover a {
  color: #f9395f;
}
footer .footer-item .footer-list li:hover::before {
  opacity: 1;
}
footer .footer-item .footer-list li a {
  color: #d2d2d2;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  transition: all 0.4s ease;
}
@media (max-width: 576px) {
  footer .footer-item .footer-list li a {
    font-size: 14px;
  }
}
footer .footer-bottom {
  background: #000000;
  padding: 15px 0px;
  border-top: 1px solid #2f2f2f;
}
footer .footer-bottom p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #fff;
  margin-bottom: 0;
  margin-right: 30px;
}
@media (max-width: 576px) {
  footer .footer-bottom p {
    margin-right: 0px;
  }
}
footer .footer-bottom p .egns-lab {
  font-weight: 600;
  color: #f9395f;
}
footer .footer-bottom .footer-logo-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}
footer .footer-bottom .footer-logo-list li {
  margin-right: 20px;
}
footer .footer-bottom .footer-logo-list li img {
  transition: all 0.6s ease;
}
footer .footer-bottom .footer-logo-list li:last-child {
  margin-right: 0px;
}
footer .footer-bottom .footer-logo-list li:hover img {
  transform: rotate(360deg);
}

ul.recent-feed-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.recent-feed-list .single-feed {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 25px;
}
ul.recent-feed-list .single-feed:last-child {
  margin-bottom: 0px;
}
ul.recent-feed-list .single-feed .feed-img {
  margin-right: 15px;
}
ul.recent-feed-list .single-feed .feed-img img {
  min-width: 64px;
  height: 64px;
  border-radius: 5px;
  margin-bottom: 0;
}
ul.recent-feed-list .single-feed .feed-content span {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  font-family: "Inter", sans-serif;
  display: block;
  margin-bottom: 5px;
}
ul.recent-feed-list .single-feed .feed-content h6 {
  margin-bottom: 0;
}
ul.recent-feed-list .single-feed .feed-content h6 a {
  font-size: 16px;
  font-weight: 600;
  color: #d2d2d2;
  transition: all 0.42s ease-in;
}
ul.recent-feed-list .single-feed .feed-content h6:hover a {
  color: #f9395f;
}

ul.recent-post {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.recent-post .single-post {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 25px;
}
ul.recent-post .single-post:last-child {
  margin-bottom: 0px;
}
ul.recent-post .single-post .post-img {
  margin-right: 15px;
}
ul.recent-post .single-post .post-img img {
  min-width: 64px;
  height: 64px;
  border-radius: 5px;
  margin-bottom: 0;
}
ul.recent-post .single-post .post-content span {
  font-size: 14px;
  font-weight: 400;
  color: #696969;
  font-family: "Inter", sans-serif;
  display: block;
  margin-bottom: 5px;
}
ul.recent-post .single-post .post-content h6 {
  margin-bottom: 0;
}
ul.recent-post .single-post .post-content h6 a {
  font-size: 16px;
  font-weight: 600;
  color: #1f2230;
  transition: all 0.42s ease-in;
}
ul.recent-post .single-post .post-content h6:hover a {
  color: #f9395f;
}

ul.category-list {
  margin: 0;
  padding: 0;
}
ul.category-list li {
  margin-bottom: 15px;
}
ul.category-list li:last-child {
  margin-bottom: 0px;
}
ul.category-list li a {
  font-size: 16px;
  font-weight: 500;
  font-family: "Saira", sans-serif;
  color: #696969;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul.category-list li a span:first-child {
  padding-left: 20px;
  position: relative;
}
ul.category-list li a span:first-child::before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f9395f;
  transition: all 0.5s ease-out 0s;
}
ul.category-list li a span:last-child {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #f9395f;
  border: 1px solid #f9395f;
  border-radius: 50%;
  transition: all 0.5s ease-out 0s;
}
ul.category-list li a:hover span:first-child::before {
  background: #696969;
}
ul.category-list li a:hover span:last-child {
  color: #f9395f;
  background: #fff;
  border: 1px solid #f9395f;
}

.sidebar-social-list {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0;
  padding: 0;
}
.sidebar-social-list li a {
  width: 35px !important;
  height: 35px !important;
  line-height: 32px;
  text-align: center;
  border: 1px solid #eeeeee;
  color: #696969;
  border-radius: 5px;
  transition: all 0.5s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-social-list li a:hover {
  border: 1px solid #f9395f;
  color: #f9395f;
}

footer.style-2 {
  background-image: url("../../public/images//bg/footer2-bg.png");
  position: relative;
}
footer.style-2 .footer-top {
  padding: 90px 0px;
}
footer.style-2 .footer-item {
  min-width: 200px;
}
footer.style-2 .footer-item a img {
  margin-bottom: 25px;
}
footer.style-2 .footer-item p {
  font-size: 16px;
  font-weight: 400;
  color: #d2d2d2;
  margin-bottom: 25px;
  font-family: "Inter", sans-serif;
}
footer.style-2 .footer-item .input-with-btn {
  max-width: 350px;
  width: 100%;
  border: 1px solid #696969;
  padding: 5px;
  border-radius: 30px;
}
footer.style-2 .footer-item .input-with-btn input {
  width: 100%;
  border-radius: 30px;
  background: transparent;
  border: none;
  outline: none;
  padding: 7px 20px;
  transition: all 0.4s ease;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
footer.style-2 .footer-item .input-with-btn input::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
footer.style-2 .footer-item .input-with-btn input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
footer.style-2 .footer-item .input-with-btn button {
  outline: none;
  border: none;
  background: #f9395f;
  border-radius: 30px;
  min-width: 120px;
  min-height: 46px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #fff;
  transition: all 0.42s ease;
}
footer.style-2 .footer-item .input-with-btn button:hover {
  background: rgba(249, 57, 95, 0.9);
}
footer.style-2 .footer-item .input-with-btn button img {
  transition: 0.65s ease;
}
footer.style-2 .footer-item .input-with-btn button:hover img {
  transform: rotate(360deg);
}
footer.style-2 .footer-item h5 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
}
footer.style-2 .footer-item .footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
footer.style-2 .footer-item .footer-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 0px;
  transition: all 0.4s ease-in;
}
footer.style-2 .footer-item .footer-list li::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  background: #f9395f;
  opacity: 0;
  transition: all 0.4s ease;
}
footer.style-2 .footer-item .footer-list li:last-child {
  margin-bottom: 0;
}
footer.style-2 .footer-item .footer-list li:hover {
  padding-left: 12px;
}
footer.style-2 .footer-item .footer-list li:hover a {
  color: #f9395f;
}
footer.style-2 .footer-item .footer-list li:hover::before {
  opacity: 1;
}
footer.style-2 .footer-item .footer-list li a {
  color: #d2d2d2;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  transition: all 0.4s ease;
}
footer.style-2 .footer-item .address-list {
  margin: 0;
  padding: 0;
}
footer.style-2 .footer-item .address-list li {
  margin-bottom: 15px;
}
footer.style-2 .footer-item .address-list li:last-child {
  margin-bottom: 0px;
}
footer.style-2 .footer-item .address-list li a {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #d2d2d2;
  transition: all 0.42s ease;
}
footer.style-2 .footer-item .address-list li:hover a {
  color: #f9395f;
}
footer.style-2 .footer-item .footer-social {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: start;
}
footer.style-2 .footer-item .footer-social i {
  color: #fff;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  transition: all 0.42s ease;
}
footer.style-2 .footer-item .footer-social i:hover {
  background: #f9395f;
  border: 1px solid #f9395f;
}
footer.style-2 .footer-bottom {
  background: #000000;
  padding: 15px 0px;
}
footer.style-2 .footer-bottom p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #fff;
  margin-bottom: 0;
  margin-right: 30px;
}
footer.style-2 .footer-bottom p .egns-lab {
  font-weight: 600;
  color: #f9395f;
}
footer.style-2 .footer-bottom .footer-logo-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}
footer.style-2 .footer-bottom .footer-logo-list li {
  margin-right: 20px;
}
footer.style-2 .footer-bottom .footer-logo-list li img {
  transition: all 0.6s ease;
}
footer.style-2 .footer-bottom .footer-logo-list li:last-child {
  margin-right: 0px;
}
footer.style-2 .footer-bottom .footer-logo-list li:hover img {
  transform: rotate(360deg);
}

footer.style-3 {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url("../../public/images//bg/footer3-bg.png");
  position: relative;
}
footer.style-3 .footer-top {
  padding: 90px 0px;
}
footer.style-3 .footer-item {
  min-width: 200px;
}
footer.style-3 .footer-item a img {
  margin-bottom: 25px;
}
footer.style-3 .footer-item p {
  font-size: 16px;
  font-weight: 400;
  color: #d2d2d2;
  margin-bottom: 25px;
  font-family: "Inter", sans-serif;
}
footer.style-3 .footer-item .input-with-btn {
  max-width: 350px;
  width: 100%;
  border: 1px solid #696969;
  padding: 5px;
  border-radius: 30px;
}
footer.style-3 .footer-item .input-with-btn input {
  width: 100%;
  border-radius: 30px;
  background: transparent;
  border: none;
  outline: none;
  padding: 7px 20px;
  transition: all 0.4s ease;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
footer.style-3 .footer-item .input-with-btn input::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
footer.style-3 .footer-item .input-with-btn input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
footer.style-3 .footer-item .input-with-btn button {
  outline: none;
  border: none;
  background: #029e7c;
  border-radius: 30px;
  min-width: 120px;
  min-height: 46px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #fff;
  transition: all 0.42s ease;
}
footer.style-3 .footer-item .input-with-btn button:hover {
  background: rgba(2, 158, 124, 0.9);
}
footer.style-3 .footer-item .input-with-btn button img {
  transition: 0.65s ease;
}
footer.style-3 .footer-item .input-with-btn button:hover img {
  transform: rotate(360deg);
}
footer.style-3 .footer-item h5 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
}
footer.style-3 .footer-item .footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
footer.style-3 .footer-item .footer-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 0px;
  transition: all 0.4s ease-in;
}
footer.style-3 .footer-item .footer-list li::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  background: #029e7c;
  opacity: 0;
  transition: all 0.4s ease;
}
footer.style-3 .footer-item .footer-list li:last-child {
  margin-bottom: 0;
}
footer.style-3 .footer-item .footer-list li:hover {
  padding-left: 12px;
}
footer.style-3 .footer-item .footer-list li:hover a {
  color: #029e7c;
}
footer.style-3 .footer-item .footer-list li:hover::before {
  opacity: 1;
}
footer.style-3 .footer-item .footer-list li a {
  color: #d2d2d2;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  transition: all 0.4s ease;
}
footer.style-3 .footer-item .address-list {
  margin: 0;
  padding: 0;
}
footer.style-3 .footer-item .address-list li {
  margin-bottom: 15px;
}
footer.style-3 .footer-item .address-list li a {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #d2d2d2;
  transition: all 0.42s ease;
}
footer.style-3 .footer-item .address-list li:hover a {
  color: #029e7c;
}
footer.style-3 .footer-item .footer-social {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: start;
}
footer.style-3 .footer-item .footer-social i {
  color: #fff;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  transition: all 0.42s ease;
}
footer.style-3 .footer-item .footer-social i:hover {
  background: #029e7c;
  border: 1px solid #029e7c;
}
footer.style-3 .footer-bottom {
  background: #000000;
  padding: 15px 0px;
}
footer.style-3 .footer-bottom p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #fff;
  margin-bottom: 0;
  margin-right: 30px;
}
footer.style-3 .footer-bottom p .egns-lab {
  font-weight: 600;
  color: #029e7c;
}
footer.style-3 .footer-bottom .footer-logo-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}
footer.style-3 .footer-bottom .footer-logo-list li {
  margin-right: 20px;
}
footer.style-3 .footer-bottom .footer-logo-list li img {
  transition: all 0.6s ease;
}
footer.style-3 .footer-bottom .footer-logo-list li:last-child {
  margin-right: 0px;
}
footer.style-3 .footer-bottom .footer-logo-list li:hover img {
  transform: rotate(360deg);
}

/*================================================
7. Blog page
=================================================*/
.blog-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.blog-section .section-bg-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .blog-section .section-bg-top {
    display: none;
    visibility: hidden;
  }
}
.blog-section .section-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .blog-section .section-bg-bottom {
    display: none;
    visibility: hidden;
  }
}

.pagination-wrap .page-item.active .page-link {
  z-index: 3;
  color: #f9395f !important;
  background-color: #fff !important;
  border-color: #f9395f;
}
.pagination-wrap .pagination {
  margin: 0;
}
.pagination-wrap .pagination.style-two .page-link {
  position: relative;
  display: block;
  color: #1f2230;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  transition: 0.42s ease;
  padding: 10px;
}
.pagination-wrap .pagination.style-two .page-link:focus {
  color: #f9395f;
  border-color: #f9395f;
  background-color: #fff;
}
.pagination-wrap .pagination.style-two .page-link:hover {
  color: #f9395f;
  border-color: #f9395f;
}
.pagination-wrap .page-item:first-child .page-link {
  border-radius: 2px;
}
.pagination-wrap .page-item:last-child .page-link {
  border-radius: 2px;
}
.pagination-wrap .page-link {
  position: relative;
  display: block;
  color: #1f2230;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #eeeeee;
  border-radius: 8px 8px 2px 8px;
  transition: 0.42s ease;
}
.pagination-wrap .page-link:focus {
  box-shadow: unset;
  color: #fff;
  background-color: #f9395f;
  border-color: #f9395f;
}
.pagination-wrap .page-link:hover {
  color: #fff;
  background-color: #f9395f;
  border-color: #f9395f;
}
.pagination-wrap .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #f9395f;
  border-color: #f9395f;
}

.inner-banner {
  min-height: 320px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  background-image: url("../assets/images/bg/breadcrump-bg-4/1.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.inner-banner .fake-div {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: rgba(82, 67, 67, 0.65);
  z-index: -1;
}
@media (min-width: 1921px) {
  .inner-banner {
    background-image: url("../assets/images/bg/breadcrump-bg-4/1-big-screens.png");
  }
}
@media (max-width: 1199px) {
  .inner-banner {
    background-image: url("../assets/images/bg/breadcrump-bg-4/1-small.png");
  }
}
@media (max-width: 576px) {
  .inner-banner {
    min-height: 175px;
  }
}

.breadcrumb .breadcrumb-item {
  color: #f9395f;
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  text-shadow: 1px 0px 4px rgba(0, 0, 0, 0.46);
}
.breadcrumb .breadcrumb-item.active {
  color: #fff;
}
@media (max-width: 576px) {
  .breadcrumb .breadcrumb-item {
    font-size: 14px;
    max-width: 300px;
  }
}

.inner-banner-title {
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  text-shadow: 1px 0px 4px rgba(0, 0, 0, 0.46);
}
@media (max-width: 576px) {
  .inner-banner-title.fucha-detail-title {
    max-width: 300px;
  }
}
@media (max-width: 576px) {
  .inner-banner-title {
    font-size: 20px;
  }
}

/*================================================
7. Login Page
=================================================*/
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: rgba(249, 57, 95, 0.3);
}

.nice-select .option:hover {
  background-color: rgba(249, 57, 95, 0.3);
}

.nice-select.open,
.nice-select:focus {
  border-color: #f9395f;
}

.nice-select {
  width: 100%;
  height: 50px;
  line-height: 50px;
  float: unset;
}
.nice-select .list {
  width: 100%;
}

.login-section {
  position: relative;
  z-index: 1;
  min-height: calc(100dvh - 85px);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .login-section {
    min-height: calc(100dvh - 45px);
  }
}
.login-section .section-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}
@media (max-width: 1199px) {
  .login-section .section-bg {
    display: none;
    visibility: hidden;
  }
}
.login-section .section-bg-small {
  display: none;
  visibility: hidden;
}
@media (max-width: 1199px) {
  .login-section .section-bg-small {
    display: block;
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
  }
}
.login-section .form-wrapper .form-title {
  margin-bottom: 30px;
  text-align: center;
}
.login-section .form-wrapper .form-title h3 {
  font-size: 25px;
  color: #444444;
  font-weight: 700;
  text-align: center;
  max-width: 350px;
  margin: 15px auto;
}
@media (max-width: 576px) {
  .login-section .form-wrapper .form-title h3 {
    font-size: 20px;
  }
}
.login-section .form-wrapper .form-title .login-text {
  color: #696969;
  font-family: "Inter", sans-serif;
  max-width: 200px;
  margin: auto;
}
@media (max-width: 576px) {
  .login-section .form-wrapper .form-title .login-text {
    font-size: 15px;
  }
}
.login-section .form-wrapper .form-title .login-text a {
  color: #f9395f;
  font-weight: bold;
}
.login-section .form-wrapper .form-title .login-text.reset-text {
  max-width: 100%;
}
.login-section .form-wrapper .form-title.reset-form-title {
  margin-bottom: 20px;
}
.login-section .form-wrapper .login-form .forgot-pass-container {
  padding: 5px;
}
.login-section .form-wrapper .login-form .forgot-pass-container .forgot-pass {
  color: #f9395f;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  transition: 0.3s ease;
  font-family: "Inter", sans-serif;
}
.login-section .form-wrapper .login-form .forgot-pass-container .forgot-pass::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  background: #f9395f;
  height: 1px;
  width: 100%;
}
@media (max-width: 576px) {
  .login-section .form-wrapper .login-form .forgot-pass-container .forgot-pass::after {
    display: none;
    visibility: hidden;
  }
}
.login-section .form-wrapper .login-form .forgot-pass-container .forgot-pass:hover {
  color: #1f2230;
}
.login-section .form-wrapper .login-form .forgot-pass-container .forgot-pass:hover::after {
  background: #1f2230;
}
.login-section .form-wrapper .login-form .reset-pass-container {
  margin: 0;
  padding: 5px;
}
.login-section .form-wrapper .login-form .reset-pass-container .reset-pass {
  color: #f9395f;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  transition: 0.3s ease;
  font-family: "Inter", sans-serif;
}
.login-section .form-wrapper .login-form .reset-pass-container .reset-pass::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  background: #f9395f;
  height: 1px;
  width: 100%;
}
@media (max-width: 576px) {
  .login-section .form-wrapper .login-form .reset-pass-container .reset-pass::after {
    display: none;
    visibility: hidden;
  }
}
.login-section .form-wrapper .login-form .reset-pass-container .reset-pass:hover {
  color: #1f2230;
}
.login-section .form-wrapper .login-form .reset-pass-container .reset-pass:hover::after {
  background: #1f2230;
}
.login-section .form-wrapper .login-form .form-inner {
  padding: 5px;
}
.login-section .form-wrapper .login-form .form-inner i {
  bottom: 20px !important;
}
.login-section .form-wrapper .login-form .form-inner input {
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
@media (max-width: 576px) {
  .login-section .form-wrapper .login-form .form-inner input {
    font-size: 14px;
  }
}
.login-section .form-wrapper .login-form .form-inner input::-moz-placeholder {
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
.login-section .form-wrapper .login-form .form-inner input::placeholder {
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
@media (max-width: 576px) {
  .login-section .form-wrapper .login-form .form-inner input::-moz-placeholder {
    font-size: 14px;
  }
  .login-section .form-wrapper .login-form .form-inner input::placeholder {
    font-size: 14px;
  }
}
.login-section .form-wrapper .login-form .veryfi-error {
  padding-left: 18px;
  margin-top: -5px;
  margin-bottom: 10px;
}
.login-section .form-wrapper .login-form .password-error,
.login-section .form-wrapper .login-form .email-error {
  min-height: 25px;
  position: relative;
  padding: 0 6px;
}

.form-wrapper {
  border-radius: 5px;
  box-shadow: 5px 7px 35px rgba(139, 139, 139, 0.1);
  padding: 40px;
  background: #fff;
}
.form-wrapper .error-message {
  color: red;
  font-size: 12px;
  text-transform: none;
}
@media (max-width: 576px) {
  .form-wrapper {
    padding: 25px;
  }
}
.form-wrapper .form-title2 {
  margin-bottom: 30px;
}
.form-wrapper .form-title2 h3 {
  font-size: 35px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 15px;
}
.form-wrapper label {
  color: #1f2230;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  font-family: "Saira", sans-serif;
}
.form-wrapper input,
.form-wrapper textarea {
  border: 1px solid #d6d3d3;
  height: 54px;
  width: 100%;
  padding: 2px 8px;
  transition: 0.4s ease;
  border-radius: 0.25rem;
}
.form-wrapper input::-moz-placeholder, .form-wrapper textarea::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
}
.form-wrapper input::placeholder,
.form-wrapper textarea::placeholder {
  font-size: 15px;
  font-weight: 400;
}
.form-wrapper input:focus,
.form-wrapper textarea:focus {
  border: 1px solid #f9395f;
}
.form-wrapper textarea {
  min-height: 150px;
}
.form-wrapper .check-box-text {
  font-size: 16px;
}
.form-wrapper .form-inner {
  margin-bottom: 18px;
  position: relative;
  padding: 5px;
}
.form-wrapper .form-inner i {
  position: absolute;
  right: 20px;
  bottom: 15px;
}
.form-wrapper .form-agreement {
  margin-left: 33px;
  padding: 0;
}
.form-wrapper .form-agreement label {
  font-size: 13px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.form-wrapper .form-agreement label a {
  font-weight: 600;
  font-size: 16;
}
.form-wrapper .forgotpass {
  text-align: center;
  margin-top: 25px;
}
@media (max-width: 576px) {
  .form-wrapper .sign-here-btn {
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .form-wrapper .sign-here-btn {
    font-size: 12px;
  }
}
.form-wrapper .account-btn {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background: #f9395f;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 12px;
  outline: none;
  border: 1px solid #f9395f;
  border-radius: 5px;
  transition: 0.4s ease;
}
.form-wrapper .account-btn:hover {
  background: #fff;
  color: #f9395f;
}
@media (max-width: 576px) {
  .form-wrapper .account-btn {
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .form-wrapper .account-btn {
    font-size: 12px;
  }
}
.form-wrapper .alternate-signup-box {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding: 20px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 35px;
}
.form-wrapper .alternate-signup-box h6 {
  font-size: 16px;
  font-weight: 700;
  color: #1f2230;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.form-wrapper .alternate-signup-box .btn-group {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.form-wrapper .alternate-signup-box .btn-group a {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  padding: 12px 15px;
  color: #fff;
  border-radius: 5px;
  transition: 0.5s ease;
}
.form-wrapper .alternate-signup-box .btn-group a:hover {
  background: #1f2230;
}
.form-wrapper .alternate-signup-box .btn-group i {
  font-size: 16px;
}
.form-wrapper .alternate-signup-box .google-btn {
  background: #ea4335;
}
.form-wrapper .alternate-signup-box .facebook-btn {
  background: #4267b2;
}
.form-wrapper .form-poicy-area {
  text-align: center;
}
.form-wrapper .form-poicy-area a {
  font-weight: 600;
  color: #f9395f;
  text-decoration: underline;
}
.form-wrapper .form-group input[type=checkbox] {
  display: none;
  cursor: pointer;
}
.form-wrapper input[type=checkbox] + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid black;
  margin-left: -33px;
}
.form-wrapper input[id=marketingComms] + label:before,
.form-wrapper input[id=externalMarketingComms] + label:before {
  margin-top: 8px;
  margin-bottom: -10px;
}
@media (max-width: 576px) {
  .form-wrapper input[id=marketingComms] + label:before,
  .form-wrapper input[id=externalMarketingComms] + label:before {
    margin-top: 0;
    margin-bottom: -10px;
  }
}
.form-wrapper input[type=checkbox]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: -23px;
  width: 4px;
  height: 12px;
  border: 1px solid #1f2230;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
@media (max-width: 576px) {
  .form-wrapper input[type=checkbox]:checked + label:after {
    top: 4px;
  }
}
.form-wrapper input[id=marketingComms]:checked + label:after,
.form-wrapper input[id=externalMarketingComms]:checked + label:after {
  top: 13px;
}
@media (max-width: 576px) {
  .form-wrapper input[id=marketingComms]:checked + label:after,
  .form-wrapper input[id=externalMarketingComms]:checked + label:after {
    top: 8px;
  }
}
.form-wrapper input[id=latestFuchyNotificationEnable]:checked + label:after,
.form-wrapper input[id=notificationEnable]:checked + label:after,
.form-wrapper input[id=isMarketingEnabled]:checked + label:after,
.form-wrapper input[id=isExternalMarketingEnabled]:checked + label:after {
  top: 5px;
  left: 10px;
}
.form-wrapper button.form--btn {
  outline: none;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 25px;
}

.signup-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 576px) {
  .signup-section {
    padding: 40px 0;
  }
}
.signup-section .section-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}
.signup-section .form-title {
  margin-bottom: 60px;
  text-align: center;
}
@media (max-width: 576px) {
  .signup-section .form-title {
    margin-bottom: 30px;
  }
}
.signup-section .form-title h3 {
  font-size: 25px;
  color: #444444;
  font-weight: 700;
  text-align: center;
  max-width: 300px;
  margin: 15px auto;
}
@media (max-width: 576px) {
  .signup-section .form-title h3 {
    font-size: 20px;
  }
}
.signup-section .form-title .eg-btn {
  text-transform: uppercase;
  font-weight: 400;
}
@media (max-width: 576px) {
  .signup-section .form-title .eg-btn {
    font-size: 14px;
  }
}
.signup-section .form-title .signup-text {
  color: #696969;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .signup-section .form-title .signup-text {
    font-size: 15px;
  }
}
.signup-section .form-title .signup-text a {
  color: #f9395f;
  font-weight: bold;
}
.signup-section .section-bg-top {
  position: absolute;
  top: 0;
  right: -80px;
  z-index: -1;
}
@media (max-width: 767px) {
  .signup-section .section-bg-top {
    display: none;
    visibility: hidden;
  }
}
.signup-section .section-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .signup-section .section-bg-bottom {
    display: none;
    visibility: hidden;
  }
}

/*================================================
7. How Work Page
=================================================*/
.how-works-btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
@media (max-width: 767px) {
  .how-works-btn-container {
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .how-works-btn-container {
    max-width: 300px;
  }
}
.how-works-btn-container .how-works-btn {
  border-radius: 0.25rem;
  text-decoration: none;
  transition: all 0.4s ease;
  text-transform: capitalize;
  font-family: "Saira", sans-serif;
  border: none;
  color: #000;
  margin: 10px;
  height: 60px;
  width: 400px;
}
.how-works-btn-container .how-works-btn.active-tab {
  background-color: #f9395f;
  color: #fff;
}
@media (max-width: 576px) {
  .how-works-btn-container .how-works-btn {
    width: 100%;
  }
}

.how-work-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 576px) {
  .how-work-section {
    padding-top: 40px;
  }
}
@media (max-width: 576px) {
  .how-work-section .row {
    padding: 10px;
  }
}
.how-work-section .video-container {
  margin: 100px 0;
}
@media (max-width: 576px) {
  .how-work-section .video-container {
    margin: 20px 0;
  }
}
@media (max-width: 576px) {
  .how-work-section .video-container iframe {
    height: 250px;
  }
}
.how-work-section .how-work-title {
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.how-work-section .how-work-title h3 {
  max-width: 610px;
  font-weight: bold;
  color: #1f2230;
  font-size: 28px;
}
@media (max-width: 576px) {
  .how-work-section .how-work-title h3 {
    font-size: 25px;
  }
}
.how-work-section .section-bg-top {
  position: absolute;
  top: 0;
  right: -80px;
  z-index: -1;
}
@media (max-width: 767px) {
  .how-work-section .section-bg-top {
    display: none;
    visibility: hidden;
  }
}
.how-work-section .how-work-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}
.how-work-section .how-work-content span {
  font-size: 50px;
  font-weight: 700;
  color: #f9395f;
  font-family: "Saira", sans-serif;
  display: block;
  margin-bottom: 15px;
}
.how-work-section .how-work-content h3 {
  font-size: 30px;
  font-weight: 700;
  color: #1f2230;
}
@media (max-width: 576px) {
  .how-work-section .how-work-content h3 {
    font-size: 25px;
  }
}
.how-work-section .how-work-content a {
  margin-top: 25px;
}
.how-work-section .how-work-img {
  background: #fff;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  width: 100%;
  max-width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .how-work-section .how-work-img {
    box-shadow: unset;
  }
}
@media (max-width: 767px) {
  .how-work-section .how-work-img {
    box-shadow: unset;
    max-width: 100%;
    height: auto;
  }
}
.how-work-section .how-work-img .work-img {
  max-width: 65%;
  animation: jumping 4s linear infinite alternate;
}
@keyframes jumping {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.single-feature {
  border: 1px solid #eeeeee;
  background: #fff;
  padding: 25px;
  text-align: center;
  position: relative;
  transition: all 0.42s ease;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.single-feature:hover {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
}
.single-feature:hover .sn {
  -webkit-text-stroke-color: #1f2230;
}
.single-feature:hover .icon svg {
  fill: #f9395f;
}
.single-feature .sn {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 50px;
  font-weight: 700;
  color: rgba(31, 34, 48, 0.1);
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(31, 34, 48, 0.1);
  line-height: 1;
  transition: all 0.42s ease;
}
@media (max-width: 576px) {
  .single-feature .sn {
    font-size: 40px;
  }
}
.single-feature .icon {
  margin-bottom: 20px;
}
.single-feature .icon svg {
  fill: #696969;
  transition: all 0.42s ease;
}
.single-feature .content h5 {
  font-size: 22px;
  font-weight: 700;
  color: #1f2230;
  transition: all 0.42s ease-in;
  cursor: unset;
}
.single-feature .content h5:hover {
  color: #f9395f;
}

.choose-us-section {
  position: relative;
  overflow: hidden;
}
.choose-us-section .section-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 767px) {
  .choose-us-section .section-bg-bottom {
    display: none;
    visibility: hidden;
  }
}

/*================================================
FAQ Page
=================================================*/
.faq-wrap .accordion-item {
  margin-bottom: 24px;
  border: none;
}
.faq-wrap .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-wrap .accordion-button {
  font-weight: 500;
  font-size: 20px;
  background: #fff;
  border-radius: 5px;
  color: #1f2230;
  padding: 25px 60px 25px 25px;
  padding-right: 60px;
  position: relative;
  transition: 0.4s ease-in;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
}
@media (max-width: 767px) {
  .faq-wrap .accordion-button {
    padding: 25px 60px 25px 25px;
  }
}
.faq-wrap .accordion-button:hover::after {
  background: #f9395f;
  color: #fff;
}
.faq-wrap .accordion-button:focus {
  z-index: unset;
  border-color: unset;
  outline: 0;
  background: #fff;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
}
.faq-wrap .accordion-button::after {
  flex-shrink: 0;
  width: unset;
  height: unset;
  margin-left: auto;
  background-image: none;
  background-repeat: unset;
  background-size: unset;
  font-family: bootstrap-icons !important;
  font-size: 15px;
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  content: "\f282";
  transition: unset;
  font-size: 20px;
  color: #f9395f;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f9395f;
  transition: all 0.4s ease;
}
.faq-wrap .accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: unset;
  font-family: bootstrap-icons !important;
  content: "\f286";
  background: #f9395f;
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
}
.faq-wrap .accordion-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  background: rgba(249, 57, 95, 0.05);
  border-top: none;
  text-align: left;
}
.faq-wrap .accordion-button:not(.collapsed) {
  box-shadow: unset;
  color: #1f2230;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  background: #fff;
}

.faq-form-area {
  margin-top: 78px;
  padding: 25px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
}
@media (max-width: 991px) {
  .faq-form-area {
    margin-top: unset;
  }
}
.faq-form-area h5 {
  font-size: 25px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 10px;
}
.faq-form-area p {
  margin-bottom: 20px;
}
.faq-form-area .faq-form label {
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: 500;
  color: #1f2230;
  font-family: "Saira", sans-serif;
  line-height: 1;
}
.faq-form-area .faq-form .form-inner {
  margin-bottom: 20px;
}
.faq-form-area .faq-form input,
.faq-form-area .faq-form textarea {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #eeeeee;
  transition: 0.35s ease-in;
}
@media (max-width: 767px) {
  .faq-form-area .faq-form input,
  .faq-form-area .faq-form textarea {
    padding: 10px 15px;
  }
}
.faq-form-area .faq-form input::-moz-placeholder, .faq-form-area .faq-form textarea::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
  font-family: "Inter", sans-serif;
}
.faq-form-area .faq-form input::placeholder,
.faq-form-area .faq-form textarea::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
  font-family: "Inter", sans-serif;
}
.faq-form-area .faq-form input:focus,
.faq-form-area .faq-form textarea:focus {
  border: 1px solid #f9395f;
}

.section-title3 {
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 600;
  color: #1f2230;
  text-align: left;
}

/*================================================
live-auction Page
=================================================*/
.live-auction-section {
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .live-auction-section {
    padding-top: 10px;
  }
}
.live-auction-section .solid {
  display: none;
}
@media (max-width: 991px) {
  .live-auction-section h6 {
    padding-left: 14px;
    padding-bottom: 8px;
  }
}
.live-auction-section .filter-select-container {
  margin-bottom: 25px;
}
@media (max-width: 991px) {
  .live-auction-section .filter-select-container {
    margin-bottom: 5px;
  }
}
.live-auction-section .filter-select-container .filter-select,
.live-auction-section .filter-select-container .filter-select__menu {
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
@media (max-width: 767px) {
  .live-auction-section .filter-select-container .filter-select,
  .live-auction-section .filter-select-container .filter-select__menu {
    font-size: 14px;
  }
}
.live-auction-section .no-fuchy-container {
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.live-auction-section .no-fuchy-container h4 {
  padding: 10px;
}
@media (max-width: 576px) {
  .live-auction-section .no-fuchy-container h4 {
    font-size: 20px;
    padding: 35px;
  }
}
.live-auction-section .no-fuchy-container .image-container {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: move 2.6s ease-in-out infinite;
}
@media (max-width: 767px) {
  .live-auction-section .no-fuchy-container .image-container {
    height: 150px;
    width: 150px;
  }
}
.live-auction-section .no-fuchy-container .image-container img {
  height: 100%;
  width: 100%;
}
.live-auction-section .section-bg-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .live-auction-section .section-bg-top {
    display: none;
    visibility: hidden;
  }
}
.live-auction-section .section-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .live-auction-section .section-bg-bottom {
    display: none;
    visibility: hidden;
  }
}

/*================================================
 Auction-details Page
=================================================*/
.auction-details-section {
  position: relative;
}
.auction-details-section .section-bg-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .auction-details-section .section-bg-top {
    display: none;
    visibility: hidden;
  }
}
.auction-details-section .section-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .auction-details-section .section-bg-bottom {
    display: none;
    visibility: hidden;
  }
}
.auction-details-section #fucha-detail-tabs button {
  background: #fff;
}

.product-details-right h3 {
  font-size: 30px;
  font-weight: 700;
  font-family: "Saira", sans-serif;
  color: #1f2230;
  margin-bottom: 10px;
}
.product-details-right p {
  margin-bottom: 20px;
}
.product-details-right h4 {
  font-size: 25px;
  font-weight: 400;
  color: #1f2230;
}
.product-details-right h4 span {
  font-weight: 700;
}
.product-details-right .bid-form {
  border-radius: 5px;
  background: #fff;
}
.product-details-right .bid-form .extra-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2px;
  padding-top: 15px;
}
.product-details-right .bid-form .extra-info-container p,
.product-details-right .bid-form .extra-info-container span {
  color: #696969;
  margin: 0;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
}
.product-details-right .bid-form .extra-info-container .bid-para {
  font-size: 13px;
  margin-bottom: 5px;
  color: #696969;
  font-weight: bold;
}
@media (max-width: 576px) {
  .product-details-right .bid-form .extra-info-container .bid-para {
    font-size: 12px !important;
    line-height: 20px !important;
  }
}
.product-details-right .bid-form .extra-info-container span {
  font-size: 12px;
  color: #f9395f;
  margin-top: 5px;
}
.product-details-right .bid-form .form-title {
  padding-bottom: 3px;
  margin-bottom: 20px;
}
.product-details-right .bid-form .form-title h5 {
  font-size: 22px;
  font-weight: bold;
  color: #1f2230;
}
@media (max-width: 576px) {
  .product-details-right .bid-form .form-title h5 {
    font-size: 16px;
  }
}
.product-details-right .bid-form .form-title p {
  font-size: 16px;
  color: #696969;
  font-family: "Inter", sans-serif;
  position: relative;
}
@media (max-width: 576px) {
  .product-details-right .bid-form .form-title p {
    font-size: 14px;
  }
}
.product-details-right .bid-form .form-title p::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -12px;
  width: 52px;
  height: 3px;
  border-radius: 5px;
  background-color: #f9395f;
}
.product-details-right .bid-form .form-title p::after {
  content: "";
  position: absolute;
  left: 56px;
  bottom: -12px;
  width: 4px;
  height: 3px;
  border-radius: 5px;
  background-color: #f9395f;
}
.product-details-right .bid-form .form-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 991px) {
  .product-details-right .bid-form .form-inner {
    flex-direction: column;
  }
}
.product-details-right .bid-form .form-inner fieldset,
.product-details-right .bid-form .form-inner .need-more-info {
  width: 100%;
  padding: 0 3px;
  flex-direction: row;
  align-items: center;
}
.product-details-right .bid-form .form-inner fieldset label,
.product-details-right .bid-form .form-inner .need-more-info label {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #696969;
}
.product-details-right .bid-form .form-inner .need-more-info {
  border: 1px solid #eeeeee;
  border-radius: 0.25rem;
  margin-bottom: 15px;
}
.product-details-right .bid-form .form-inner .need-more-info label {
  font-size: 13px;
}
@media (max-width: 576px) {
  .product-details-right .bid-form .form-inner .need-more-info label {
    font-size: 12px;
  }
}
@media (max-width: 576px) {
  .product-details-right .bid-form .form-inner .need-more-info {
    margin-bottom: 15px;
    margin-left: 0;
  }
}
.product-details-right .bid-form .form-inner fieldset .MuiFormControlLabel-root {
  margin-left: 5px;
}
.product-details-right .bid-form .form-inner fieldset .MuiFormControlLabel-root span {
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
@media (max-width: 576px) {
  .product-details-right .bid-form .form-inner fieldset .MuiFormControlLabel-root span {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .product-details-right .bid-form .form-inner fieldset .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 576px) {
  .product-details-right .bid-form .form-inner fieldset {
    flex-direction: column;
    align-items: baseline;
    margin-top: 10px;
  }
}
.product-details-right .bid-form .form-inner input[type=text] {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 11px 15px;
  transition: all 0.42s ease;
}
.product-details-right .bid-form .form-inner input[type=text]::-moz-placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #696969;
}
.product-details-right .bid-form .form-inner input[type=text]::placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #696969;
}
.product-details-right .bid-form .form-inner input[type=text]:focus {
  border: 1px solid #f9395f;
}
@media (max-width: 991px) {
  .product-details-right .bid-form button {
    width: 100%;
    font-size: 15px;
  }
}

.small-image-list .nav-item img {
  max-width: 105px;
  width: 100%;
  border-radius: 5px;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
  transition: all 0.62s ease;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .small-image-list .nav-item img {
    height: 115px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .small-image-list .nav-item img {
    height: 112px;
  }
}
@media (max-width: 767px) {
  .small-image-list .nav-item img {
    height: 100px;
  }
}
.small-image-list .nav-item:hover img {
  transform: scale(1.1);
}

.tab-content {
  height: 100%;
}
.tab-content img {
  width: 100%;
  border-radius: 0px 0px 5px 5px;
}
.tab-content .auction-gallery-timer {
  background-color: #1f2230;
  border-radius: 5px 4px 0px 0px;
  text-align: center;
  padding: 10px 20px;
}
.tab-content .auction-gallery-timer h3 {
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .tab-content .auction-gallery-timer h3 {
    font-size: 24px;
  }
}

#fucha-detail-tabs button {
  background: #ffffff;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  color: #1f2230;
  font-size: 18px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  width: 100%;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-out 0s;
}
#fucha-detail-tabs button:hover {
  background: #f9395f;
  color: #fff;
}
#fucha-detail-tabs button.active {
  background: #f9395f !important;
  color: #fff;
}

.chat-list {
  padding-left: 0;
}
.chat-list li {
  border-bottom: 1px solid #eeeeee;
  padding: 20px 0px;
}
.chat-list li:last-child {
  border-bottom: unset;
  padding-bottom: 0px;
}
.chat-list .chat-user-area {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}
.chat-list .chat-user-area .chat-user-img {
  margin-right: 25px;
}
@media (max-width: 767px) {
  .chat-list .chat-user-area .chat-user-img {
    margin-right: 15px;
  }
}
.chat-list .chat-user-area .chat-user-content h6 {
  font-size: 18px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 3px;
  font-family: "Saira", sans-serif;
}
.chat-list .chat-user-area .chat-user-content p {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
}
.chat-list .chat-msg-time p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
}

.chat-list-area {
  max-height: 600px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 5px 5px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column-reverse;
}
.chat-list-area::-webkit-scrollbar {
  width: 5px;
}
.chat-list-area::-webkit-scrollbar-track {
  background: #eeeeee;
}
.chat-list-area::-webkit-scrollbar-thumb {
  background: #888;
  -webkit-transition: 0.35s ease-in;
  transition: 0.35s ease-in;
  border-radius: 5px;
  height: 100px;
}
.chat-list-area::-webkit-scrollbar-thumb:hover {
  background: #1f2230;
}

.describe-content h5 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #1f2230;
}
@media (max-width: 576px) {
  .describe-content h5 {
    font-size: 18px;
  }
}
.describe-content p {
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .describe-content p {
    font-size: 14px;
    line-height: 25px;
  }
}
.describe-content .describe-list {
  margin: 0;
  padding: 0;
}
.describe-content .describe-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;
}
.describe-content .describe-list li::before {
  content: "\f269";
  font-family: "bootstrap-icons";
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 20px;
}
.describe-content .describe-list li a {
  font-size: 18px;
  font-weight: 500;
  font-family: "Saira", sans-serif;
  color: #696969;
  line-height: 1.5;
}
.describe-content .describe-list li .bx {
  font-size: 24px;
  margin-right: 12px;
}

.offer-banner {
  padding: 25px;
  border-radius: 5px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
}
.offer-banner h4 {
  font-size: 24px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 15px;
}
.offer-banner img {
  width: 100%;
}

.chat-image,
.bid-image {
  max-width: 60px;
  width: 100% !important;
  height: 100%;
  border-radius: 50% !important;
}

.bid-list {
  padding-left: 0;
}
.bid-list li {
  border-bottom: 1px solid #eeeeee;
  padding: 20px 0px;
}
.bid-list li:last-child {
  border-bottom: unset;
  padding-bottom: 0px;
}
.bid-list .bid-user-area {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}
.bid-list .bid-user-area .bid-user-img {
  margin-right: 25px;
}
@media (max-width: 767px) {
  .bid-list .bid-user-area .bid-user-img {
    margin-right: 15px;
  }
}
.bid-list .bid-user-area .bid-user-content h6 {
  font-size: 18px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 3px;
  font-family: "Saira", sans-serif;
}
.bid-list .bid-user-area .bid-user-content p {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
}
.bid-list .bid-msg-time p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
}

.bid-list-area {
  max-height: 600px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 5px 5px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column-reverse;
}
.bid-list-area::-webkit-scrollbar {
  width: 5px;
}
.bid-list-area::-webkit-scrollbar-track {
  background: #eeeeee;
}
.bid-list-area::-webkit-scrollbar-thumb {
  background: #888;
  -webkit-transition: 0.35s ease-in;
  transition: 0.35s ease-in;
  border-radius: 5px;
  height: 100px;
}
.bid-list-area::-webkit-scrollbar-thumb:hover {
  background: #1f2230;
}

/*================================================
7.  about
=================================================*/
.about-section {
  position: relative;
}
@media (max-width: 576px) {
  .about-section {
    padding-top: 40px;
  }
}
.about-section .section-bg-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 1199px) {
  .about-section .section-bg-top {
    display: none;
    visibility: hidden;
  }
}
@media (max-width: 1399px) {
  .about-section .about-content-container {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .about-section .about-content-container {
    max-width: 600px;
  }
}
@media (max-width: 576px) {
  .about-section .about-content-container {
    max-width: 375px;
  }
}
.about-section .about-content-container .about-content {
  display: block;
  visibility: visible;
}
@media (max-width: 1399px) {
  .about-section .about-content-container .about-content {
    display: none;
    visibility: hidden;
  }
}
.about-section .about-content-container .about-content .header-text {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}
.about-section .about-content-container .about-content h2 {
  font-size: 30px;
  font-weight: 700;
  color: #1f2230;
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .about-section .about-content-container .about-content h2 {
    font-size: 25px;
  }
}
.about-section .about-content-container .about-content h2 .logo-text {
  font-size: 35px !important;
}
.about-section .about-content-container .about-content .about-list {
  margin-top: 25px;
}
.about-section .about-content-container .small-devices-about-content {
  display: none;
  visibility: hidden;
}
@media (max-width: 1399px) {
  .about-section .about-content-container .small-devices-about-content {
    display: block;
    visibility: visible;
  }
}
.about-section .about-content-container .small-devices-about-content .small-devices-image {
  display: block;
  float: right;
  margin-left: 20px;
  border-radius: 50%;
  shape-outside: circle();
}
@media (max-width: 1399px) {
  .about-section .about-content-container .small-devices-about-content .small-devices-image {
    height: 400px;
    margin-left: 125px;
  }
}
@media (max-width: 1199px) {
  .about-section .about-content-container .small-devices-about-content .small-devices-image {
    height: 345px;
    margin-left: 40px;
  }
}
@media (max-width: 991px) {
  .about-section .about-content-container .small-devices-about-content .small-devices-image {
    height: 238px;
  }
}
@media (max-width: 767px) {
  .about-section .about-content-container .small-devices-about-content .small-devices-image {
    height: 210px;
  }
}
@media (max-width: 576px) {
  .about-section .about-content-container .small-devices-about-content .small-devices-image {
    height: 150px;
  }
}
@media (max-width: 375px) {
  .about-section .about-content-container .small-devices-about-content .small-devices-image {
    height: 120px;
  }
}
.about-section .about-content-container .small-devices-about-content .header-text {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
  text-align: left;
}
.about-section .about-content-container .small-devices-about-content h2 {
  font-size: 40px;
  font-weight: 700;
  color: #1f2230;
  margin-bottom: 15px;
}
@media (max-width: 1399px) {
  .about-section .about-content-container .small-devices-about-content h2 {
    font-size: 43px;
  }
}
@media (max-width: 1199px) {
  .about-section .about-content-container .small-devices-about-content h2 {
    font-size: 38px;
  }
}
@media (max-width: 576px) {
  .about-section .about-content-container .small-devices-about-content h2 {
    font-size: 30px;
  }
}
@media (max-width: 1399px) {
  .about-section .about-content-container .small-devices-about-content h2 .logo-text {
    font-size: 50px;
  }
}
@media (max-width: 576px) {
  .about-section .about-content-container .small-devices-about-content h2 .logo-text {
    font-size: 35px;
  }
}

.about-list {
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
}
.about-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;
}
.about-list li::before {
  content: "\f269";
  font-family: "bootstrap-icons";
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 20px;
}
.about-list li a {
  font-size: 18px;
  font-weight: 500;
  font-family: "Saira", sans-serif;
  color: #696969;
  line-height: 1.5;
}
.about-list li .bx {
  font-size: 24px;
  margin-right: 12px;
}

.about-img-area {
  position: relative;
}
@media (max-width: 1399px) {
  .about-img-area .about-img {
    display: none;
    visibility: hidden;
  }
}
.about-img-area .total-tag {
  position: absolute;
  background: #ffffff;
  box-shadow: 3px 2px 35px rgba(0, 27, 85, 0.08);
  border-radius: 50%;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: -8px;
  right: 0px;
  animation: fadeInUp 1.7s;
}
@media (max-width: 1399px) {
  .about-img-area .total-tag {
    display: none;
    visibility: hidden;
  }
}
.about-img-area .total-tag img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.about-img-area .about-vector {
  position: absolute;
  left: -50px;
  top: 0;
  z-index: -1;
  animation: rotation 14s linear infinite;
}
@media (max-width: 1399px) {
  .about-img-area .about-vector {
    display: none;
    visibility: hidden;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.about-img-area .about-linear {
  position: absolute;
  bottom: -70px;
  left: 0;
}
@media (max-width: 1399px) {
  .about-img-area .about-linear {
    display: none;
    visibility: hidden;
  }
}

.choose-us-section .single-feature {
  height: 300px;
}
@media (max-width: 991px) {
  .choose-us-section .single-feature {
    padding: 10px;
    max-width: 600px;
  }
}
@media (max-width: 576px) {
  .choose-us-section .single-feature {
    max-width: 375px;
  }
}
.choose-us-section .single-feature .icon {
  width: 100%;
  height: 65px;
}
.choose-us-section .single-feature .content {
  width: 100%;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .choose-us-section .single-feature .content h5 {
    font-size: 19px;
  }
}
.choose-us-section .single-feature .content .para {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .choose-us-section .single-feature .content .para {
    font-size: 14px;
    line-height: 25px;
  }
}
@media (max-width: 991px) {
  .choose-us-section .section-title1 {
    max-width: 600px;
  }
}
@media (max-width: 576px) {
  .choose-us-section .section-title1 {
    max-width: 375px;
    padding: 10px;
  }
}
.choose-us-section .section-title1 h2 {
  font-size: 40px;
}
@media (max-width: 576px) {
  .choose-us-section .section-title1 h2 {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 20px;
  }
}
.choose-us-section .angle-vector {
  position: absolute;
  left: -20px;
  top: 130px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .choose-us-section .angle-vector {
    top: 150px;
    left: -10px;
  }
}
@media (max-width: 991px) {
  .choose-us-section .angle-vector {
    display: none;
    visibility: hidden;
  }
}

/*================================================
 Contact page
=================================================*/
.contact-section {
  position: relative;
  z-index: 1;
}
@media (max-width: 576px) {
  .contact-section {
    padding: 60px 0;
  }
}
.contact-section .form-title2 {
  max-width: 100%;
}
@media (max-width: 1199px) {
  .contact-section .form-title2 {
    max-width: 500px;
  }
}
@media (max-width: 991px) {
  .contact-section .form-title2 {
    max-width: 400px;
  }
}
@media (max-width: 576px) {
  .contact-section .form-title2 {
    max-width: 230px;
  }
}
@media (max-width: 576px) {
  .contact-section .form-title2 h3 {
    font-size: 25px;
  }
}
.contact-section .image-area {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media (max-width: 1199px) {
  .contact-section .image-area {
    height: 200px;
    width: 200px;
    position: absolute;
    top: 65px;
    right: 120px;
    z-index: -1;
  }
}
@media (max-width: 991px) {
  .contact-section .image-area {
    height: 200px;
    width: 200px;
    top: 60px;
    right: 70px;
  }
}
@media (max-width: 576px) {
  .contact-section .image-area {
    height: 150px;
    width: 150px;
    top: 37px;
    right: 8px;
  }
}
@media (max-width: 375px) {
  .contact-section .image-area {
    height: 130px;
    width: 130px;
    right: 0;
    top: 40px;
  }
}
.contact-section .image-area img {
  width: 70%;
  height: 70%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 1199px) {
  .contact-section .image-area img {
    width: 100%;
    height: 100%;
  }
}
.contact-section .form-wrapper {
  box-shadow: none;
  background: none;
  padding: 20px;
}
@media (max-width: 576px) {
  .contact-section .form-wrapper {
    padding: 10px;
  }
}
.contact-section .form-wrapper .form-inner {
  padding: 0;
}
.contact-section .form-wrapper .form-inner .contact-form-inner__value-container {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #1f2230;
  padding: 0 8px !important;
}
@media (max-width: 576px) {
  .contact-section .form-wrapper .form-inner .contact-form-inner__value-container {
    font-size: 14px;
  }
}
.contact-section .form-wrapper .form-inner .contact-form-inner__value-container::-moz-placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #696969;
}
.contact-section .form-wrapper .form-inner .contact-form-inner__value-container::placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #696969;
}
@media (max-width: 576px) {
  .contact-section .form-wrapper .form-inner .contact-form-inner__value-container::-moz-placeholder {
    font-size: 14px;
  }
  .contact-section .form-wrapper .form-inner .contact-form-inner__value-container::placeholder {
    font-size: 14px;
  }
}
.contact-section .form-wrapper .form-inner .text-counter {
  font-size: 14px;
  text-align: right;
  width: 100%;
  font-family: "Inter", sans-serif;
  color: #696969;
}
.contact-section .form-wrapper .form-inner textarea {
  padding: 10px 8px;
  resize: none;
  border: 1px solid #d6d3d3;
  border-radius: 0.25rem;
  overflow: auto;
  min-height: 200px;
  font-size: 15px;
  position: relative;
  font-family: "Inter", sans-serif;
  color: #1f2230;
}
@media (max-width: 576px) {
  .contact-section .form-wrapper .form-inner textarea {
    font-size: 14px;
  }
}
.contact-section .form-wrapper .form-inner textarea::-moz-placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #696969;
}
.contact-section .form-wrapper .form-inner textarea::placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #696969;
}
@media (max-width: 576px) {
  .contact-section .form-wrapper .form-inner textarea::-moz-placeholder {
    font-size: 14px;
  }
  .contact-section .form-wrapper .form-inner textarea::placeholder {
    font-size: 14px;
  }
}
.contact-section .form-wrapper .form-inner input {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #1f2230;
}
@media (max-width: 576px) {
  .contact-section .form-wrapper .form-inner input {
    font-size: 14px;
  }
}
.contact-section .form-wrapper .form-inner input::-moz-placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #696969;
}
.contact-section .form-wrapper .form-inner input::placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #696969;
}
@media (max-width: 576px) {
  .contact-section .form-wrapper .form-inner input::-moz-placeholder {
    font-size: 14px;
  }
  .contact-section .form-wrapper .form-inner input::placeholder {
    font-size: 14px;
  }
}
.contact-section .section-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 991px) {
  .contact-section .section-bg-bottom {
    display: none;
    visibility: hidden;
  }
}

/*================================================
 Dashboard-page
=================================================*/
.text-red {
  color: #f9395f !important;
}

.text-green {
  color: #f9395f !important;
}

.dashboard-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 576px) {
  .dashboard-section {
    padding-top: 50px;
  }
}
.dashboard-section .create-fuche .mobiles-bg {
  display: none;
}
@media (max-width: 576px) {
  .dashboard-section .create-fuche .mobiles-bg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
  }
}
@media (max-width: 576px) {
  .dashboard-section .create-fuche {
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .dashboard-section .create-fuche .create-fuche-content h2 {
    font-size: 21px;
  }
}
@media (max-width: 991px) {
  .dashboard-section .create-fuche .create-fuche-content h2 {
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .dashboard-section .create-fuche .create-fuche-content .para {
    font-size: 14px;
    line-height: 23px;
  }
}
@media (max-width: 991px) {
  .dashboard-section .create-fuche .create-fuche-content .para {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .dashboard-section .create-fuche .create-fuche-content .para {
    display: none;
  }
}
@media (max-width: 991px) {
  .dashboard-section .create-fuche .about-img-area .about-img {
    display: none;
  }
}
@media (max-width: 991px) {
  .dashboard-section .create-fuche .about-img-area .about-vector {
    display: none;
  }
}
.dashboard-section .fui-avatar-label {
  font-family: "Saira", sans-serif;
  font-size: 16px;
  line-height: 18px;
}
.dashboard-section .section-bg-1,
.dashboard-section .section-bg-2 {
  display: none;
}
@media (max-width: 991px) {
  .dashboard-section .section-bg-1,
  .dashboard-section .section-bg-2 {
    position: absolute;
    display: block;
    visibility: visible;
    z-index: -1;
  }
}
@media (max-width: 991px) {
  .dashboard-section .section-bg-1 {
    top: 0;
    right: 0;
  }
}
@media (max-width: 991px) {
  .dashboard-section .section-bg-2 {
    bottom: 0;
    left: 0;
  }
}
@media (max-width: 991px) {
  .dashboard-section .dashboard-menu {
    display: none;
    visibility: hidden;
  }
}
.dashboard-section .nav-pills .nav-link {
  background-color: #fff;
}
.dashboard-section .nav-pills .nav-link:hover {
  background-color: #f9395f;
}

.table-title-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
  font-family: "Inter", sans-serif;
  color: #696969;
}
.table-title-area #fucha-category {
  cursor: pointer;
  height: 35px;
  width: 185px;
  color: #696969;
  border: 1px solid #696969;
  border-radius: 0.25rem;
}
.table-title-area option {
  font-family: "Inter", sans-serif;
  color: #696969;
  cursor: pointer;
}
.table-title-area h3 {
  font-size: 30px;
  font-weight: 600;
  color: #1f2230;
  line-height: 1.7;
}
.table-title-area .nice-select {
  max-width: 270px;
}

.eg-table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}
.eg-table thead {
  background: #f9395f;
}
.eg-table thead tr {
  border-width: 1px;
}
.eg-table thead tr th {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  opacity: 1;
  padding: 15px;
  vertical-align: top;
  text-align: center;
  border-width: 1px;
  border-color: rgba(210, 210, 210, 0.4);
  font-family: "Saira", sans-serif;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .eg-table thead tr th {
    display: none;
  }
}
@media (max-width: 767px) {
  .eg-table tbody tr {
    border: 4px solid rgba(238, 238, 238, 0.75);
    border-radius: 0.25rem;
  }
}
.eg-table tbody tr td {
  font-weight: 400;
  color: #696969;
  text-align: center;
  border: 1px solid rgba(238, 238, 238, 0.75);
  vertical-align: middle;
  font-family: "Inter", sans-serif;
  font-size: 12px;
}
.eg-table tbody tr td .element {
  margin-top: 0;
  font-weight: bold;
}
@media (max-width: 767px) {
  .eg-table tbody tr td .element {
    margin-top: 5px;
  }
}
.eg-table tbody tr td .element.types-of-work-element {
  min-height: 70px;
  max-height: 70px;
  overflow-y: auto;
}
@media (max-width: 767px) {
  .eg-table tbody tr td .element.types-of-work-element {
    min-height: 0;
  }
}
.eg-table tbody tr td .element.localization-element {
  display: flex;
  flex-direction: column;
}
.eg-table tbody tr td .mobile-title {
  font-weight: bold;
}
.eg-table tbody tr td .badge {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  margin-bottom: 2px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .eg-table tbody tr td {
    display: block;
    width: 100%;
    text-align: left;
    padding-left: 50%;
    position: relative;
  }
}
.eg-table tbody tr td img {
  max-width: 40px;
  width: 100%;
}
@media (max-width: 767px) {
  .eg-table tbody tr {
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .eg-table tbody {
    display: block;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .eg-table {
    display: block;
    width: 100%;
  }
}
.eg-table.my-fuchy-table .badge-items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.eg-table.my-fuchy-table .badge-items .badge {
  display: block;
  height: 20px;
  margin-right: 3px;
  width: fit-content;
  width: -moz-fit-content;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 30px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  background-color: #fff;
}
@media (max-width: 767px) {
  .table-wrapper {
    box-shadow: unset;
  }
}
@media (max-width: 576px) {
  .table-wrapper {
    padding-right: 10px;
  }
}
.table-wrapper .bg-primary {
  background-color: #198754 !important;
}
.table-wrapper .bg-success {
  background-color: #f9395f !important;
}
.table-wrapper .order-table tr td {
  padding: 10px;
}

.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.table-pagination P {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  margin-right: 10px;
}
@media (max-width: 576px) {
  .table-pagination P {
    margin-bottom: 1rem;
  }
}

.nav-btn-style {
  background: #fff;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  width: 100%;
  min-width: 0px;
  color: #696969;
  font-size: 20px;
  font-weight: 500;
  color: #696969;
  font-family: "Saira", sans-serif;
  padding: 10px 20px;
  text-align: left;
  transition: all 0.42s ease;
}
.nav-btn-style svg {
  margin-right: 10px;
  fill: #696969;
  transition: all 0.42s ease;
}
.nav-btn-style:hover {
  background-color: #f9395f;
  color: #fff;
}
.nav-btn-style:hover svg {
  fill: #fff;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f9395f;
}
.nav-pills .nav-link.active svg,
.nav-pills .show > .nav-link svg {
  fill: #fff;
}

.my-profile-container .header {
  background: #1f2230;
  border-radius: 4px 4px 0px 0px;
  padding: 22px 25%;
}
.my-profile-container .header h4 {
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}

.dashboard-profile {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  background-color: #fff;
  padding: 30px;
}
@media (max-width: 576px) {
  .dashboard-profile {
    padding: 10px;
  }
}
.dashboard-profile .inactive-account-info {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #696969;
  text-align: left;
}
@media (max-width: 576px) {
  .dashboard-profile .payment-status-account .icon {
    display: none;
  }
}
.dashboard-profile .payment-status-account .para {
  line-height: 20px;
  margin-bottom: 0;
}
.dashboard-profile .payment-status-account .para.warning-info {
  font-size: 13px;
  font-weight: 500 !important;
  max-width: unset !important;
  padding-right: 50px;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .dashboard-profile .payment-status-account .para.warning-info {
    padding-right: 0;
  }
}
@media (max-width: 576px) {
  .dashboard-profile .payment-status-account .para.warning-info {
    font-size: 12px !important;
    line-height: 18px !important;
    padding-right: 5px;
  }
}
@media (max-width: 991px) {
  .dashboard-profile .check-profile-btn-container {
    text-align: center;
  }
  .dashboard-profile .check-profile-btn-container .check-profile-btn-container:active .check-profile {
    display: block;
    opacity: 1;
  }
}
.dashboard-profile .upload-container .form-section-title p {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #696969;
  font-family: "Saira", sans-serif;
  text-align: center;
}
.dashboard-profile .form-wrapper {
  padding: 0;
}
.dashboard-profile .form-section-title {
  --c: linear-gradient(#f9395f 0 0); /* update the color here */
  background: var(--c), var(--c);
  background-size: 10rem 2px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  transition: 0.3s linear, background-size 0.3s 0.2s linear;
  margin-top: 15px;
  margin-bottom: 40px;
}
.dashboard-profile .form-section-title p {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #696969;
  font-weight: bold;
}
.dashboard-profile .form-section-title:hover {
  background-size: 40% 2px;
  background-position: 10% 100%, 90% 100%;
}
.dashboard-profile .form-wrapper {
  box-shadow: unset;
}
.dashboard-profile .form-wrapper .marketing-notification-form input[type=checkbox] + label:before {
  margin-left: 0;
}
.dashboard-profile .form-wrapper .password-error {
  margin-top: -13px;
  margin-bottom: 10px;
  height: 10px;
}
.dashboard-profile .form-wrapper .password-error .error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.dashboard-profile .form-wrapper .select-placeholder-text {
  font-size: 15px;
  display: flex;
  font-family: "Inter", sans-serif;
}
.dashboard-profile .form-wrapper .address-form-inner__value-container {
  height: 54px;
  padding: 0 8px;
}
.dashboard-profile .form-wrapper .address-form-inner__control {
  border-radius: 0.25rem;
}
.dashboard-profile .form-wrapper .form-inner__value-container--has-value {
  padding: 0 8px;
}
.dashboard-profile .form-wrapper .form-inner__single-value {
  font-size: 15px;
}
.dashboard-profile .form-wrapper .form-inner__menu {
  z-index: 9999;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  max-width: 906px;
  margin: auto;
}
@media (max-width: 576px) {
  .dashboard-profile .form-wrapper .form-inner__menu {
    font-size: 14px;
  }
}
.dashboard-profile .form-wrapper .form-inner__control {
  border: 1px solid #d6d3d3;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  max-width: 906px;
  margin: auto;
}
.dashboard-profile .form-wrapper .form-inner__control .form-inner__input-container {
  padding: 0;
  margin: 0;
  height: 50px;
}
.dashboard-profile .form-inner {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  padding: 0 !important;
}
@media (max-width: 576px) {
  .dashboard-profile .form-inner {
    font-size: 14px;
  }
}
.dashboard-profile .form-inner label {
  color: #696969;
  font-family: "Saira", sans-serif;
  font-weight: bold;
}
.dashboard-profile .bio-container textarea {
  width: 100%;
  padding: 10px 8px;
  resize: none;
  border: 1px solid #d6d3d3;
  border-radius: 0.25rem;
  overflow: auto;
  min-height: 200px;
  font-size: 15px;
  position: relative;
  z-index: 999;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .dashboard-profile .bio-container textarea {
    font-size: 14px;
  }
}
.dashboard-profile .bio-container textarea::-moz-placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
.dashboard-profile .bio-container textarea::placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .dashboard-profile .bio-container textarea::-moz-placeholder {
    font-size: 14px;
  }
  .dashboard-profile .bio-container textarea::placeholder {
    font-size: 14px;
  }
}
.dashboard-profile .bio-container textarea::-webkit-scrollbar {
  display: none;
}
.dashboard-profile .owner {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  padding: 40px 40px 0px 40px;
}
@media (max-width: 576px) {
  .dashboard-profile .owner {
    flex-direction: column;
    align-items: center;
  }
}
.dashboard-profile .owner .image {
  margin-right: 25px;
}
@media (max-width: 576px) {
  .dashboard-profile .owner .image {
    margin-right: 0;
    margin-bottom: 25px;
  }
}
.dashboard-profile .owner .image img {
  max-width: 100px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
@media (max-width: 576px) {
  .dashboard-profile .owner .content {
    margin-bottom: 25px;
  }
}
.dashboard-profile .owner .content h3 {
  font-size: 30px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 5px;
}
.dashboard-profile .owner .content p {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  font-family: "Inter", sans-serif;
  margin-bottom: 0px;
}

.summary-dashboard-container .summary-status-account .para {
  display: none;
}

.dashboard-card {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
}
.dashboard-card:hover .body .icon svg {
  fill: #f9395f;
}
.dashboard-card .header {
  background: #1f2230;
  border-radius: 4px 4px 0px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-card .header h5 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}
.dashboard-card .header i {
  color: #fff;
  cursor: default;
}
.dashboard-card .body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 25px;
}
.dashboard-card .body .counter-item h2 {
  font-size: 30px;
  font-weight: 600;
  color: #1f2230;
}
@media (max-width: 576px) {
  .dashboard-card .body .counter-item h2 {
    font-size: 23px;
  }
}
.dashboard-card .body .counter-item .para {
  font-weight: bold;
}
@media (max-width: 576px) {
  .dashboard-card .body .counter-item .para {
    max-width: 125px;
    font-size: 14px;
    line-height: 22px;
  }
}
.dashboard-card .body .icon svg {
  fill: #696969;
  transition: all 0.42s ease;
}
.dashboard-card .body.days-left .counter-item {
  display: flex;
  align-items: center;
  height: 45px;
}
.dashboard-card .body.days-left .counter-item .para {
  margin-bottom: -3px;
  margin-left: 12px;
  font-family: "Saira", sans-serif;
}
@media (max-width: 576px) {
  .dashboard-card .body.days-left .counter-item .para {
    margin-bottom: 0;
  }
}
.dashboard-card .body.payment-body {
  padding: 0;
}
.dashboard-card .body.payment-body .card-body {
  padding: 35px;
}
@media (max-width: 576px) {
  .dashboard-card .body.payment-body .card-body {
    padding: 25px;
  }
}
.dashboard-card .body.payment-body .account-info-text {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  margin: 10px auto;
  color: #f9395f;
  max-width: 250px;
}
@media (max-width: 767px) {
  .dashboard-card .body.payment-body .payment-btn {
    max-width: 200px;
    font-size: 14px;
    padding: 14px 32px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .dashboard-card .body.payment-body .payment-btn {
    max-width: 200px;
    font-size: 14px;
    padding: 14px 32px;
  }
}
@media (max-width: 576px) {
  .dashboard-card .body.payment-body h3 {
    font-size: 20px;
    padding: 10px;
    margin-bottom: 0 !important;
  }
}
.dashboard-card .body.payment-body .para {
  font-weight: bold;
}
@media (max-width: 576px) {
  .dashboard-card .body.payment-body .para {
    font-size: 14px;
    line-height: 22px;
    padding: 10px;
  }
}
@media (max-width: 576px) {
  .dashboard-card .body.payment-body .container {
    padding: 0;
  }
}
.dashboard-card .body.payment-history-body .table-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.dashboard-card .body.payment-history-body .table-wrapper .para {
  font-weight: bold;
  margin-bottom: 0;
  padding: 20px;
}
.dashboard-card .body.verification-phone-number .para {
  font-weight: bold;
}
@media (max-width: 576px) {
  .dashboard-card .body.verification-phone-number .para {
    font-size: 14px;
    line-height: 22px;
    padding: 10px;
  }
}

.button-group {
  margin-top: 30px;
}
.button-group .profile-btn {
  border-radius: 30px;
  width: 200px;
  border: none;
  outline: none;
  padding: 13px 20px;
  background-color: #f9395f;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  transition: all 0.42s ease-in-out;
  border: 1px solid #f9395f;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 40px;
}
.button-group .profile-btn.disabled-button {
  background: #ccc;
  border: 1px solid #ccc;
}
.button-group .profile-btn.disabled-button:hover {
  background: #ccc;
  color: #fff;
}
.button-group .profile-btn:hover {
  background-color: #fff;
  color: #f9395f;
}
.button-group .cancel-btn {
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 13px 30px;
  color: #1f2230;
  background-color: #fff;
  border: 1px solid #eeeeee;
  transition: all 0.42s ease-in-out;
  text-align: center;
}
.button-group .cancel-btn:hover {
  background-color: #f9395f;
  color: #fff;
  border: 1px solid #f9395f;
}
.button-group .profile-tab {
  background-color: #fff;
  color: #f9395f;
  border: 1px solid #f9395f;
  border: none;
  outline: none;
  padding: 13px 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  transition: all 0.42s ease-in-out;
  border: 1px solid #f9395f;
  margin-right: 20px;
  text-align: center;
  width: 100%;
}
.button-group .profile-tab-icon {
  position: absolute;
}

.create-profile-tab-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.create-profile-tab-content .filepond--wrapper {
  padding: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .create-profile-tab-content .create-profile h3 {
    font-size: 21px;
  }
}
@media (max-width: 991px) {
  .create-profile-tab-content .create-profile h3 {
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .create-profile-tab-content .create-profile .para {
    font-size: 14px;
    line-height: 23px;
  }
}
@media (max-width: 991px) {
  .create-profile-tab-content .create-profile .para {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .create-profile-tab-content .create-profile .para {
    display: none;
  }
}
@media (max-width: 991px) {
  .create-profile-tab-content .create-profile .about-img-area .about-img {
    display: none;
  }
}
@media (max-width: 991px) {
  .create-profile-tab-content .create-profile .about-img-area .about-vector {
    display: none;
  }
}

.create-profile-form-wrapper {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 100%;
}
.create-profile-form-wrapper .bio-container textarea {
  width: 100%;
  padding: 10px 8px;
  resize: none;
  border: 1px solid #d6d3d3;
  border-radius: 0.25rem;
  overflow: auto;
  min-height: 300px;
  font-size: 16px;
  position: relative;
  z-index: 999;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .bio-container textarea {
    font-size: 14px;
  }
}
.create-profile-form-wrapper .bio-container textarea::-moz-placeholder {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
.create-profile-form-wrapper .bio-container textarea::placeholder {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .bio-container textarea::-moz-placeholder {
    font-size: 14px;
  }
  .create-profile-form-wrapper .bio-container textarea::placeholder {
    font-size: 14px;
  }
}
.create-profile-form-wrapper .bio-container textarea::-webkit-scrollbar {
  display: none;
}
.create-profile-form-wrapper .province-multiselect__control,
.create-profile-form-wrapper .county-multiselect__control,
.create-profile-form-wrapper .types-of-work-multiselect__control {
  border-radius: 0.25rem;
  z-index: 999;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .province-multiselect__control,
  .create-profile-form-wrapper .county-multiselect__control,
  .create-profile-form-wrapper .types-of-work-multiselect__control {
    font-size: 14px;
  }
}
.create-profile-form-wrapper .province-multiselect__menu {
  z-index: 9999;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .province-multiselect__menu {
    font-size: 14px;
  }
}
.create-profile-form-wrapper .county-multiselect__menu {
  z-index: 999;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .county-multiselect__menu {
    font-size: 14px;
  }
}
.create-profile-form-wrapper .types-of-work-multiselect__menu {
  z-index: 999;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .types-of-work-multiselect__menu {
    font-size: 14px;
  }
}
.create-profile-form-wrapper .province-multiselect {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
.create-profile-form-wrapper .upload-container {
  height: 460px;
  overflow: auto;
}
.create-profile-form-wrapper .progress {
  width: 100%;
  margin-bottom: 50px;
  height: 5px;
  border-radius: 0.25rem;
  position: relative;
  z-index: 999;
}
.create-profile-form-wrapper .progress .progress-bar {
  background-color: #f9395f;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .controllers-btn-box,
  .create-profile-form-wrapper .controllers-btn-container {
    flex-direction: column;
    align-items: center;
  }
}
.create-profile-form-wrapper .controllers-btn-box button,
.create-profile-form-wrapper .controllers-btn-container button {
  width: 210px;
  margin-right: 10px;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .controllers-btn-box button,
  .create-profile-form-wrapper .controllers-btn-container button {
    margin-bottom: 10px;
  }
}
.create-profile-form-wrapper .company-name-container .company-name-input {
  width: 100%;
  border: none;
  outline: none;
  padding: 12px 30px;
  margin: 20px 0;
  border: 1px solid rgb(190, 190, 190);
  transition: all 0.42s ease;
  border-radius: 0.25rem;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  position: relative;
  z-index: 999;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .company-name-container .company-name-input {
    height: 50px;
    font-size: 14px;
    padding: 7px 10px;
  }
}
.create-profile-form-wrapper .company-name-container .company-name-input::-moz-placeholder {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
.create-profile-form-wrapper .company-name-container .company-name-input::placeholder {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .company-name-container .company-name-input::-moz-placeholder {
    font-size: 14px;
  }
  .create-profile-form-wrapper .company-name-container .company-name-input::placeholder {
    font-size: 14px;
  }
}
.create-profile-form-wrapper .company-name-container .error-message {
  position: absolute;
  bottom: -12px;
  left: 2px;
}
.create-profile-form-wrapper .company-tax-id-container .company-tax-id-input {
  width: 100%;
  border: none;
  outline: none;
  padding: 12px 30px;
  margin: 20px 0;
  border: 1px solid rgb(190, 190, 190);
  transition: all 0.42s ease;
  border-radius: 0.25rem;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  position: relative;
  z-index: 999;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .company-tax-id-container .company-tax-id-input {
    height: 50px;
    font-size: 14px;
    padding: 7px 10px;
  }
}
.create-profile-form-wrapper .company-tax-id-container .company-tax-id-input::-moz-placeholder {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
.create-profile-form-wrapper .company-tax-id-container .company-tax-id-input::placeholder {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .company-tax-id-container .company-tax-id-input::-moz-placeholder {
    font-size: 14px;
  }
  .create-profile-form-wrapper .company-tax-id-container .company-tax-id-input::placeholder {
    font-size: 14px;
  }
}
.create-profile-form-wrapper .company-tax-id-container .tax-id-error-message {
  position: absolute;
  bottom: -12px;
  left: 2px;
  color: red;
  font-size: 12px;
  font-family: "Inter", sans-serif;
}
.create-profile-form-wrapper .explanation-text {
  margin-top: -20px;
  line-height: normal;
}
.create-profile-form-wrapper .explanation-text p {
  font-size: 14px;
  color: rgba(105, 105, 105, 0.6392156863);
  font-family: "Inter", sans-serif;
  z-index: 999;
  position: relative;
  line-height: 20px;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .explanation-text p {
    font-size: 12px;
  }
}
.create-profile-form-wrapper .loading-text {
  max-width: 600px;
  position: relative;
  z-index: 999;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .loading-text {
    margin-bottom: 30px;
  }
}
.create-profile-form-wrapper .loading-text .text-container-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.create-profile-form-wrapper .loading-text .text-container-1 .text-1 {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .loading-text .text-container-1 .text-1 {
    font-size: 15px;
  }
}
.create-profile-form-wrapper .loading-text .text-container-1 .text-1 span {
  margin-left: 5px;
  margin-right: 5px;
}
.create-profile-form-wrapper .loading-text .text-container-1 .text-normal {
  color: #696969;
}
.create-profile-form-wrapper .loading-text .text-container-1 .icon-success {
  margin-left: 10px;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-profile-form-wrapper .loading-text .text-container-2 {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.create-profile-form-wrapper .loading-text .text-container-2 .text-2 {
  text-align: left;
  font-weight: bold;
  font-size: 12px !important;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .loading-text .text-container-2 .text-2 {
    font-size: 12px !important;
    line-height: 15px !important;
  }
}
.create-profile-form-wrapper .upload-container {
  height: 500px;
  overflow-y: auto;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .upload-container {
    height: 400px;
  }
}
.create-profile-form-wrapper .upload-container .filepond--wrapper {
  padding: 20px;
}
.create-profile-form-wrapper form {
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  width: 100%;
  min-height: 400px;
}
.create-profile-form-wrapper h2 {
  font-size: 28px;
  margin: 10px 0;
  text-align: center;
}
.create-profile-form-wrapper p {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 23px;
  text-align: center;
  color: #696969;
  font-weight: bold;
  font-family: "Saira", sans-serif;
  position: relative;
  z-index: 999;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper p {
    font-size: 18px;
    line-height: 30px;
  }
}
.create-profile-form-wrapper .inputGroup {
  background-color: #fff;
  display: block;
  margin: 20px 0;
  position: relative;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 0.25rem;
  width: 100%;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .inputGroup {
    margin: 5px 0;
  }
}
.create-profile-form-wrapper .inputGroup label {
  padding: 12px 30px;
  width: 100%;
  height: 62px;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  border-radius: 0.25rem;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
@media (max-width: 576px) {
  .create-profile-form-wrapper .inputGroup label {
    font-size: 14px;
    height: 50px;
    padding: 7px 10px;
  }
}
.create-profile-form-wrapper .inputGroup label:before {
  width: 24px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #f9395f;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.create-profile-form-wrapper .inputGroup label:after {
  width: 32px;
  height: 32px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}
.create-profile-form-wrapper .inputGroup input:checked ~ label {
  color: #fff;
}
.create-profile-form-wrapper .inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.create-profile-form-wrapper .inputGroup input:checked ~ label:after {
  background-color: #f9395f;
  border-color: #f9395f;
}
.create-profile-form-wrapper .inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
  border-radius: 0.25rem;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.button-container button {
  padding: 10px;
}
.button-container button:disabled {
  background: #ccc;
}
.button-container button:disabled::after {
  background: #ccc;
}
.button-container button:disabled::before {
  background: #ccc;
}

.urlButton {
  margin: 0;
  border-radius: 10px;
  border-style: solid;
  border-radius: 5px;
  border-color: #c9c5c5;
  border-width: 0.2em;
  z-index: 1;
}

.dropbtn {
  background-color: #e8ecf4;
  color: #9fa5b1;
  border-style: none;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: small;
}

@media screen and (max-width: 725px) {
  .dropbtn {
    width: 70%;
  }
  .urlInput {
    width: 200px;
  }
}
.clicked {
  border-color: #eaeef5;
  cursor: pointer;
  text-align: left;
}

.clicked:hover {
  border-color: #664de5;
}

.clicked:active {
  border-color: #664de5;
}

@media screen and (max-width: 1228px) {
  .selectionDiv {
    flex-wrap: wrap;
  }
  .clicked {
    min-width: 200px;
  }
}
@media screen and (max-width: 800px) {
  .submitButton {
    min-width: 100%;
  }
}
.create-profile-modal .modal-body,
.create-fuche-modal .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-profile-modal .modal-header,
.create-fuche-modal .modal-header {
  border-bottom: none;
}
.create-profile-modal .client-right-vector,
.create-fuche-modal .client-right-vector {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
@media (max-width: 767px) {
  .create-profile-modal .client-right-vector,
  .create-fuche-modal .client-right-vector {
    display: none;
    visibility: hidden;
  }
}
.create-profile-modal .client-left-vector,
.create-fuche-modal .client-left-vector {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
@media (max-width: 767px) {
  .create-profile-modal .client-left-vector,
  .create-fuche-modal .client-left-vector {
    display: none;
    visibility: hidden;
  }
}
.create-profile-modal .client-circle1,
.create-fuche-modal .client-circle1 {
  position: absolute;
  right: 14%;
  bottom: 30px;
  animation: fluid 5s linear infinite alternate;
  z-index: 0;
}
.create-profile-modal .client-circle2,
.create-fuche-modal .client-circle2 {
  position: absolute;
  left: 10%;
  top: 10%;
  animation: fluid 5s linear infinite alternate;
  animation-delay: 2s;
  z-index: 0;
}
.create-profile-modal .client-circle3,
.create-fuche-modal .client-circle3 {
  position: absolute;
  left: 30%;
  bottom: 30px;
  animation: fluid 5s linear infinite alternate;
  animation-delay: 2s;
  z-index: 0;
}
.create-profile-modal .client-circle4,
.create-fuche-modal .client-circle4 {
  position: absolute;
  left: 8%;
  bottom: 30px;
  animation: fluid 5s linear infinite alternate;
  animation-delay: 2s;
  z-index: 0;
}
.create-profile-modal .client-circle5,
.create-fuche-modal .client-circle5 {
  position: absolute;
  right: 14%;
  bottom: 30px;
  animation: fluid 5s linear infinite alternate;
  z-index: 0;
}

.create-profile-container {
  padding: 0 !important;
  height: 100%;
  z-index: 9999;
}
@media (max-width: 576px) {
  .create-profile-container {
    flex-direction: column;
    align-items: center;
  }
}
.create-profile-container::-webkit-scrollbar {
  display: none;
}
.create-profile-container .modal-dialog {
  height: 100%;
  width: 100%;
  margin: 0;
  max-width: 100%;
}
.create-profile-container .modal-dialog .modal-content .create-profile-modal {
  height: 100%;
}
.create-profile-container .modal-dialog .modal-content .create-profile-modal .modal-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9999;
}
.create-profile-container .modal-dialog .modal-content .create-profile-modal .modal-body {
  overflow-y: auto;
  min-height: 100dvh;
}
@media (max-width: 576px) {
  .create-profile-container .modal-dialog .modal-content .create-profile-modal .modal-body {
    display: flex;
  }
}
.create-profile-container .modal-dialog .modal-content .create-profile-modal .modal-body::-webkit-scrollbar {
  display: none;
}
@media (max-width: 576px) {
  .create-profile-container .modal-dialog .modal-content .create-profile-modal .modal-body .create-profile-body-container {
    width: 100%;
  }
}

.create-fucha-modal-container {
  padding: 0 !important;
  height: 100%;
  z-index: 9999;
}
@media (max-width: 576px) {
  .create-fucha-modal-container {
    flex-direction: column;
    align-items: center;
  }
}
.create-fucha-modal-container::-webkit-scrollbar {
  display: none;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .controllers-btn-box,
  .create-fucha-modal-container .controllers-btn-container {
    flex-direction: column;
    align-items: center;
  }
}
.create-fucha-modal-container .controllers-btn-box button,
.create-fucha-modal-container .controllers-btn-container button {
  width: 210px;
  margin-right: 10px;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .controllers-btn-box button,
  .create-fucha-modal-container .controllers-btn-container button {
    margin-bottom: 10px;
  }
}
.create-fucha-modal-container .modal-dialog {
  height: 100%;
  width: 100%;
  margin: 0;
  max-width: 100%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal {
  height: 100%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .client-circle3 {
  left: 10%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .client-circle1 {
  right: 10%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-header {
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: #fff;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body {
  overflow-y: auto;
  min-height: 100dvh;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body::-webkit-scrollbar {
  display: none;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container {
    max-width: 340px;
    padding: 10px;
  }
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container .sn {
    left: 9px;
    top: 15px;
  }
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container .summary-content .content-title {
    margin-bottom: 15px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container .summary-content .content-item-container .title-box {
  min-width: 200px;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container .summary-content .content-item-container .para {
  text-align: left;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container .summary-content .description-container {
  justify-content: flex-start;
  align-items: flex-start;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container .summary-content .description-container .fucha-description {
  max-height: 300px !important;
  min-height: 100px !important;
  overflow-y: auto;
  flex-direction: column;
  text-align: left;
  padding-right: 10px;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container .summary-content .description-container .fucha-description .para {
    font-size: 14px;
    line-height: 25px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container {
  height: 800px;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container {
    height: 750px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .content-title {
  margin-top: 0;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .content-title {
    margin-top: -50px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container {
  display: block;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .image-gallery-right-nav,
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .image-gallery-left-nav {
  padding: 0;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .image-gallery-right-nav svg,
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .image-gallery-left-nav svg {
  height: 70px !important;
  width: 70px !important;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .image-gallery-fullscreen-button {
  padding: 0;
  background: rgba(255, 255, 255, 0.1450980392);
  border-radius: 0.25rem;
  right: 5px;
  bottom: 10px;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .image-gallery-fullscreen-button svg {
  height: 30px !important;
  width: 30px !important;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container {
  height: 500px;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container {
    width: 100%;
    margin: auto;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content {
  height: 100%;
  width: 100%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content .image-gallery-slide-wrapper {
  height: 100%;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content .image-gallery-slide-wrapper {
    width: 100%;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe {
  height: 100%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide,
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slides {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slides .image-gallery-slide,
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slides .image-gallery-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-thumbnail {
  height: 80px;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-thumbnail span {
  height: 100%;
  width: 100%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .summary-container.gallery-summary-container .summary-content .gallery-content-container .summary-fucha-gallery-container .image-gallery-thumbnail span img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
     object-fit: cover !important;
  -o-object-position: center;
     object-position: center;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .explanation-text p {
  font-size: 14px;
  color: rgba(105, 105, 105, 0.6392156863);
  font-family: "Inter", sans-serif;
  z-index: 999;
  position: relative;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .explanation-text p {
    font-size: 12px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .create-fuche-form-wrapper .extra-button::after,
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .create-fuche-form-wrapper .extra-button::before {
  background: #b19ea2;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .select-placeholder-text {
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .select-placeholder-text {
    font-size: 14px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper {
  box-shadow: none;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper {
    padding: 25px 0;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form {
  min-height: 700px;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form {
    min-height: 200px;
  }
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .pb-120 {
    padding: 0 !important;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .text-counter {
  position: relative;
  z-index: 999;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .progress-bar-container .progress {
  width: 100%;
  margin: auto;
  height: 5px;
  border-radius: 0.25rem;
  position: relative;
  z-index: 999;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .progress-bar-container .progress-bar {
  background-color: #f9395f;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .upload-container {
  height: 600px;
  overflow-y: auto;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .upload-container {
    height: 400px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .upload-container .filepond--wrapper {
  padding: 20px;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text {
  max-width: 600px;
  position: relative;
  z-index: 999;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text {
    margin-bottom: 30px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text .text-container-1 {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text .text-container-1 .text-1 {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text .text-container-1 .text-1 {
    font-size: 15px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text .text-container-1 .text-1 span {
  margin-left: 5px;
  margin-right: 5px;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text .text-container-1 .text-normal {
  color: #696969;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text .text-container-1 .icon-success {
  margin-left: 10px;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text .text-container-2 {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text .text-container-2 .text-2 {
  font-weight: bold;
  font-size: 14px !important;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .loading-text .text-container-2 .text-2 {
    margin-bottom: 20px;
    font-size: 12px !important;
    line-height: 15px !important;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-section-title {
  width: 100%;
  display: flex;
  margin-top: 40px;
  margin-bottom: 10px;
  position: relative;
  z-index: 999;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-section-title {
    margin-top: 20px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-section-title p {
  text-transform: uppercase;
  color: #696969;
  font-size: 17px;
  margin: 0;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: "Saira", sans-serif;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-section-title p {
    font-size: 15px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .data-box {
  margin-top: 5px;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .data-box p {
  text-transform: uppercase;
  font-family: "Saira", sans-serif;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-inner {
  padding: 0;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-inner input {
  height: 50px;
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-inner input {
    font-size: 14px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-inner input::-moz-placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-inner input::placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-inner input::-moz-placeholder {
    font-size: 14px;
  }
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .form-inner input::placeholder {
    font-size: 14px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .bio-container textarea {
  width: 100%;
  padding: 10px 8px;
  resize: none;
  border: 1px solid #d6d3d3;
  border-radius: 0.25rem;
  overflow: auto;
  min-height: 300px;
  font-size: 15px;
  position: relative;
  z-index: 999;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .bio-container textarea {
    font-size: 14px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .bio-container textarea::-moz-placeholder {
  font-size: 15px;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .bio-container textarea::placeholder {
  font-size: 15px;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .bio-container textarea::-moz-placeholder {
    font-size: 14px;
  }
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .bio-container textarea::placeholder {
    font-size: 14px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .bio-container textarea::-webkit-scrollbar {
  display: none;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .react-datepicker-wrapper {
  width: 100%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form .react-datepicker-popper {
  z-index: 1000;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateTo]::-moz-placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateTo]::placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateTo]::-moz-placeholder {
    font-size: 14px;
  }
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateTo]::placeholder {
    font-size: 14px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateTo]:disabled::-moz-placeholder {
  color: rgba(105, 105, 105, 0.43);
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateTo]:disabled::placeholder {
  color: rgba(105, 105, 105, 0.43);
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateFrom]::-moz-placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateFrom]::placeholder {
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateFrom]::-moz-placeholder {
    font-size: 14px;
  }
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateFrom]::placeholder {
    font-size: 14px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateFrom]:disabled::-moz-placeholder {
  color: rgba(105, 105, 105, 0.43);
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper #create-fucha-form input[name=realizationDateFrom]:disabled::placeholder {
  color: rgba(105, 105, 105, 0.43);
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper .inputGroup {
  background-color: #fff;
  display: block;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 0.25rem;
  width: 100%;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper .inputGroup label {
  padding: 12px 10px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #696969;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  border-radius: 0.25rem;
  margin-bottom: -1px;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: normal;
}
@media (max-width: 576px) {
  .create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper .inputGroup label {
    font-size: 14px;
  }
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper .inputGroup label:before {
  width: 100px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #f9395f;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper .inputGroup label:after {
  width: 30px !important;
  height: 30px !important;
  content: "" !important;
  border: 2px solid #d1d7dc !important;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute !important;
  right: 14px;
  top: 50% !important;
  transform: translateY(-50%) !important;
  cursor: pointer;
  transition: all 200ms ease-in;
  left: unset !important;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper .inputGroup input:checked ~ label {
  color: #fff;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper .inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper .inputGroup input:checked ~ label:after {
  background-color: #f9395f;
  border-color: #f9395f;
}
.create-fucha-modal-container .modal-dialog .modal-content .create-fuche-modal .modal-body .form-wrapper .inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
  border-radius: 0.25rem;
}

@keyframes fluid {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(-50%);
  }
  75% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.dashboard-work-type .body .profile-icons {
  display: flex;
  width: 100px;
}
.dashboard-work-type .body .profile-icons img {
  height: 30px;
  cursor: pointer;
}
.dashboard-work-type .body .area-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
}
.dashboard-work-type .body .area-icon .profile-icons {
  display: block;
}
.dashboard-work-type .body .profile-work-type-container,
.dashboard-work-type .body .profile-area-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 0.25rem;
}
.dashboard-work-type .body .profile-work-type-container .work-box,
.dashboard-work-type .body .profile-area-container .work-box {
  display: flex;
  width: 100%;
}
.dashboard-work-type .body .profile-work-type-container .work-box .work-type,
.dashboard-work-type .body .profile-work-type-container .work-box .company-name,
.dashboard-work-type .body .profile-area-container .work-box .work-type,
.dashboard-work-type .body .profile-area-container .work-box .company-name {
  display: flex;
  width: 45%;
  padding-left: 10px;
}
.dashboard-work-type .body .profile-work-type-container .work-box p,
.dashboard-work-type .body .profile-area-container .work-box p {
  margin-right: 20px;
  margin-bottom: 0;
  font-family: "Saira", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.dashboard-work-type .body .profile-work-type-container ul,
.dashboard-work-type .body .profile-area-container ul {
  padding-left: 0;
}
.dashboard-work-type .body .profile-work-type-container li,
.dashboard-work-type .body .profile-area-container li {
  margin-bottom: 0;
  font-family: "Saira", sans-serif;
  letter-spacing: 1px;
  height: 40px;
  margin: 10px 0;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
  width: 100%;
  font-size: 14px;
  border-radius: 0.25rem;
  text-transform: capitalize;
  color: #696969;
}
.dashboard-work-type .body .profile-area-container {
  align-items: baseline;
}
.dashboard-work-type .body .profile-area-container .accordion {
  width: 45%;
}
.dashboard-work-type .body .profile-area-container .area-container {
  display: flex;
  width: 100%;
  gap: 10px;
}
.dashboard-work-type .body .profile-area-container .accordion-item {
  margin-bottom: 24px;
  border: none;
}
.dashboard-work-type .body .profile-area-container .accordion-item:last-child {
  margin-bottom: 0;
}
.dashboard-work-type .body .profile-area-container .accordion-button {
  font-weight: 500;
  font-size: 16px;
  background: #fff;
  border-radius: 0.25rem;
  color: #1f2230;
  padding-right: 60px;
  position: relative;
  transition: 0.4s ease-in;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  padding-left: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 767px) {
  .dashboard-work-type .body .profile-area-container .accordion-button {
    padding: 25px 60px 25px 25px;
  }
}
.dashboard-work-type .body .profile-area-container .accordion-button:hover::after {
  background: #f9395f;
  color: #fff;
}
.dashboard-work-type .body .profile-area-container .accordion-button:focus {
  z-index: unset;
  border-color: unset;
  outline: 0;
  background: #fff;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
}
.dashboard-work-type .body .profile-area-container .accordion-button::after {
  flex-shrink: 0;
  width: unset;
  height: unset;
  margin-left: auto;
  background-image: none;
  background-repeat: unset;
  background-size: unset;
  font-family: bootstrap-icons !important;
  font-size: 15px;
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  content: "\f282";
  transition: unset;
  font-size: 20px;
  color: #f9395f;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f9395f;
  transition: all 0.4s ease;
}
.dashboard-work-type .body .profile-area-container .accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: unset;
  font-family: bootstrap-icons !important;
  content: "\f286";
  background: #f9395f;
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
}
.dashboard-work-type .body .profile-area-container .accordion-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  border-top: none;
  text-align: left;
  padding: 0;
}
.dashboard-work-type .body .profile-area-container .accordion-button:not(.collapsed) {
  box-shadow: unset;
  color: #1f2230;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  background: #fff;
}

.my-fucha-list-item h5,
.my-fucha-list-item h6,
.my-fucha-list-item p {
  color: #696969 !important;
  font-family: "Saira", sans-serif;
}
.my-fucha-list-item .span-text {
  font-weight: bold;
  margin-right: 10px;
}
.my-fucha-list-item .info-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 25px;
  padding: 18px;
  border: 1px solid #fff;
}
.my-fucha-list-item .info-container .status-tag {
  text-transform: uppercase;
  color: #fff !important;
  letter-spacing: 1px;
}
.my-fucha-list-item .info-container .delete-fucha-icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
  transition: transform 500ms;
  border-radius: 0;
}
.my-fucha-list-item .info-container .delete-fucha-icon:is(:hover, :focus) {
  transform: scale(1.2);
}
.my-fucha-list-item .inactive {
  background-color: #b19ea2;
}
.my-fucha-list-item .active {
  background-color: #f9395f;
}
.my-fucha-list-item .btn-inactive::after {
  background-color: #b19ea2;
}
.my-fucha-list-item .btn-inactive::before {
  background-color: #b19ea2;
}
.my-fucha-list-item .finished {
  background-color: #57bf84;
}
.my-fucha-list-item .btn-finished::after {
  background-color: #57bf84;
}
.my-fucha-list-item .btn-finished::before {
  background-color: #57bf84;
}
.my-fucha-list-item .cancelled {
  background-color: #0a0a0f;
}
.my-fucha-list-item .btn-cancelled::after {
  background-color: #0a0a0f;
}
.my-fucha-list-item .btn-cancelled::before {
  background-color: #0a0a0f;
}
.my-fucha-list-item .my-fuchy-list-item {
  margin-bottom: 20px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.1);
  height: 230px;
  background-color: #fff;
  border-radius: 0px 0.25rem 0.25rem 0;
}
@media (max-width: 991px) {
  .my-fucha-list-item .my-fuchy-list-item {
    height: unset;
    border-radius: 0 0 0.25rem 0.25rem;
  }
}
.my-fucha-list-item .my-fuchy-list-item .text-part {
  border: none;
  border-radius: unset;
  height: 100%;
}
.my-fucha-list-item .my-fuchy-list-item .text-part .media-body {
  padding: 0 20px;
}
.my-fucha-list-item .my-fuchy-list-item .text-part .media-body h5 {
  font-size: 18px;
  margin-top: 10px;
}
.my-fucha-list-item .my-fuchy-list-item .my-fucha-image-container {
  height: 100%;
  width: 100%;
}
@media (max-width: 991px) {
  .my-fucha-list-item .my-fuchy-list-item .my-fucha-image-container {
    height: 170px;
    padding: 13px;
  }
}
.my-fucha-list-item .my-fuchy-list-item .my-fucha-image-container .fucha-image {
  border-radius: 0px 0.25rem 0.25rem 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 1000ms;
  height: 100%;
  width: 100%;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.1);
}
@media (max-width: 991px) {
  .my-fucha-list-item .my-fuchy-list-item .my-fucha-image-container .fucha-image {
    border-radius: 0.25rem;
  }
}

.summary-container {
  border: 1px solid #eeeeee;
  background: #fff;
  padding: 30px;
  position: relative;
  max-width: 880px;
  transition: all 0.42s ease;
  margin: auto;
}
.summary-container .summary-content {
  width: 100%;
}
.summary-container .summary-content .content-title {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  margin-top: 70px;
}
.summary-container .summary-content .content-item-container {
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}
@media (max-width: 576px) {
  .summary-container .summary-content .content-item-container {
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .summary-container .summary-content .content-item-container .para {
    font-size: 14px;
    line-height: 25px;
  }
}
.summary-container .summary-content .content-item-container .date-text {
  margin-right: 5px !important;
  font-weight: bold;
}
.summary-container .summary-content .content-item-container .date {
  margin-right: 10px;
}
.summary-container .summary-content .title-box {
  width: 200px;
  display: flex;
}
.summary-container .summary-content .title-box h5,
.summary-container .summary-content .title-box p {
  font-size: 18px;
  margin-right: 10px;
}
.summary-container .summary-content .description-container {
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100px;
  max-height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .summary-container .summary-content .description-container {
    align-items: baseline;
    justify-content: flex-start;
  }
}
.summary-container .summary-content .description-container .title-box {
  width: 200px;
  display: flex;
}
.summary-container .summary-content .description-container .title-box h5,
.summary-container .summary-content .description-container .title-box .para {
  font-size: 18px;
  margin-right: 10px;
}
.summary-container .summary-content .description-container.no-description-container {
  height: 100%;
}
@media (max-width: 1199px) {
  .summary-container .summary-content .description-container.no-description-container {
    height: unset;
  }
}
.summary-container .summary-content .description-container.no-description-container .description {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.summary-container .summary-content .description-container.no-description-container .description .para {
  font-weight: bold;
}
.summary-container.summary-text-container {
  border: 1px solid #eeeeee;
  width: 100%;
  text-align: left;
}
@media (max-width: 576px) {
  .summary-container.summary-text-container .warning-text {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .summary-container.summary-text-container .summary-text-box-steps {
    padding-left: 8px;
  }
}
.summary-container.summary-text-container .summary-text-box-steps .summary-text-box {
  justify-content: flex-start;
}
@media (max-width: 576px) {
  .summary-container.summary-text-container .summary-text-box-steps .summary-text-box {
    margin-bottom: 10px;
  }
}
.summary-container.summary-text-container .summary-text-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .summary-container.summary-text-container .summary-text-box {
    margin: 0;
  }
}
.summary-container.summary-text-container .summary-text-box p {
  font-size: 16px;
  font-weight: 400;
  color: #f9395f;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
  width: 100%;
}
@media (max-width: 576px) {
  .summary-container.summary-text-container .summary-text-box p {
    font-size: 14px;
    margin-left: 0 !important;
  }
}
.summary-container.summary-text-container .summary-text-box p span {
  color: #f9395f;
}
.summary-container.summary-text-container .summary-text-box p i {
  margin-right: 10px;
  color: #f9395f;
}
.summary-container.summary-text-container .summary-text-box p.additional-text {
  color: #696969 !important;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  margin-top: -18px;
  margin-left: 17px;
  max-width: 530px;
}
@media (max-width: 576px) {
  .summary-container.summary-text-container .summary-text-box p.additional-text {
    font-size: 12px;
    width: 100%;
  }
}
.summary-container.summary-text-container .summary-text-box p.additional-text a {
  color: #c71438;
}

/*================================================
  Blog details page
=================================================*/
.blog-details-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.blog-details-section .section-bg-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
}
@media (max-width: 767px) {
  .blog-details-section .section-bg-top {
    display: none;
    visibility: hidden;
  }
}
.blog-details-section .section-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -2;
}
@media (max-width: 767px) {
  .blog-details-section .section-bg-bottom {
    display: none;
    visibility: hidden;
  }
}

.blog-details-single .blog-img img {
  width: 100%;
}
.blog-details-single .blog-meta {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 35px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .blog-details-single .blog-meta {
    justify-content: space-between;
  }
}
.blog-details-single .blog-meta li {
  margin-right: 20px;
}
.blog-details-single .blog-meta a {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  font-family: "Inter", sans-serif;
}
.blog-details-single .blog-meta a img {
  margin-right: 10px;
}
.blog-details-single .blog-title {
  font-family: "Saira", sans-serif;
  font-size: 35px;
  font-weight: 600;
  line-height: 1.3;
  text-align: left;
}
.blog-details-single blockquote {
  background-color: #f1f1f1;
  padding: 30px 25px;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 30px;
}
.blog-details-single blockquote .quote-icon {
  margin-bottom: 15px;
}
.blog-details-single blockquote h5 {
  font-size: 22px;
  font-weight: 600;
  color: #1f2230;
}
.blog-details-single .sub-title {
  font-size: 24px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 10px;
}
.blog-details-single .blog-video-area {
  margin-top: 25px;
  margin-bottom: 25px;
}
.blog-details-single .blog-video-area img {
  border-radius: 5px;
}
.blog-details-single .blog-tag {
  border-bottom: 1px solid #eeeeee;
  padding: 20px 0px;
  margin-top: 35px;
  margin-bottom: 50px;
}
.blog-details-single .blog-tag h6 {
  font-size: 16px;
  font-weight: 400;
  color: #696969;
  margin-bottom: 0px;
  margin-right: 10px;
  white-space: nowrap;
}
.blog-details-single .blog-tag .tag-list {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 600;
}
.blog-details-single .blog-tag .tag-list li {
  margin-right: 5px;
  text-decoration: underline;
}
.blog-details-single .blog-tag .tag-list li a {
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
  transition: all 0.42s ease;
  white-space: nowrap;
}
.blog-details-single .blog-tag .tag-list li a:hover {
  color: #f9395f;
}
.blog-details-single .blog-tag .share-social {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-details-single .blog-tag .share-social .bx,
.blog-details-single .blog-tag .share-social .bi {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  text-align: center;
  transition: all 0.42s ease-out 0s;
}
.blog-details-single .blog-tag .share-social .bx:hover,
.blog-details-single .blog-tag .share-social .bi:hover {
  background-color: #f9395f;
  color: #fff;
}
.blog-details-single .blog-author {
  background: #f1f1f1;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 50px;
}
.blog-details-single .blog-author .author-image img {
  min-width: 140px;
}
.blog-details-single .blog-author .author-detials h5 {
  font-size: 22px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 10px;
}

.video-play {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-play .video-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 52px;
  display: inline-block;
  text-align: center;
  position: relative;
  border: 1px solid #fff;
  font-size: 36px;
  color: #f9395f;
  background: #fff;
}
.video-play .video-icon::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  animation: ripple-1 2s infinite ease-in-out;
  z-index: -1;
}
.video-play .video-icon::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  z-index: -1;
  animation: ripple-2 2s infinite ease-in-out;
  animation-delay: 0.5s;
}

@keyframes ripple-1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
@keyframes ripple-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.9);
    opacity: 0;
  }
}
.blog-widget-title {
  margin-bottom: 30px;
}
.blog-widget-title h4 {
  font-size: 25px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
}
.blog-widget-title span {
  width: 50px;
  height: 3px;
  background: #f9395f;
  border-radius: 5px;
  display: block;
  position: relative;
  margin-top: 12px;
}
.blog-widget-title span::before {
  content: "";
  width: 5px;
  height: 3px;
  background: #f9395f;
  border-radius: 5px;
  position: absolute;
  left: 55px;
  top: 0;
}
.blog-widget-title span::after {
  content: "";
  width: 5px;
  height: 3px;
  background: #f9395f;
  border-radius: 5px;
  position: absolute;
  left: 65px;
  top: 0;
}
.blog-widget-title.style2 h4 {
  margin-bottom: 0;
}

.sidebar-widget-title {
  margin-bottom: 25px;
}
.sidebar-widget-title h4 {
  font-size: 25px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
}
.sidebar-widget-title span {
  width: 50px;
  height: 3px;
  background: #f9395f;
  border-radius: 5px;
  display: block;
  position: relative;
  margin-top: 5px;
}
.sidebar-widget-title span::before {
  content: "";
  width: 5px;
  height: 3px;
  background: #f9395f;
  border-radius: 5px;
  position: absolute;
  left: 55px;
  top: 0;
}
.sidebar-widget-title span::after {
  content: "";
  width: 5px;
  height: 3px;
  background: #f9395f;
  border-radius: 5px;
  position: absolute;
  left: 65px;
  top: 0;
}

.comment-list {
  padding: 0;
  margin: 0;
}
.comment-list > li {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
}
.comment-list > li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.blog-sidebar {
  width: 100%;
  height: 100%;
}
.blog-sidebar .sidebar-banner {
  background-image: linear-gradient(90deg, rgba(31, 34, 48, 0.75), rgba(31, 34, 48, 0.75)), url("../../public/images//blog/banner-card-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.blog-sidebar .sidebar-banner span {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  display: block;
  margin-bottom: 5px;
}
.blog-sidebar .sidebar-banner h3 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  font-family: "Saira", sans-serif;
  line-height: 1.4;
  margin-bottom: 30px;
}
.blog-sidebar .sidebar-banner .card--btn {
  padding: 9px 35px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.comment-box .author {
  display: flex;
  justify-content: start;
  align-items: center;
}
.comment-box .author img {
  width: 100%;
  max-width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
.comment-box .author h5 {
  font-size: 20px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 0px;
}
.comment-box .author span.commnt-date {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: -4px;
  margin-left: 10px;
  color: #696969;
  font-family: "Saira", sans-serif;
}
.comment-box .comment-header {
  margin-bottom: 10px;
}
.comment-box .comment-header .commnt-reply {
  min-width: 25px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  text-align: center;
  transition: all 0.42s ease;
}
.comment-box .comment-header .commnt-reply .bi {
  color: #696969;
  transition: all 0.42s ease;
}
.comment-box .comment-header .commnt-reply:hover {
  border: 1px solid #f9395f;
}
.comment-box .comment-header .commnt-reply:hover .bi {
  color: #f9395f;
  transition: all 0.42s ease;
}
.comment-box .comment-body {
  margin-bottom: 20px;
}

.comment-form {
  margin-top: 40px;
}
.comment-form .form--btn {
  outline: none;
  border: none;
}
.comment-form .form-inner {
  margin-bottom: 30px;
}
.comment-form .form-inner input,
.comment-form .form-inner textarea {
  width: 100%;
  background: #f1f1f1;
  border: none;
  outline: none;
  padding: 18px 15px;
  border-radius: 5px;
  transition: all 0.42s ease;
}
.comment-form .form-inner input::-moz-placeholder, .comment-form .form-inner textarea::-moz-placeholder {
  font-size: 18px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.comment-form .form-inner input::placeholder,
.comment-form .form-inner textarea::placeholder {
  font-size: 18px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.comment-form .form-inner input:focus,
.comment-form .form-inner textarea:focus {
  background: rgba(249, 57, 95, 0.2);
}

.blog-widget-item {
  background: #ffffff;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 30px 25px;
  margin-bottom: 40px;
}

.blog-sidebar .search-area .form-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.blog-sidebar .search-area .form-inner input {
  border: 1px solid #eeeeee;
  outline: none;
  padding: 12px 15px;
  color: #1f2230;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  width: 100%;
}
.blog-sidebar .search-area .form-inner input:focus {
  border-left: 1px solid #f9395f;
}
.blog-sidebar .search-area .form-inner input::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
.blog-sidebar .search-area .form-inner input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
.blog-sidebar .search-area .form-inner .search--btn {
  outline: none;
  border: 1px solid #f9395f;
  background: #f9395f;
  padding: 11px;
}
.blog-sidebar .search-area .form-inner .search--btn .bx {
  color: #fff;
  font-size: 24px;
}

/*================================================
  Error page
=================================================*/
.error-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.error-section .section-bg-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .error-section .section-bg-top {
    display: none;
    visibility: hidden;
  }
}
.error-section .section-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .error-section .section-bg-bottom {
    display: none;
    visibility: hidden;
  }
}
.error-section .evector1 {
  position: absolute;
  top: 30%;
  right: 30%;
  z-index: -1;
}
.error-section .evector2 {
  position: absolute;
  top: 60%;
  right: 30%;
  z-index: -1;
}
@media (max-width: 767px) {
  .error-section .evector2 {
    display: none;
    visibility: hidden;
  }
}
.error-section .evector3 {
  position: absolute;
  top: 30%;
  left: 30%;
  animation: bounce 2s linear infinite alternate;
  z-index: -1;
}
@media (max-width: 767px) {
  .error-section .evector3 {
    left: 2%;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
.error-section .evector4 {
  position: absolute;
  top: 60%;
  left: 23%;
  z-index: -1;
}
@media (max-width: 767px) {
  .error-section .evector4 {
    display: none;
    visibility: hidden;
  }
}
.error-section .error-wrapper {
  padding: 0px 30px;
  overflow: hidden;
}
.error-section .error-wrapper .error-bg {
  animation: rotating 2.5s linear infinite;
  margin-bottom: 35px;
}
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.error-section .error-wrapper .error-content h2 {
  font-size: 40px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 10px;
}
.error-section .error-wrapper .error-content p {
  margin-bottom: 45px;
}

/*================================================
SIGNUP PAGE
=================================================*/
.registration-form .password-error {
  margin-top: -10px;
  padding: 0 7px;
  min-height: 25px;
  position: relative;
}
.registration-form .form-inner i {
  bottom: 20px !important;
}
.registration-form .form-inner input {
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
@media (max-width: 576px) {
  .registration-form .form-inner input {
    font-size: 14px;
  }
}
.registration-form .form-inner input::-moz-placeholder {
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
.registration-form .form-inner input::placeholder {
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
@media (max-width: 576px) {
  .registration-form .form-inner input::-moz-placeholder {
    font-size: 14px;
  }
  .registration-form .form-inner input::placeholder {
    font-size: 14px;
  }
}

.contractor .inner-banner {
  background-image: url("../assets/images/bg/register-bg/registration-contractor.png");
}
@media (min-width: 1921px) {
  .contractor .inner-banner {
    background-image: url("../assets/images/bg/register-bg/registration-contractor-big.png");
  }
}
@media (max-width: 991px) {
  .contractor .inner-banner {
    background-image: url("../assets/images/bg/register-bg/registration-contractor-medium.png");
  }
}

.client .inner-banner {
  background-image: url("../assets/images/bg/register-bg/registration-client.png");
}
@media (min-width: 1921px) {
  .client .inner-banner {
    background-image: url("../assets/images/bg/register-bg/registration-client-big.png");
  }
}
@media (max-width: 991px) {
  .client .inner-banner {
    background-image: url("../assets/images/bg/register-bg/registration-client-medium.png");
  }
}

/*================================================
GALLERY
=================================================*/
.image-gallery,
.image-gallery-using-mouse {
  width: 100% !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover {
  border: 4px solid #f9395f !important;
  border-radius: 0.25rem;
}

.image-gallery-right-nav:hover,
.image-gallery-left-nav:hover,
.image-gallery-fullscreen-button:hover {
  color: #f9395f !important;
}

.image-gallery-slide .image-gallery-image {
  border-radius: 0.25rem;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  border-radius: 0.1rem;
}

/*================================================
NOTIFICATIONS
=================================================*/
.notification-list-area {
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: auto;
}
.notification-list-area .no-notifications {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-list-area .no-notifications .para {
  margin-bottom: 0;
  font-weight: bold;
}
.notification-list-area .notification-list-item {
  padding: 0;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.06);
  min-height: 100px;
  align-items: center;
  justify-content: space-around;
  position: relative;
  height: 100%;
  display: flex;
}
@media (max-width: 991px) {
  .notification-list-area .notification-list-item {
    flex-direction: column;
    max-height: unset;
    max-height: 220px;
  }
}
.notification-list-area .notification-list-item li {
  border-bottom: 1px solid #eeeeee;
}
.notification-list-area .notification-list-item li:last-child {
  border-bottom: unset;
  padding-bottom: 0px;
}
.notification-list-area .notification-list-item .icon-area,
.notification-list-area .notification-list-item .notification-close {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100px;
  width: auto;
}
@media (max-width: 991px) {
  .notification-list-area .notification-list-item .icon-area,
  .notification-list-area .notification-list-item .notification-close {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
.notification-list-area .notification-list-item .message-area {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  width: 550px;
  padding: 15px 0px;
}
@media (max-width: 1199px) {
  .notification-list-area .notification-list-item .message-area {
    max-width: 400px;
  }
}
@media (max-width: 991px) {
  .notification-list-area .notification-list-item .message-area {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .notification-list-area .notification-list-item .message-area {
    max-width: 300px;
  }
}
.notification-list-area .notification-list-item .message-area .message-content {
  max-height: 100px;
  width: 100%;
}
@media (max-width: 991px) {
  .notification-list-area .notification-list-item .message-area .message-content {
    text-align: center;
    flex-direction: column;
    max-width: 100%;
  }
}
.notification-list-area .notification-list-item .message-area .message-content h6 {
  font-size: 15px;
  font-weight: 600;
  color: #1f2230;
  margin-bottom: 3px;
  font-family: "Saira", sans-serif;
}
.notification-list-area .notification-list-item .message-area .message-content p,
.notification-list-area .notification-list-item .message-area .message-content a {
  font-size: 13px;
  font-weight: 500;
  color: #696969;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .notification-list-area .notification-list-item .message-area .message-content p,
  .notification-list-area .notification-list-item .message-area .message-content a {
    text-align: center;
  }
}
.notification-list-area .notification-list-item .message-area .message-content a {
  color: #f9395f;
  text-decoration: none;
  font-weight: bold;
}
.notification-list-area .notification-list-item .icon-area {
  padding: 15px;
  max-width: 80px;
}
@media (max-width: 991px) {
  .notification-list-area .notification-list-item .icon-area {
    max-width: 100%;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.notification-list-area .notification-list-item .notification-close-container {
  padding: 15px;
}
@media (max-width: 991px) {
  .notification-list-area .notification-list-item .notification-close-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 10px;
    padding: 0;
  }
}
.notification-list-area .notification-list-item .notification-close-container .notification-close {
  cursor: pointer;
}
@media (max-width: 991px) {
  .notification-list-area .notification-list-item .notification-close-container .notification-close {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
}
.notification-list-area .notification-list-item .message-time {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  width: 200px;
  padding: 15px;
}
@media (max-width: 991px) {
  .notification-list-area .notification-list-item .message-time {
    max-width: 100%;
    width: 100%;
    padding: 10px;
  }
}
.notification-list-area .notification-list-item .message-time p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #696969;
}

.notification-list-area::-webkit-scrollbar {
  width: 5px;
}

.notification-list-area::-webkit-scrollbar-track {
  background: #eeeeee;
}

.notification-list-area::-webkit-scrollbar-thumb {
  background: #888;
  -webkit-transition: 0.35s ease-in;
  transition: 0.35s ease-in;
  border-radius: 5px;
  height: 100px;
}

.notification-list-area::-webkit-scrollbar-thumb:hover {
  background: #1f2230;
}

/*================================================
 Contractor profile page
=================================================*/
.contractor-profile-container .row {
  margin: auto;
}
@media (max-width: 576px) {
  .contractor-profile-container .row {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .contractor-profile-container .sidebar-widget-title {
    margin-right: 10px;
  }
}
@media (max-width: 991px) {
  .contractor-profile-container .sidebar-widget-title h4 {
    font-size: 20px;
  }
}
.contractor-profile-container .review-rate span {
  font-family: "Saira", sans-serif;
  font-size: 15px;
  margin-top: 2px;
}
@media (max-width: 576px) {
  .contractor-profile-container .review-rate span {
    font-size: 13px;
  }
}
.contractor-profile-container .testimonial-section {
  padding-top: 0 !important;
  padding-bottom: 50px !important;
}
.contractor-profile-container .image-gallery-right-nav,
.contractor-profile-container .image-gallery-left-nav {
  padding: 0;
}
.contractor-profile-container .image-gallery-right-nav svg,
.contractor-profile-container .image-gallery-left-nav svg {
  height: 70px !important;
  width: 70px !important;
}
.contractor-profile-container .image-gallery-fullscreen-button {
  padding: 0;
  background: rgba(255, 255, 255, 0.1450980392);
  border-radius: 0.25rem;
  right: 5px;
  bottom: 10px;
}
.contractor-profile-container .image-gallery-fullscreen-button svg {
  height: 30px !important;
  width: 30px !important;
}
.contractor-profile-container .contractor-details-container {
  align-items: center;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
@media (max-width: 1199px) {
  .contractor-profile-container .contractor-details-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}
.contractor-profile-container .contractor-details-container .description-box,
.contractor-profile-container .contractor-details-container .work-area-box {
  height: 100%;
}
.contractor-profile-container .contractor-details-container .description-box {
  grid-area: 1/1/3/2;
}
@media (max-width: 1199px) {
  .contractor-profile-container .contractor-details-container .description-box {
    grid-area: 1/1/2/2;
  }
}
.contractor-profile-container .contractor-details-container .description-box .card {
  height: 100%;
}
.contractor-profile-container .contractor-details-container .description-box .card.single-feature {
  min-height: 100px;
  height: 100%;
}
.contractor-profile-container .contractor-details-container .description-box .card .summary-content {
  height: 100%;
}
.contractor-profile-container .contractor-details-container .description-box .card .summary-content .description {
  display: flex;
  flex-wrap: wrap;
  max-height: 330px;
  min-height: 100px;
  overflow-y: auto;
  flex-direction: column;
  text-align: left;
  padding-right: 20px;
}
@media (max-width: 767px) {
  .contractor-profile-container .contractor-details-container .description-box .card .summary-content .description .para {
    font-size: 14px;
    line-height: 25px;
    text-align: left;
  }
}
.contractor-profile-container .contractor-details-container .types-work-box {
  grid-area: 2/2/3/3;
}
@media (max-width: 1199px) {
  .contractor-profile-container .contractor-details-container .types-work-box {
    grid-area: 3/1/4/2;
  }
}
.contractor-profile-container .contractor-details-container .types-work-box .card.single-feature {
  min-height: 100px;
}
.contractor-profile-container .contractor-details-container .types-work-box .card.work-area-card {
  width: 100%;
}
.contractor-profile-container .contractor-details-container .types-work-box .card.work-area-card .card-body {
  align-items: baseline;
  justify-content: flex-start;
  padding: 0;
}
.contractor-profile-container .contractor-details-container .types-work-box .card.work-area-card .card-body .summary-content {
  width: 100%;
  height: 100%;
}
.contractor-profile-container .contractor-details-container .types-work-box .card.work-area-card .card-body .summary-content .tags {
  height: 100px;
  max-height: 100px;
  overflow-y: auto;
  padding-right: 5px;
}
.contractor-profile-container .contractor-details-container .types-work-box .card.work-area-card .card-body .summary-content .tags.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contractor-profile-container .contractor-details-container .types-work-box .card.work-area-card .card-body .summary-content .tags .para {
  margin-bottom: 0;
}
.contractor-profile-container .contractor-details-container .types-work-box .card.work-area-card .card-body .summary-content .tags .tag {
  display: inline-block;
  padding: 0.2em 0.5em 0.3em;
  border-radius: 0.25rem;
  margin: 0.25em 0.1em;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: "Saira", sans-serif;
}
.contractor-profile-container .contractor-details-container .types-work-box .card.work-area-card .card-body .summary-content .tags .tag-province {
  background-color: #c71438;
}
.contractor-profile-container .contractor-details-container .types-work-box .card.work-area-card .card-body .summary-content .tags .tag-county {
  background-color: #f9395f;
}
.contractor-profile-container .contractor-details-container .work-area-box {
  grid-area: 1/2/2/3;
}
@media (max-width: 1199px) {
  .contractor-profile-container .contractor-details-container .work-area-box {
    grid-area: 2/1/3/2;
    align-content: center;
  }
}
.contractor-profile-container .contractor-details-container .work-area-box .card.single-feature {
  min-height: 100px;
}
.contractor-profile-container .contractor-details-container .work-area-box .card.work-area-card {
  width: 100%;
}
.contractor-profile-container .contractor-details-container .work-area-box .card.work-area-card .card-body {
  align-items: baseline;
  justify-content: flex-start;
}
.contractor-profile-container .contractor-details-container .work-area-box .card.work-area-card .card-body .summary-content {
  width: 100%;
  height: 100%;
}
.contractor-profile-container .contractor-details-container .work-area-box .card.work-area-card .card-body .summary-content .tags {
  height: 100px;
  max-height: 100px;
  overflow-y: auto;
  padding-right: 5px;
}
.contractor-profile-container .contractor-details-container .work-area-box .card.work-area-card .card-body .summary-content .tags.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contractor-profile-container .contractor-details-container .work-area-box .card.work-area-card .card-body .summary-content .tags .para {
  margin-bottom: 0;
}
.contractor-profile-container .contractor-details-container .work-area-box .card.work-area-card .card-body .summary-content .tags .tag {
  display: inline-block;
  padding: 0.2em 0.5em 0.3em;
  border-radius: 0.25rem;
  margin: 0.25em 0.1em;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: "Saira", sans-serif;
}
.contractor-profile-container .contractor-details-container .work-area-box .card.work-area-card .card-body .summary-content .tags .tag-province {
  background-color: #c71438;
}
.contractor-profile-container .contractor-details-container .work-area-box .card.work-area-card .card-body .summary-content .tags .tag-county {
  background-color: #f9395f;
}
.contractor-profile-container .card,
.contractor-profile-container .summary-container {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 0.25rem;
  text-align: left;
  padding: 25px;
}
.contractor-profile-container .card .summary-content,
.contractor-profile-container .summary-container .summary-content {
  text-align: left;
}
.contractor-profile-container .card .card-body,
.contractor-profile-container .summary-container .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0;
}
@media (max-width: 767px) {
  .contractor-profile-container .card .card-body .para,
  .contractor-profile-container .summary-container .card-body .para {
    font-size: 14px !important;
    line-height: 25px !important;
    text-align: center;
    margin-bottom: 0 !important;
  }
}
.contractor-profile-container .card .card-body .image-container,
.contractor-profile-container .summary-container .card-body .image-container {
  height: 160px;
  width: 100%;
  position: absolute;
  background: #f9395f;
  top: 0;
  border-radius: 0.25rem;
}
.contractor-profile-container .card .card-body .image-container .image,
.contractor-profile-container .summary-container .card-body .image-container .image {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  position: absolute;
  left: 17%;
  top: 130px;
  transform: translate(-50%, -50%);
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
}
@media (max-width: 991px) {
  .contractor-profile-container .card .card-body .image-container .image,
  .contractor-profile-container .summary-container .card-body .image-container .image {
    z-index: 999;
    left: 50%;
    top: 110px;
  }
}
@media (max-width: 767px) {
  .contractor-profile-container .card .card-body .image-container .image,
  .contractor-profile-container .summary-container .card-body .image-container .image {
    height: 150px;
    width: 150px;
  }
}
.contractor-profile-container .card .card-body .image-container .image .react-loading-skeleton,
.contractor-profile-container .summary-container .card-body .image-container .image .react-loading-skeleton {
  height: 200px;
  width: 200px;
}
@media (max-width: 767px) {
  .contractor-profile-container .card .card-body .image-container .image .react-loading-skeleton,
  .contractor-profile-container .summary-container .card-body .image-container .image .react-loading-skeleton {
    height: 150px;
    width: 150px;
  }
}
.contractor-profile-container .card .card-body .image-container .image img,
.contractor-profile-container .summary-container .card-body .image-container .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border: 5px solid #fff;
}
.contractor-profile-container .contractor-gallery-card {
  height: 685px;
}
.contractor-profile-container .contractor-gallery-card.empty-gallery-card {
  height: 150px;
}
@media (max-width: 576px) {
  .contractor-profile-container .contractor-gallery-card.empty-gallery-card .gallery-container .sidebar-widget-title {
    margin-bottom: 25px !important;
  }
}
.contractor-profile-container .contractor-gallery-card .gallery-container {
  display: block;
}
@media (max-width: 576px) {
  .contractor-profile-container .contractor-gallery-card .gallery-container .sidebar-widget-title {
    margin-bottom: 10px !important;
  }
}
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container {
  height: 500px;
}
@media (max-width: 576px) {
  .contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container {
    width: 100%;
    margin: auto;
  }
}
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content {
  height: 100%;
  width: 100%;
}
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content .image-gallery-slide-wrapper {
  height: 100%;
}
@media (max-width: 576px) {
  .contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content .image-gallery-slide-wrapper {
    width: 100%;
  }
}
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe {
  height: 100%;
}
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide,
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slides {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slides .image-gallery-slide,
.contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slides .image-gallery-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media (max-width: 576px) {
  .contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-thumbnails-wrapper {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .contractor-profile-container .contractor-gallery-card .gallery-container .contractor-gallery-container .image-gallery-thumbnails-wrapper .image-gallery-thumbnails {
    width: 100%;
  }
}
.contractor-profile-container .personal-info-container {
  padding: 20px;
  position: relative;
}
.contractor-profile-container .personal-info-container .card-body {
  height: unset;
}
.contractor-profile-container .personal-info-container .icon-container {
  display: flex;
  align-items: center;
  min-height: 40px;
  width: -moz-max-content;
  width: max-content;
}
@media (max-width: 991px) {
  .contractor-profile-container .personal-info-container .icon-container {
    width: 100%;
  }
}
.contractor-profile-container .personal-info-container .icon-container p {
  color: #696969;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Saira", sans-serif;
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .icon-container p {
    font-size: 14px;
  }
}
.contractor-profile-container .personal-info-container .icon-container .icon {
  background: rgba(249, 57, 95, 0.2);
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  transition: all 0.4s ease-in;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.contractor-profile-container .personal-info-container .icon-container .icon i {
  color: #f9395f;
  transition: all 0.35s ease-in;
  font-size: 16px;
}
.contractor-profile-container .personal-info-container .info-container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 217px;
  padding-left: 30px;
  width: 100%;
}
@media (max-width: 991px) {
  .contractor-profile-container .personal-info-container .info-container {
    margin-bottom: 22px;
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .info-container {
    padding-left: 0;
    margin-top: 195px;
    margin-bottom: 38px;
  }
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item {
  box-shadow: unset;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 175px;
}
@media (max-width: 991px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item {
    bottom: 0;
    right: 20px;
    top: unset;
  }
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item {
    bottom: -20px;
  }
}
@media (max-width: 576px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item {
    right: unset;
    left: 24px;
  }
}
@media (max-width: 991px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list {
    flex-direction: row;
  }
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a {
  border: 1px solid #d2d2d2;
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a {
    height: 30px !important;
    width: 30px !important;
  }
}
@media (max-width: 576px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a {
    height: 25px !important;
    width: 25px !important;
  }
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a.is-link {
  border: 1px solid #f9395f;
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a.is-link .bi {
  color: #f9395f;
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a.is-link .bx {
  color: #f9395f;
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a.is-link:hover {
  border: 1px solid #c71438;
  color: #c71438;
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a.no-link {
  border: 1px solid #d2d2d2;
  cursor: auto;
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a.no-link .bi {
  color: #d2d2d2;
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a.no-link .bx {
  color: #d2d2d2;
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a.no-link:hover {
  border: 1px solid #bdbdbd;
  color: #bdbdbd;
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .tiktok {
  height: 25px;
}
@media (max-width: 576px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .tiktok {
    height: 20px;
  }
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .instagram,
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .globe {
  height: 32px;
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .instagram,
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .globe {
    height: 27px;
  }
}
@media (max-width: 576px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .instagram,
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .globe {
    height: 25px;
  }
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .bi {
  font-size: 19px;
}
.contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .bx {
  font-size: 25px;
}
@media (max-width: 576px) {
  .contractor-profile-container .personal-info-container .info-container .blog-widget-item .sidebar-social-list li a .bx {
    font-size: 22px;
  }
}
.contractor-profile-container .personal-info-container .info-container .contractor-info-container {
  max-width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .info-container .contractor-info-container {
    min-height: 30px;
  }
}
.contractor-profile-container .personal-info-container .info-container .contractor-info-container .sidebar-widget-title,
.contractor-profile-container .personal-info-container .info-container .contractor-info-container .review-rate {
  height: 100%;
  display: flex;
  justify-content: center;
}
.contractor-profile-container .personal-info-container .info-container .contractor-info-container .sidebar-widget-title {
  margin-bottom: 0;
  margin-right: 10px;
}
.contractor-profile-container .personal-info-container .info-container .contractor-info-container .sidebar-widget-title h4 {
  margin-bottom: 0;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .info-container .contractor-info-container .sidebar-widget-title h4 {
    font-size: 20px;
  }
}
.contractor-profile-container .personal-info-container .info-container .contractor-info-container .review-rate i {
  font-size: 16px;
  margin-right: 5px;
  color: #f9395f;
  align-items: baseline;
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .info-container .contractor-info-container .review-rate i {
    font-size: 14px;
  }
}
.contractor-profile-container .personal-info-container .contractor-personal-info-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  left: 1px;
}
@media (max-width: 991px) {
  .contractor-profile-container .personal-info-container .contractor-personal-info-container {
    position: relative;
    justify-content: flex-start;
    padding-left: 30px;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .contractor-profile-container .personal-info-container .contractor-personal-info-container {
    padding-left: 0;
    bottom: 40px;
  }
}
.contractor-profile-container .personal-info-container .contractor-personal-info-container p {
  margin-right: 20px;
}
.contractor-profile-container .personal-info-container .contractor-personal-info-container button {
  width: 200px;
  height: 40px;
  margin-left: 30px;
}
.contractor-profile-container .gallery-container p,
.contractor-profile-container .testimonial-card p {
  font-size: 16px;
  font-weight: 400;
  color: #696969;
  font-family: "Inter", sans-serif;
  line-height: 28px;
}
.contractor-profile-container .testimonial-card .card-body .testi-next2 {
  right: -75px !important;
}
.contractor-profile-container .testimonial-card .card-body .testi-prev2 {
  left: -75px !important;
}

/*================================================
 Fucha Detail Page
=================================================*/
@media (max-width: 576px) {
  .modal.bid-modal .sidebar-widget-title h4, .modal.confirm-bid-modal .sidebar-widget-title h4 {
    font-size: 20px;
    max-width: 280px;
  }
}
@media (max-width: 576px) {
  .modal.bid-modal .modal-body p, .modal.confirm-bid-modal .modal-body p {
    font-size: 14px;
    line-height: 25px;
  }
}

.fucha-detail-container .first-offer-text {
  font-size: 11px;
  margin-bottom: -5px;
  margin-top: -10px;
  font-family: "Inter", sans-serif;
}
.fucha-detail-container .image-gallery-right-nav,
.fucha-detail-container .image-gallery-left-nav {
  padding: 0;
}
.fucha-detail-container .image-gallery-right-nav svg,
.fucha-detail-container .image-gallery-left-nav svg {
  height: 70px !important;
  width: 70px !important;
}
.fucha-detail-container .image-gallery-fullscreen-button {
  padding: 0;
  background: rgba(255, 255, 255, 0.1450980392);
  border-radius: 0.25rem;
  right: 5px;
  bottom: 10px;
}
.fucha-detail-container .image-gallery-fullscreen-button svg {
  height: 30px !important;
  width: 30px !important;
}
.fucha-detail-container .row {
  --bs-gutter-x: 0 !important;
}
@media (max-width: 576px) {
  .fucha-detail-container .similar-fuchy-container .similar-fuchy-card .auction-card2 {
    max-width: 100%;
  }
}
.fucha-detail-container .similar-fuchy-container .similar-fuchy-card .swiper-button-lock {
  display: block;
}
@media (max-width: 576px) {
  .fucha-detail-container .similar-fuchy-container .similar-fuchy-card .sidebar-widget-title {
    padding: 16px;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 576px) {
  .fucha-detail-container .similar-fuchy-container .similar-fuchy-card .card-body {
    padding: 0;
  }
}
.fucha-detail-container .similar-fuchy-container .similar-fuchy-card .no-fuchy-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fucha-detail-container .similar-fuchy-container .similar-fuchy-card .no-fuchy-container h4 {
  padding: 10px;
}
@media (max-width: 576px) {
  .fucha-detail-container .similar-fuchy-container .similar-fuchy-card .no-fuchy-container h4 {
    font-size: 20px;
    padding: 35px;
  }
}
.fucha-detail-container .similar-fuchy-container .similar-fuchy-card .no-fuchy-container .image-container {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: move 2.6s ease-in-out infinite;
  background: unset;
}
@media (max-width: 767px) {
  .fucha-detail-container .similar-fuchy-container .similar-fuchy-card .no-fuchy-container .image-container {
    height: 150px;
    width: 150px;
  }
}
.fucha-detail-container .similar-fuchy-container .similar-fuchy-card .no-fuchy-container .image-container img {
  height: 100%;
  width: 100%;
}
.fucha-detail-container .card {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 0.25rem;
  text-align: left;
  margin-bottom: 1rem;
}
.fucha-detail-container .card.side-card {
  min-height: 300px;
}
.fucha-detail-container .card.side-card .card-body .product-details-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}
.fucha-detail-container .card.gallery-card {
  width: 99%;
}
@media (max-width: 1199px) {
  .fucha-detail-container .card.gallery-card {
    min-height: 610px;
  }
}
@media (max-width: 576px) {
  .fucha-detail-container .card.gallery-card {
    min-height: 520px;
  }
}
@media (max-width: 576px) {
  .fucha-detail-container .card.gallery-card .sidebar-widget-title {
    margin-bottom: 0 !important;
  }
}
.fucha-detail-container .card.gallery-card .gallery-container {
  display: flex;
  align-items: center;
  height: 460px !important;
}
@media (max-width: 576px) {
  .fucha-detail-container .card.gallery-card .gallery-container {
    height: 400px !important;
  }
}
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery {
  height: 80%;
}
@media (max-width: 576px) {
  .fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery {
    width: 100%;
    margin: auto;
  }
}
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content {
  height: 100%;
  width: 100%;
}
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content .image-gallery-slide-wrapper {
  height: 100%;
}
@media (max-width: 576px) {
  .fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content .image-gallery-slide-wrapper {
    width: 100%;
  }
}
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe {
  height: 100%;
}
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide,
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slides {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slides .image-gallery-slide,
.fucha-detail-container .card.gallery-card .gallery-container .fucha-images-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slides .image-gallery-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.fucha-detail-container .card.gallery-card .gallery-container .image-gallery-thumbnail {
  height: 80px;
  width: 80px;
}
@media (max-width: 576px) {
  .fucha-detail-container .card.gallery-card .gallery-container .image-gallery-thumbnail {
    max-width: 325px;
  }
}
.fucha-detail-container .card.gallery-card .gallery-container .image-gallery-thumbnail span {
  height: 100%;
}
.fucha-detail-container .card.gallery-card .gallery-container .image-gallery-thumbnail span img {
  height: 100%;
  -o-object-fit: cover !important;
     object-fit: cover !important;
  -o-object-position: center;
     object-position: center;
}
.fucha-detail-container .card .card-body .product-details-right h5 {
  font-weight: bold;
}
.fucha-detail-container .card .card-body .product-details-right h6 {
  color: #696969;
}
.fucha-detail-container .card .card-body .product-details-right .row {
  align-items: baseline;
  justify-content: space-evenly;
}
@media (max-width: 576px) {
  .fucha-detail-container .card .card-body .product-details-right p {
    font-size: 14px;
    line-height: 25px;
  }
}
.fucha-detail-container .card .card-body .offers-list-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}
.fucha-detail-container .card .card-body .offers-list-container h6 {
  margin-bottom: 12px;
  margin-top: 25px;
  margin-left: 4px;
  color: #696969;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container {
  min-height: 80px;
  margin-bottom: 16px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 5px;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-image-box,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-image-box {
  border: 2px solid #f9395f;
  border-radius: 50%;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item p,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container p {
  font-size: 14px;
  line-height: unset;
  margin: 0;
  text-align: center;
  font-weight: bold;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-icon-container,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-icon-container {
  height: 100%;
  justify-content: center;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-icon-container .icon,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-icon-container .icon {
  margin-right: 0;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-icon-container .para,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-icon-container .para {
  font-size: 13px;
  font-weight: bold;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-icon-container .para.not-verified,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-icon-container .para.not-verified {
  color: #f9395f;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-icon-container .para.verified,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-icon-container .para.verified {
  color: rgb(43, 174, 9);
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-review-rate,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-review-rate {
  display: flex;
  align-items: center;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-review-rate i,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-review-rate i {
  font-size: 14px;
  margin-right: 2px;
  color: #696969;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-review-rate .fas.fa-star,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-review-rate .fas.fa-star-half-alt,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-review-rate .fas.fa-star,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-review-rate .fas.fa-star-half-alt {
  color: #f1d045;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item .offer-review-rate span,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container .offer-review-rate span {
  margin-left: 5px;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  color: #696969;
  margin-top: 3px;
}
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-list-item.offer-btn-container .confirm,
.fucha-detail-container .card .card-body .offers-list-container .offer-list-item-container .offer-image-container.offer-btn-container .confirm {
  margin-right: 10px;
}
.fucha-detail-container .card .card-body .form-title {
  margin-bottom: 0;
}
.fucha-detail-container .card .card-body .form-title h5 {
  font-size: 17px;
  margin-bottom: 10px;
  color: #696969;
}
.fucha-detail-container .card .card-body .form-title.no-signed-view-title h5 {
  font-size: 17px !important;
}
.fucha-detail-container .card .card-body .contractor-image-container {
  height: 85px;
  width: 100%;
  background: #f9395f;
  top: 0;
  border-radius: 0.25rem;
  margin-bottom: 35px;
}
.fucha-detail-container .card .card-body .contractor-image-container .contractor-image-box {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  background: #fff;
  position: absolute;
  top: 110px;
  left: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .fucha-detail-container .card .card-body .contractor-image-container .contractor-image-box {
    top: 100px;
    left: 45px;
  }
}
.fucha-detail-container .card .card-body .contractor-image-container .contractor-image-box a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fucha-detail-container .card .card-body .contractor-image-container .contractor-image-box a img {
  height: 90%;
  width: 90%;
}
.fucha-detail-container .card .card-body .image-container {
  height: 85px;
  width: 100%;
  background: #f9395f;
  top: 0;
  border-radius: 0.25rem;
  margin-bottom: 35px;
}
.fucha-detail-container .card .card-body .image-container .image-box {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  background: #fff;
  position: absolute;
  top: 110px;
  left: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .fucha-detail-container .card .card-body .image-container .image-box {
    top: 100px;
    left: 45px;
  }
}
.fucha-detail-container .card .card-body .image-container .image-box img {
  height: 90%;
  width: 90%;
}
.fucha-detail-container .card .card-body .contractor-details-container .icon-container .icon {
  background: rgba(43, 174, 9, 0.2);
  border: 1px solid rgb(43, 174, 9);
}
.fucha-detail-container .card .card-body .contractor-details-container .icon-container .icon i {
  color: rgb(43, 174, 9);
}
.fucha-detail-container .card .card-body .contractor-details-container .contractor-name-container {
  display: flex;
  min-height: 30px;
  margin-top: 40px;
  width: 100%;
  align-items: center;
}
.fucha-detail-container .card .card-body .contractor-details-container .contractor-name-container .name {
  font-size: 16px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  margin-right: 10px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 4px;
  height: 100%;
  color: #696969;
}
@media (max-width: 576px) {
  .fucha-detail-container .card .card-body .contractor-details-container .contractor-name-container .name {
    font-size: 14px;
    margin-left: 0;
    margin-right: 5px;
  }
}
.fucha-detail-container .card .card-body .contractor-details-container .contractor-name-container .name-container {
  height: 100%;
}
.fucha-detail-container .card .card-body .contractor-details-container .contractor-name-container .review-rate {
  display: flex;
  align-items: flex-start;
  height: 100%;
}
.fucha-detail-container .card .card-body .contractor-details-container .contractor-name-container .review-rate i {
  font-size: 12px;
  margin-right: 3px;
  color: #f9395f;
  align-items: baseline;
}
.fucha-detail-container .card .card-body .contractor-details-container .contractor-name-container .review-rate span {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  letter-spacing: 1px;
  margin-top: 2px;
  color: #696969;
}
.fucha-detail-container .card .card-body .client-name-container {
  display: flex;
  height: 30px;
  margin-top: 40px;
  width: 100%;
  align-items: center;
}
.fucha-detail-container .card .card-body .client-name-container .name {
  font-size: 16px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  margin-right: 10px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  color: #696969;
}
@media (max-width: 576px) {
  .fucha-detail-container .card .card-body .client-name-container .name {
    font-size: 14px;
    margin-left: 0;
    margin-right: 5px;
  }
}
.fucha-detail-container .card .card-body .client-name-container .name-container {
  height: 100%;
}
.fucha-detail-container .card .card-body .client-name-container .review-rate {
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.fucha-detail-container .card .card-body .client-name-container .review-rate i {
  font-size: 12px;
  margin-right: 3px;
  color: #f9395f;
  align-items: baseline;
}
.fucha-detail-container .card .card-body .client-name-container .review-rate span {
  font-size: 11px;
  font-family: "Inter", sans-serif;
  letter-spacing: 1px;
}
.fucha-detail-container .card .card-body .review-rate {
  display: flex;
  align-items: center;
}
.fucha-detail-container .card .card-body .review-rate i {
  font-size: 14px;
  margin-right: 2px;
  color: #696969;
}
.fucha-detail-container .card .card-body .review-rate .fas.fa-star,
.fucha-detail-container .card .card-body .review-rate .fas.fa-star-half-alt {
  color: #f1d045;
}
.fucha-detail-container .card .card-body .review-rate span {
  margin-left: 5px;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  color: #696969;
  margin-top: 3px;
}
.fucha-detail-container .card .card-body .sidebar-widget-title {
  margin-bottom: 25px;
  margin-top: 10px;
}
@media (max-width: 576px) {
  .fucha-detail-container .card .card-body .sidebar-widget-title h4 {
    font-size: 20px;
  }
}
.fucha-detail-container .card .card-body .icon-container {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
}
@media (max-width: 576px) {
  .fucha-detail-container .card .card-body .icon-container {
    height: 35px;
  }
}
.fucha-detail-container .card .card-body .icon-container.specification-container {
  margin-top: 15px;
  margin-bottom: 10px;
}
.fucha-detail-container .card .card-body .icon-container p {
  color: #696969;
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 576px) {
  .fucha-detail-container .card .card-body .icon-container p {
    font-size: 13px;
  }
}
.fucha-detail-container .card .card-body .icon-container.not-verified .icon {
  background: rgba(249, 57, 95, 0.2);
}
.fucha-detail-container .card .card-body .icon-container.verified .icon {
  background: rgba(43, 174, 9, 0.2);
  border: 1px solid rgb(43, 174, 9);
}
.fucha-detail-container .card .card-body .icon-container.verified .icon i {
  color: rgb(43, 174, 9);
}
.fucha-detail-container .card .card-body .icon-container .icon {
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  transition: all 0.4s ease-in;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  border: 1px solid #f9395f;
}
.fucha-detail-container .card .card-body .icon-container .icon i {
  color: #f9395f;
  transition: all 0.35s ease-in;
  font-size: 16px;
}
.fucha-detail-container .card .card-body .tags {
  height: 100%;
  width: 100%;
  max-height: 180px;
  overflow-y: auto;
  padding-right: 10px;
}
.fucha-detail-container .card .card-body .tags p {
  font-size: 16px;
  font-weight: 400;
  color: #696969;
  font-family: "Saira", sans-serif;
  line-height: 28px;
}
.fucha-detail-container .card .card-body .tags .tag {
  display: inline-block;
  padding: 0.2em 0.5em 0.3em;
  border-radius: 0.25rem;
  margin: 0.25em 0.1em;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: "Saira", sans-serif;
  background-color: #f9395f;
}
.fucha-detail-container .card .card-body .tags .tag-province {
  background-color: #c71438;
}
.fucha-detail-container .card .card-body .tags .tag-county {
  background-color: #f9395f;
}
.fucha-detail-container .card .card-body .verification-info-container .verify-text.verified {
  color: #029e7c;
}
.fucha-detail-container .card .card-body .verification-info-container .verify-text.not-verified {
  color: #f9395f;
}
.fucha-detail-container .card .card-body .veryfi-text-container {
  position: absolute;
  right: -80%;
  top: 40px;
}
.fucha-detail-container .card .card-body .veryfi-text-container .verify-text-fucha {
  color: #c71438;
}
.fucha-detail-container .card .card-body .loader {
  border-radius: 50%;
  background-color: #f9395f;
  animation: 1.5s pulse infinite ease-in-out;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #f9395f;
  }
  100% {
    box-shadow: 0 0 0 14px rgba(249, 57, 95, 0);
  }
}
.fucha-detail-container .card .card-body .join-btn {
  margin-left: 0 !important;
}
.fucha-detail-container .card .card-body .details-container {
  height: 70%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .fucha-detail-container .offer-list-item-container {
    height: unset !important;
    justify-content: center;
    position: relative;
  }
  .fucha-detail-container .offer-list-item-container .icon-container .icon {
    height: 25px !important;
    min-width: 25px !important;
  }
  .fucha-detail-container .offer-list-item-container .icon-container .icon i {
    font-size: 14px !important;
  }
  .fucha-detail-container .offer-list-item-container .offer-image-container,
  .fucha-detail-container .offer-list-item-container .offer-list-item {
    width: unset !important;
    justify-content: flex-start !important;
  }
  .fucha-detail-container .offer-list-item-container .offer-image-container p,
  .fucha-detail-container .offer-list-item-container .offer-list-item p {
    font-size: 12px !important;
  }
  .fucha-detail-container .offer-list-item-container .bid-contractor-details {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .fucha-detail-container .offer-list-item-container .bid-contractor-details .bid-contractor-info {
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }
  .fucha-detail-container .offer-list-item-container .bid-contractor-details .bid-contractor-info .offer-review-rate i,
  .fucha-detail-container .offer-list-item-container .bid-contractor-details .bid-contractor-info .offer-review-rate span {
    font-size: 12px !important;
  }
  .fucha-detail-container .offer-list-item-container .veryfi-icons {
    display: flex;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 70%;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container .bid-details-box {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container .bid-buttons {
    display: flex;
    padding: 10px;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container .bid-buttons .btn--sm {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .fucha-detail-container .offer-list-item-container {
    flex-direction: column;
  }
  .fucha-detail-container .offer-list-item-container .bid-contractor-details {
    width: 100%;
    justify-content: flex-start;
  }
  .fucha-detail-container .offer-list-item-container .veryfi-icons {
    padding: 10px;
    position: absolute;
    right: 0;
    top: 10px;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container {
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container .remove-offer-btn {
    width: 100% !important;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container .bid-details-box {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container .bid-details-box .para-small {
    margin-left: 10px !important;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container .bid-buttons {
    width: 100%;
    padding: 5px 0;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container .bid-buttons .offer-list-item {
    width: 50% !important;
  }
  .fucha-detail-container .offer-list-item-container .bid-details-container .bid-buttons .offer-list-item button {
    width: 100%;
  }
}

/*================================================
 Pricing Page
=================================================*/
.pricing-section .subtitle {
  max-width: 615px;
  margin: auto;
}
.pricing-section .prices-header span {
  font-family: "League Spartan", sans-serif !important;
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 576px) {
  .pricing-section .prices-header span {
    font-size: 26px;
  }
}
.pricing-section .client-right-vector {
  position: absolute;
  top: 35%;
  right: 0;
}
@media (max-width: 767px) {
  .pricing-section .client-right-vector {
    display: none;
    visibility: hidden;
  }
}
.pricing-section .client-left-vector {
  position: absolute;
  top: 35%;
  left: 0;
}
@media (max-width: 767px) {
  .pricing-section .client-left-vector {
    display: none;
    visibility: hidden;
  }
}
.pricing-section .client-circle5 {
  position: absolute;
  right: 0;
  bottom: -350px;
  z-index: 1;
}
.pricing-section .client-circle4 {
  position: absolute;
  left: 0;
  bottom: -450px;
  z-index: 1;
}
.pricing-section .card {
  padding: 60px 40px;
  position: relative;
  z-index: 10;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
}
.pricing-section .single-feature {
  height: 240px;
  display: flex;
  align-items: center;
  z-index: 10;
  position: relative;
  justify-content: center;
}
.pricing-section .single-feature h5 {
  font-size: 22px;
  font-weight: 700;
  color: #1f2230;
  transition: all 0.42s ease-in;
}

/*================================================
 Reviews
=================================================*/
.add-review-button:disabled,
.expired-fucha-btn:disabled {
  background: #ccc;
}
.add-review-button::after,
.expired-fucha-btn::after {
  background: #ccc;
}
.add-review-button::before,
.expired-fucha-btn::before {
  background: #ccc;
}

.client-review-modal .sidebar-widget-title,
.contractor-review-modal .sidebar-widget-title {
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .client-review-modal .sidebar-widget-title h4,
  .contractor-review-modal .sidebar-widget-title h4 {
    font-size: 20px;
  }
}
.client-review-modal .modal-body,
.contractor-review-modal .modal-body {
  padding: 50px 10px;
}
.client-review-modal .modal-body .review-bg,
.client-review-modal .modal-body .review-circle4,
.client-review-modal .modal-body .review-circle5,
.client-review-modal .modal-body .review-circle6,
.contractor-review-modal .modal-body .review-bg,
.contractor-review-modal .modal-body .review-circle4,
.contractor-review-modal .modal-body .review-circle5,
.contractor-review-modal .modal-body .review-circle6 {
  position: absolute;
}
.client-review-modal .modal-body .review-bg,
.contractor-review-modal .modal-body .review-bg {
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0;
}
.client-review-modal .modal-body .review-circle4,
.contractor-review-modal .modal-body .review-circle4 {
  left: 190px;
  top: 96px;
  height: 25px;
}
.client-review-modal .modal-body .review-circle5,
.contractor-review-modal .modal-body .review-circle5 {
  left: 182px;
  height: 45px;
  top: 40px;
}
.client-review-modal .modal-body .review-circle6,
.contractor-review-modal .modal-body .review-circle6 {
  left: 240px;
  top: 80px;
  height: 20px;
}
.client-review-modal .modal-body .review-container,
.contractor-review-modal .modal-body .review-container {
  max-width: 400px;
  height: 100%;
  margin: auto;
  border: 2px solid #f9395f;
  background-color: #fff;
  border-radius: 30px;
}
.client-review-modal .modal-body .review-container .contractor-name-box p,
.contractor-review-modal .modal-body .review-container .contractor-name-box p {
  font-family: "Saira", sans-serif;
  color: #696969;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px 0 !important;
}
.client-review-modal .modal-body .user-name-box,
.contractor-review-modal .modal-body .user-name-box {
  width: 90%;
  margin: auto;
}
.client-review-modal .modal-body .user-name-box p,
.contractor-review-modal .modal-body .user-name-box p {
  font-family: "Saira", sans-serif;
  color: #696969;
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0 !important;
  text-align: center;
}
.client-review-modal .modal-body .image-container,
.contractor-review-modal .modal-body .image-container {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client-review-modal .modal-body .image-container img,
.contractor-review-modal .modal-body .image-container img {
  position: absolute;
  top: 15px;
  background-color: #fff;
  border: 10px solid #fff;
}
.client-review-modal .rating-box,
.contractor-review-modal .rating-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client-review-modal .rating-box .text-part,
.contractor-review-modal .rating-box .text-part {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}
.client-review-modal .rating-box .text-part p,
.contractor-review-modal .rating-box .text-part p {
  font-family: "Inter", sans-serif;
  color: #696969;
  font-size: 13px;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 0;
}
.client-review-modal .rating-box .stars-part,
.contractor-review-modal .rating-box .stars-part {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .contractor-summary-modal .modal-dialog {
    width: 90% !important;
    margin: auto !important;
  }
}
.contractor-summary-modal p {
  font-size: 14px;
  margin-bottom: 0;
  color: #696969;
  text-align: end;
  margin-right: 20px;
}
@media (max-width: 576px) {
  .contractor-summary-modal h4 {
    font-size: 20px;
  }
}
.contractor-summary-modal .contractor-name-box {
  margin-bottom: 15px;
}
.contractor-summary-modal .contractor-name-box p {
  margin-right: 0;
}
.contractor-summary-modal .middle-text {
  margin: 10px 0;
  font-weight: bold;
}
.contractor-summary-modal .middle-text p {
  font-size: 14px;
  margin-bottom: 0;
  color: #696969;
  text-align: center;
  font-weight: 700;
}
.contractor-summary-modal .rating-box .text-part {
  width: 50%;
  margin-right: 0;
}
.contractor-summary-modal .rating-box .text-part p {
  margin-top: 0;
  font-weight: 500;
}
.contractor-summary-modal .rating-box .stars-part {
  width: 30%;
}
.contractor-summary-modal .rating-box .stars-part p {
  font-weight: 500;
}
.contractor-summary-modal .rating-box .stars-part i {
  margin-right: 5px;
  font-size: 14px;
  color: #f1d045;
}

/*================================================
35. Chat
=================================================*/
@layer theme {
  .str-chat__theme-custom {
    --str-chat__primary-color: #f9395f;
    --str-chat__active-primary-color: #c71438;
    --str-chat__surface-color: #f5f5f5;
    --str-chat__secondary-surface-color: #fafafa;
    --str-chat__primary-surface-color: #d0d7d6;
    --str-chat__primary-surface-color-low-emphasis: #edf7f7;
    --str-chat__border-radius-circle: 6px;
  }
}
.chat-section {
  height: calc(100dvh - 85px - 20px);
}
@media (max-width: 1199px) {
  .chat-section {
    height: calc(100dvh - 45px - 56px);
  }
}
.chat-section .chat-container {
  display: flex;
  height: 100%;
  border: 1px solid #f5f5f5;
}
@media (max-width: 576px) {
  .chat-section .chat-container h4 {
    padding: 0 20px;
  }
}
.chat-section .chat-container .no-channels,
.chat-section .chat-container .no-channels-list {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px;
  text-align: center;
}
.chat-section .chat-container .no-channels .close-list-button-container,
.chat-section .chat-container .no-channels-list .close-list-button-container {
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .no-channels .close-list-button-container,
  .chat-section .chat-container .no-channels-list .close-list-button-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.chat-section .chat-container .no-channels .close-list-button-container i,
.chat-section .chat-container .no-channels-list .close-list-button-container i {
  font-size: 30px;
  cursor: pointer;
  display: none;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .no-channels .close-list-button-container i,
  .chat-section .chat-container .no-channels-list .close-list-button-container i {
    display: block;
  }
}
.chat-section .chat-container .no-channels-list {
  background-color: rgba(249, 57, 95, 0.0784313725);
}
.chat-section .chat-container .custom-header-container {
  display: flex;
  align-items: center;
  padding: 20px;
  min-height: 90px;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .custom-header-container {
    padding-left: 60px;
  }
}
@media (max-width: 576px) {
  .chat-section .chat-container .custom-header-container {
    padding-left: 0;
    min-height: 120px;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-large {
  display: flex;
  align-items: center;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .custom-header-container .custom-header-buttons-container-large {
    padding: 10px;
  }
}
@media (max-width: 991px) {
  .chat-section .chat-container .custom-header-container .custom-header-buttons-container-large {
    display: none;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-large .custom-header-button {
  margin-top: 0;
  display: flex;
  align-items: center;
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-large .custom-header-button .profile-btn {
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 10px;
  background-color: #fff;
  color: #f9395f;
  font-size: 16px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  transition: all 0.42s ease-in-out;
  border: 1px solid #f9395f;
  text-align: center;
  letter-spacing: 1px;
  font-size: 12px;
  min-width: 140px;
  margin-right: 15px;
}
@media (max-width: 991px) {
  .chat-section .chat-container .custom-header-container .custom-header-buttons-container-large .custom-header-button .profile-btn {
    width: 200px;
    margin-bottom: 10px;
    margin-right: 0;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-large .custom-header-button .profile-btn:hover {
  background-color: #f9395f;
  color: #fff;
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-large .custom-header-button .profile-btn.contractor-btn {
  min-width: 200px;
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-small {
  display: none;
}
@media (max-width: 991px) {
  .chat-section .chat-container .custom-header-container .custom-header-buttons-container-small {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 15px;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-small .custom-header-button {
  margin-top: 0;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-small .custom-header-button .profile-btn {
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 10px;
  background-color: #fff;
  color: #f9395f;
  transition: all 0.42s ease-in-out;
  border: 1px solid #f9395f;
  text-align: center;
  letter-spacing: 1px;
  font-size: 12px;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-small .custom-header-button .profile-btn i {
  font-size: 20px;
}
.chat-section .chat-container .custom-header-container .custom-header-buttons-container-small .custom-header-button .profile-btn:hover {
  background-color: #f9395f;
  color: #fff;
}
.chat-section .chat-container .custom-header-container .custom-header-info-container {
  display: flex;
  width: 100%;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .custom-header-container .custom-header-info-container {
    flex-direction: column;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-info-container .close-list-button-container {
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .custom-header-container .custom-header-info-container .close-list-button-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-info-container .close-list-button-container i {
  font-size: 30px;
  cursor: pointer;
  display: none;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .custom-header-container .custom-header-info-container .close-list-button-container i {
    display: block;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-info-container .header-item {
  display: flex;
  align-items: center;
  width: 100%;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .custom-header-container .custom-header-info-container .header-item {
    padding: 10px;
  }
}
@media (max-width: 991px) {
  .chat-section .chat-container .custom-header-container .custom-header-info-container .header-item {
    flex-direction: column;
    margin-top: 10px;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-info-container .header-item p {
  font-family: "Saira", sans-serif;
  margin-bottom: 0;
  margin-left: 10px;
}
@media (max-width: 576px) {
  .chat-section .chat-container .custom-header-container .custom-header-info-container .header-item p {
    text-align: center;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-info-container .header-item .str-chat__avatar {
  height: 50px;
  width: 50px;
  margin: 10px 0;
}
@media (max-width: 576px) {
  .chat-section .chat-container .custom-header-container .custom-header-info-container .header-item .str-chat__avatar {
    margin-bottom: 10px;
    margin-top: -15px;
  }
}
.chat-section .chat-container .custom-header-container .custom-header-info-container .header-item .str-chat__avatar .str-chat__avatar-fallback {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
}
.chat-section .chat-container .custom-header-container .custom-header-info-container .header-item .str-chat__avatar img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chat-section .chat-container .custom-header-container .custom-header-info-container .header-item .header-pound {
  font-family: "Saira", sans-serif;
  padding: 10px;
  color: #696969;
}
.chat-section .chat-container .list-of-channels {
  transition: all 0.5s;
  position: relative;
  width: 420px;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .list-of-channels.open-list {
    z-index: 999999;
    position: fixed;
    background: #fff;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    transition: all 0.5s;
  }
  .chat-section .chat-container .list-of-channels.open-list::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 1199px) {
  .chat-section .chat-container .list-of-channels.closed-list {
    position: fixed;
    z-index: 999999;
    background: #fff;
    height: 100%;
    left: -120%;
    top: 0;
    transition: all 0.5s;
    overflow-y: auto;
  }
}
.chat-section .chat-container .list-of-channels .channel-list-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}
.chat-section .chat-container .list-of-channels .channel-list-item.active {
  background-color: rgba(249, 57, 95, 0.0784313725);
}
.chat-section .chat-container .list-of-channels .channel-list-item:hover {
  background-color: rgba(249, 57, 95, 0.0784313725);
}
.chat-section .chat-container .list-of-channels .channel-list-item .unread-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background-color: #f9395f;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
  height: 20px;
  width: 20px;
  font-family: "Saira", sans-serif;
  position: absolute;
  right: 10px;
}
.chat-section .chat-container .list-of-channels .channel-list-item .channel-image {
  margin-right: 10px;
}
.chat-section .chat-container .list-of-channels .channel-list-item .channel-image .str-chat__avatar,
.chat-section .chat-container .list-of-channels .channel-list-item .channel-image .str-chat__avatar-fallback {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.chat-section .chat-container .list-of-channels .channel-list-item .channel-image .str-chat__avatar img,
.chat-section .chat-container .list-of-channels .channel-list-item .channel-image .str-chat__avatar-fallback img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chat-section .chat-container .list-of-channels .channel-list-item .channel-info {
  display: flex;
  flex-direction: column;
  width: 78%;
  flex-wrap: wrap;
}
.chat-section .chat-container .list-of-channels .channel-list-item .channel-info .para {
  margin-bottom: 0;
}
.chat-section .chat-container .list-of-channels .channel-list-item .channel-info .para.channel-name {
  max-width: 100%;
}
.chat-section .chat-container .list-of-channels .channel-list-item .channel-info .para.channel-last-message {
  font-size: 12px !important;
}
.chat-section .chat-container .list-of-channels .channel-list-header-container {
  padding: 20px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.chat-section .chat-container .list-of-channels .channel-list-header-container h5 {
  margin-bottom: 0;
  color: #696969;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.chat-section .chat-container .list-of-channels .channel-list-header-container i {
  cursor: pointer;
  display: none;
}
@media (max-width: 1199px) {
  .chat-section .chat-container .list-of-channels .channel-list-header-container i {
    display: block;
  }
}
.chat-section .chat-container .list-of-channels .MuiChip-root {
  background-color: rgba(249, 57, 95, 0.0784313725);
}
.chat-section .chat-container .list-of-channels .MuiChip-root .MuiChip-label {
  font-family: "Saira", sans-serif;
}
.chat-section .chat-container .list-of-channels .str-chat__channel-list-empty {
  height: 100%;
  width: 100%;
}
.chat-section .chat-container .list-of-channels .str-chat__channel-list-empty svg {
  height: 50px;
  margin-bottom: 10px;
}
.chat-section .chat-container .list-of-channels .str-chat__channel-list-empty p {
  font-family: "Inter", sans-serif;
  font-size: 17px;
}
.chat-section .chat-container .str-chat__channel-preview--active,
.chat-section .chat-container .str-chat__channel-preview-messenger--active {
  background-color: rgba(249, 57, 95, 0.0784313725);
}
.chat-section .chat-container .str-chat__avatar-image {
  border-radius: 50% !important;
  cursor: auto;
}
.chat-section .chat-container .str-chat__message-input {
  margin-bottom: 20px;
}
.chat-section .chat-container .str-chat__channel-list {
  width: 100%;
}
.chat-section .chat-container .str-chat__channel-header {
  border: 1px solid #eeeeee;
  padding: 15px;
}
.chat-section .chat-container .str-chat__list {
  background-color: rgba(249, 57, 95, 0.0784313725);
}
.chat-section .chat-container .str-chat__list .str-chat__empty-channel {
  background-color: rgba(249, 57, 95, 0.0784313725);
}
.chat-section .chat-container .str-chat__list .str-chat__empty-channel svg {
  height: 60px;
  width: 60px;
  margin-bottom: 15px;
}
.chat-section .chat-container .str-chat__list .str-chat__empty-channel .str-chat__empty-channel-text {
  font-family: "Inter", sans-serif;
  font-size: 18px;
}
.chat-section .chat-container .str-chat__channel-preview-messenger--name span {
  font-family: "Saira", sans-serif !important;
}
.chat-section .chat-container .str-chat {
  width: 100%;
}
.chat-section .chat-container .str-chat .str-chat__channel-preview-messenger--last-message p {
  margin-bottom: 0;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  color: #696969;
}
.chat-section .chat-footer {
  height: 20px;
  width: 100%;
  background-color: #f9395f;
  position: absolute;
  bottom: 0;
  left: 0;
}/*# sourceMappingURL=style.css.map */